import { ChevronRightIcon } from '@otto-finance/ui';
import { ModifierPhases } from '@popperjs/core';
import clsx from 'clsx';
import { Chip } from 'common/otto-ui/chip';
import { ClickAwayListener } from 'common/otto-ui/click-away-listener';
import { Portal } from 'common/otto-ui/portal';
import noop from 'lodash/noop';
import {
  Children,
  cloneElement,
  FormEvent,
  Fragment,
  isValidElement,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  ReactText,
  SelectHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modifier, usePopper } from 'react-popper';

export type SelectValueType = ReactText | ReactText[] | null;

export enum SelectVariantEnum {
  FILLED = 'filled',
  LINE = 'line',
  TEXT = 'text',
}

export interface SelectInterface extends Omit<SelectHTMLAttributes<HTMLElement>, 'value'> {
  /** Error status of select value */
  error?: boolean;
  /** Set a chip view to selected values */
  chips?: boolean;
  /** Set a  checkbox control in option list items */
  checkbox?: boolean;
  /** Set full width of parent container */
  fullWidth?: boolean;
  /** Helper text bottom of component */
  helperText?: string;
  /** The variant of select */
  variant?: Lowercase<keyof typeof SelectVariantEnum>;
  /** Value of select */
  value?: SelectValueType;
  /** Padding classes */
  paddingClassName?: string;
  children?: ReactNode;
  placeholder?: string;
}
//TODO: Remove
/** Select controlled input with customized properties */
export const Select = ({
  /** Enable selecting multiple values */
  multiple,
  error,
  chips,
  checkbox,
  fullWidth,
  helperText,
  disabled,
  value,
  className,
  placeholder,
  name,
  paddingClassName,
  children,
  onClick,
  onChange,
  variant = SelectVariantEnum.FILLED,
  ...props
}: SelectInterface) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement>(null);
  const [dropdownElement, setDropdownElement] = useState<HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<number>(-1);
  const [nodes, setNodes] = useState<ReactNode[]>([]);
  const arrayValue = useMemo(() => (Array.isArray(value) ? value : [value].filter((item) => item)), [value]);

  const classes = {
    wrapper: clsx(
      'flex flex-col bg-white dark:bg-transparent',
      {
        'w-full': fullWidth,
      },
      className,
    ),
    label: clsx(
      'relative z-0 flex flex-row flex-grow justify-start items-center transition duration-150 cursor-pointer',
      {
        'pointer-events-none opacity-30': disabled,
        'pointer-events-auto': !disabled,
      },
      {
        'pl-5.5 pr-1': variant === SelectVariantEnum.FILLED,
        'border-error': error,
        'border-label dark:border-body focus-within:border-primary dark:focus-within:border-primary-light active:border-title dark:active:border-offWhite':
          !error,
      },
      variant === SelectVariantEnum.FILLED && {
        'focus-within:bg-white dark:bg-transparent rounded-lg overflow-hidden': true,
        'border group-hover:border-2 border-label dark:border-body focus-within:border-primary dark:focus-within:border-primary-light active:border-title dark:active:border-offWhite':
          !error && !disabled,
      },
      variant === SelectVariantEnum.LINE && 'focus-within:bg-white dark:bg-transparent',
      variant === SelectVariantEnum.TEXT && {
        'rounded-lg focus-within:bg-white': true,
        'border-grey2': !error,
      },
    ),
    placeholder: clsx('text-grey2 dark:text-placeholder', {
      'text-xs leading-snug': arrayValue.length || open,
      'text-base leading-normal': !arrayValue.length && !open,
    }),
    body: clsx(
      'flex flex-row flex-wrap justify-start items-center self-stretch space-x-2 text-title dark:text-offWhite text-base',
      {
        'mt-1 flex-grow': placeholder && (arrayValue.length || open),
      },
    ),
    outlineBorder: clsx('absolute m-0 w-full left-0 bottom-0 border-b group-hover:border-b-2 focus-within:border-b-2', {
      'border-grey1 dark:border-placeholder group-hover:border-grey2 dark:group-hover:border-offWhite': !error,
    }),
    chip: 'my-1 bg-line dark:bg-grey2 border-line dark:border-grey2 dark:text-offWhite',
    arrow: clsx(paddingClassName ?? 'py-3.5', 'transition-transform transform duration-150', {
      'rotate-0': !open,
      'rotate-90': open,
    }),
    helperText: clsx('text-xs font-medium tracking-wide leading-none mt-2', {
      'text-error': error,
      'text-grey2 dark:placeholder': !error,
    }),
    input: 'w-0 h-0 outline-none border-none bg-transparent opacity-0',
    dropdown: 'p-3 bg-white dark:bg-background-dark-background3 overflow-x-hidden overflow-y-auto',
  };

  const closeDropDown = () => {
    setOpen(false);
    setHighlight(-1);
  };

  const openDropDown = () => {
    setOpen(true);
    setHighlight(getNextOption(0));
  };

  const handleClickAway = () => {
    setTimeout(() => {
      closeDropDown();
    }, 0);
  };

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    if (open) {
      closeDropDown();
    } else {
      openDropDown();
    }

    if (onClick) {
      onClick(event);
    }
  };

  const handleKeydown: KeyboardEventHandler<HTMLSelectElement> = (event) => {
    onArrowUp(event);
    onArrowDown(event);
    onEscape(event);
    onEnterorSpace(event);
    onTab(event);
  };

  const onArrowUp = (event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'ArrowUp') {
      if (!open) {
        openDropDown();
      } else {
        setHighlight(getPrevOption(highlight - 1));
      }
    }
  };

  const onArrowDown = (event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'ArrowDown') {
      if (!open) {
        openDropDown();
      } else {
        setHighlight(getNextOption(highlight + 1));
      }
    }
  };

  const onEscape = (event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'Escape') {
      if (open) {
        closeDropDown();
      }
    }
  };

  const onEnterorSpace = (event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (!open) {
        openDropDown();
      } else {
        const node = nodes[highlight];
        if (isValidElement(node)) {
          handleChange(node.props.value)(event);
        }
        if (!multiple) {
          closeDropDown();
        }
      }
    }
  };

  const onTab = (event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'Tab') {
      if (open) {
        closeDropDown();
      }
    }
  };

  const handleChange = (newValue: string) => (event: FormEvent<HTMLSelectElement>) => {
    if (onChange) {
      let val;
      if (multiple) {
        if (arrayValue.includes(newValue)) {
          val = arrayValue.filter((item: any) => item !== newValue);
        } else {
          val = [...arrayValue, newValue];
        }
      } else {
        val = newValue;
        closeDropDown();
      }
      const newEvent = {
        ...new Event('change', { ...event }),
        nativeEvent: event.nativeEvent,
        isDefaultPrevented: event.isDefaultPrevented,
        isPropagationStopped: event.isPropagationStopped,
        persist: event.persist,
        target: {
          ...(event.target as EventTarget & HTMLSelectElement),
          value: val,
          name,
        },
        currentTarget: {
          ...(event.currentTarget as EventTarget & HTMLSelectElement),
          value: val,
          name,
        },
      };
      onChange(newEvent);
    }
  };

  const handleMouseEnter = (index: number) => () => {
    setHighlight(index);
  };

  const getPrevOption = (index: number): number => {
    if (index >= 0 && index < nodes.length) {
      const node = nodes[index];
      if (isValidElement(node) && 'value' in node.props && !node.props.disabled) {
        return index;
      } else {
        return getPrevOption(index - 1);
      }
    } else {
      return highlight;
    }
  };

  const getNextOption = (index: number): number => {
    if (index >= 0 && index < nodes.length) {
      const node = nodes[index];
      if (isValidElement(node) && 'value' in node.props && !node.props.disabled) {
        return index;
      } else {
        return getNextOption(index + 1);
      }
    } else {
      return highlight;
    }
  };

  const renderValue = (): ReactNode =>
    chips
      ? arrayValue.map((item, index) => (
          <Chip key={`selected-value-${index}`} color="custom" className={classes.chip}>
            {item}
          </Chip>
        ))
      : arrayValue.join();

  const modifiers = useMemo<Modifier<any>[]>(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
      {
        name: 'preventOverflow',
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top'],
        },
      },
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as ModifierPhases,
        requires: ['computeStyles'],
        fn({ state }) {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect({ state }) {
          state.elements.popper.style.width = `${state.elements.reference.getBoundingClientRect().width}px`;
        },
      },
    ],
    [value],
  );

  const { styles, attributes } = usePopper(anchorElement, dropdownElement, {
    placement: 'bottom-start',
    modifiers,
  });

  useEffect(() => {
    const items: ReactNode[] = [];
    Children.forEach(children, (child: ReactNode) => {
      if (isValidElement(child)) {
        if ('value' in child.props) {
          items.push(cloneElement(child, { key: child.key ?? child.props.value }));
        } else {
          if ('label' in child.props) {
            items.push(cloneElement<any>(child, { key: child.key ?? child.props.value, children: null }));
          }
          Children.forEach(child.props.children, (subChild: ReactNode) => {
            if (isValidElement(subChild) && 'value' in subChild.props) {
              items.push(cloneElement(subChild, { key: child.key ?? child.props.value }));
            }
          });
        }
      }
    });
    setNodes(items);
  }, [children]);

  return (
    <Fragment>
      <div className={classes.wrapper}>
        <label ref={setAnchorElement} className={classes.label}>
          <div className="my-1 flex flex-col flex-grow justify-center items-start self-stretch">
            <div className={classes.placeholder}>{placeholder}</div>
            <div className={classes.body}>
              {renderValue()}
              <select
                name={name}
                className={classes.input}
                value={arrayValue.join()}
                disabled={disabled}
                onClick={handleClick}
                onKeyDown={handleKeydown}
                onChange={noop}
                {...props}
              />
            </div>
          </div>
          <div className={classes.arrow}>
            <ChevronRightIcon />
          </div>
        </label>
      </div>
      {helperText && <div className={classes.helperText}>{helperText}</div>}
      {open && Children.count(children) > 0 && (
        <Portal>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              ref={setDropdownElement}
              className="rounded-2xl shadow-general overflow-hidden z-[200]"
              style={styles.popper}
              {...attributes.popper}
            >
              <div className={classes.dropdown} style={{ maxHeight: '30vh' }}>
                {nodes.map((child: ReactNode, index: number) => {
                  if (isValidElement(child)) {
                    if ('value' in child.props) {
                      return cloneElement<any>(child, {
                        key: child.key ?? child.props.value,
                        checkbox,
                        selected: arrayValue.includes(child.props.value),
                        highlight: highlight === index,
                        onChange: handleChange(child.props.value),
                        onMouseEnter: handleMouseEnter(index),
                      });
                    } else {
                      return cloneElement(child, {
                        key: `option-group-${index}`,
                      });
                    }
                  } else {
                    return child;
                  }
                })}
              </div>
            </div>
          </ClickAwayListener>
        </Portal>
      )}
    </Fragment>
  );
};
