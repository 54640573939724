import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { financeApi, FinanceOverviewInterface } from 'api/finance.api';
import { RootState } from 'configuration/redux/reducer';

export interface FinanceOverviewStateInterface {
  overview: FinanceOverviewInterface;
  dailySpend?: number;
  isLoading?: boolean;
}

const initialState: FinanceOverviewStateInterface = {
  overview: null,
  dailySpend: 0,
  isLoading: true,
};

export const financeOverviewSlice = createSlice({
  name: 'financeOverview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(financeApi.endpoints.financeOverview.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(
        financeApi.endpoints.financeOverview.matchFulfilled,
        (state, action: PayloadAction<FinanceOverviewInterface>) => {
          state.isLoading = false;
          if (action.payload) {
            state.overview = action.payload;
            state.dailySpend = action.payload.spending.currentMonth / new Date().getDate();
          } else {
            state.overview = null;
          }
        },
      )
      .addMatcher(financeApi.endpoints.financeOverview.matchRejected, (state) => {
        state.isLoading = false;
        state.overview = null;
      });
  },
});

export default financeOverviewSlice.reducer;
export const selectFinanceOverview = (state: RootState): FinanceOverviewStateInterface => state.financeOverview;
