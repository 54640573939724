import { IconProps } from 'common/otto-ui/icons/icon.type';

export const DiamondIcon = ({ size = 24, color = 'currentColor' }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0,0,256,256">
    <g
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
    >
      <g transform="scale(5.12,5.12)">
        <path d="M24.61328,3.01172l-10.85937,11.53125l2.42578,-11.53125zM33.76563,3.01172l2.47266,11.53125l-10.74219,-11.53125zM35.74219,17h-21.49219l10.75,-11.55859zM11.21094,17h-9.95312l12.76953,-13.43359zM11.32813,19l9.73047,25.65625l-19.875,-25.65625zM13.5,19h23l-11.5,29.81641zM38.78125,17l-2.86719,-13.48828l12.74609,13.48828zM28.94922,44.63672l9.72656,-25.63672h10.10547z" />
      </g>
    </g>
  </svg>
);
