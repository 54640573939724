import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const CreditCardIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  height = 32,
  width = 32,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.9451 17.6969L30.4401 3.49044C30.0997 1.56025 28.2521 0.266707 26.3223 0.607105L4.8377 4.3955C2.90744 4.73583 1.61397 6.58304 1.9543 8.51323L2.13836 9.55724C0.881274 10.1056 0 11.3599 0 12.8167V26.8875C0 28.8475 1.59458 30.4421 3.55457 30.4421H24.882C26.8419 30.4421 28.4365 28.8475 28.4365 26.8875V22.1012L30.0617 21.8147C30.9967 21.6498 31.8115 21.1307 32.3562 20.3529C32.9008 19.5752 33.1099 18.6319 32.9451 17.6969V17.6969ZM30.768 19.2408C30.5205 19.5944 30.15 19.8303 29.725 19.9053L28.4365 20.1325V19.0274V14.6295V12.8166C28.4365 10.8567 26.8419 9.26208 24.882 9.26208H4.05518L3.86375 8.17652C3.78878 7.75152 3.88391 7.32278 4.13144 6.9692C4.37897 6.61568 4.74942 6.37972 5.17441 6.30475L26.659 2.51642C26.7535 2.49975 26.8475 2.49174 26.9404 2.49174C27.7092 2.49174 28.3928 3.04418 28.5308 3.82702L31.0357 18.0335C31.1106 18.4586 31.0155 18.8873 30.768 19.2408V19.2408ZM24.882 28.5032H3.55457C2.66366 28.5032 1.93885 27.7784 1.93885 26.8875V19.9969H26.4977V26.8874C26.4977 27.7784 25.7729 28.5032 24.882 28.5032ZM3.55457 11.201H24.882C25.7729 11.201 26.4977 11.9258 26.4977 12.8167V13.6601H1.93885V12.8167C1.93885 11.9258 2.66366 11.201 3.55457 11.201ZM26.4977 15.599V18.0581H1.93885V15.599H26.4977Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M9.32833 24.6113H4.93036C4.39498 24.6113 3.96094 25.0454 3.96094 25.5808C3.96094 26.1161 4.39498 26.5502 4.93036 26.5502H9.32833C9.86371 26.5502 10.2978 26.1161 10.2978 25.5808C10.2978 25.0454 9.86371 24.6113 9.32833 24.6113Z"
        fill={isDark ? darkColor : color}
      />
    </svg>
  );
};
