export const TABLET_SIZE = 768;

export const DURATION = {
  LONG: 7000,
  SHORT: 3000,
  FOREVER: 0,
};

export const VERCEL_PROXY_TIMEOUT = 15;

export const GRAPHQL_API = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`;
export const SESSION = {
  DURATION: 29, // minutes offset
};
