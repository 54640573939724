import { useDataset } from 'common/roq-hooks/user-dataset/user-dataset';
import { useSetDataset } from 'common/roq-hooks/user-set-dataset/user-set-dataset';
import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';

import styles from './tabs.module.scss';

let tabId = 1;
const indexArr = 'index';

interface TabsContextInterface {
  id: number;
  selectedTab: number;
  changeTab: (index: number) => void;
}

type TabsProviderProps = {
  id?: number;
  /** Selected tab index */
  selectedTab?: number;
  children: ReactNode;
  onChange?: (index: number) => void;
};

type TabsState = {
  id: number;
  selectedTab?: number;
};

const TabsContext = createContext<TabsContextInterface | null>(null);

export const Tabs = ({ id, selectedTab, onChange, children }: TabsProviderProps): JSX.Element => {
  const [state, setState] = useState<TabsState>({
    id: id ?? tabId++,
    selectedTab: selectedTab ?? 0,
  });
  const changeTab = useCallback((index: number) => {
    setState((s) => ({ ...s, selectedTab: index }));
    if (onChange) {
      onChange(index);
    }
  }, []);
  return (
    <TabsContext.Provider
      value={{
        id: state.id,
        selectedTab: state.selectedTab,
        changeTab,
      }}
    >
      <div className={styles.root}>{children}</div>
    </TabsContext.Provider>
  );
};

export const TabsItems = ({ children }: { children: ReactNode }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  useSetDataset(ref, indexArr);
  return (
    <div ref={ref} className={styles.header} role="tablist" aria-orientation="horizontal">
      {children}
    </div>
  );
};

type TabProps = {
  title?: string;
  disabled?: boolean;
  children: ReactNode;
};

export const Tab: React.FC<TabProps> = ({ title, disabled, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { changeTab, selectedTab, id } = useContext(TabsContext);
  const index = useDataset(ref, indexArr);
  return (
    <div
      ref={ref}
      role="tab"
      onClick={() => !disabled && changeTab(Number(ref.current.dataset.index))}
      aria-selected={selectedTab === index}
      aria-controls={`tabs-${id}--tabpanel-${index}`}
      data-disabled={disabled}
      title={title}
    >
      {children}
    </div>
  );
};

export const TabsPanels: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  useSetDataset(ref, indexArr);
  return (
    <div className={styles.contentWrap}>
      <div ref={ref} className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export const TabPanel: React.FC<{ children: ReactNode }> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = useDataset(ref, indexArr);
  const { selectedTab, id } = useContext(TabsContext);
  return (
    <div
      ref={ref}
      hidden={selectedTab !== index}
      role="tabpanel"
      tabIndex={index}
      aria-labelledby={`tabs-${id}--tabpanel-${index}`}
    >
      {children}
    </div>
  );
};
