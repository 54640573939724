import { caching } from 'configuration/data';
import { GetCoachMatchDocument } from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';

interface CoachMetaUser {
  avatar: string;
  avatarImageIdentifier: string;
  firstName: string;
  lastName: string;
  status: string;
}

export interface CoachMetaExpertise {
  name: string;
  icon: string;
}

export interface CoachMatchApiReturnInterface {
  id: string;
  isActive: boolean;
  coachMetaInfo: {
    about: string;
    coachExpertises: {
      data: CoachMetaExpertise[];
    };
    user: CoachMetaUser;
  };
}

interface UserCoachResponse extends CoachMetaUser {
  id: string;
  isActive: boolean;
  expertise: CoachMetaExpertise[];
  about: string;
  fullName: string;
}

export interface CustomerMetaInfoInterface {
  user: {
    customerMetaInfo: {
      coachMatches: {
        data: CoachMatchApiReturnInterface[];
      };
    };
  };
}

export const coachMatchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserCoachMatch: builder.query<UserCoachResponse | null, { userId: string }>({
      query: (variables) => ({
        variables,
        document: GetCoachMatchDocument,
      }),
      keepUnusedDataFor: caching['10mins'],
      providesTags: [API_TAGS.USER_COACH],
      transformResponse: (response: CustomerMetaInfoInterface) => {
        const payload = response.user.customerMetaInfo?.coachMatches?.data?.[0] || null;
        if (payload) {
          const { user } = payload.coachMetaInfo;
          return {
            isActive: payload.isActive,
            expertise: payload.coachMetaInfo?.coachExpertises?.data || [],
            about: payload.coachMetaInfo?.about,
            fullName: `${user.firstName} ${user.lastName}`,
            id: payload.id,
            ...payload.coachMetaInfo.user,
          };
        }

        return null;
      },
    }),
  }),
});

export const { useFetchUserCoachMatchQuery } = coachMatchApi;
