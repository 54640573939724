import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { BenefitsInterface } from 'slices/benefits';
import { requestGql } from 'utils/request-gql';

export interface FetchBenefitsActionRequestInterface extends QueryOptions {}

export const fetchBenefitsAction = createAsyncThunk(
  'benefits/list',
  async (request: FetchBenefitsActionRequestInterface, thunkApi): Promise<BenefitsInterface[] | SerializedError> =>
    await requestGql<BenefitsInterface[]>(request, thunkApi, 'benefits'),
);
