import { LabelMedium, LabelXSmall, styled, theme } from '@otto-finance/ui';
import { Avatar } from 'components';

const OptionParent = styled<'div', unknown>('div', ({ $theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr max-content',
  gap: $theme.sizing.scale400,
  alignItems: 'center',
}));

const FallbackIcon = styled<'div', { color: string }>('div', ({ color }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  textAlign: 'center',
  fontWeight: theme.weights.semiBold,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: theme.colors.white,
  backgroundColor: color,
}));

export const HoldingTitle = ({
  data: { companyName, symbol, image },
  color,
  extras,
}: {
  data: { companyName: string; symbol: string; image: string };
  color?: string;
  extras?: JSX.Element;
}): JSX.Element => (
  <OptionParent title={companyName}>
    <HoldingIcon image={image} symbol={symbol} color={color} />
    <div>
      <LabelXSmall color={theme.colors.blueScale300}>{symbol}</LabelXSmall>
      <LabelMedium>{companyName}</LabelMedium>
    </div>
    {extras && extras}
  </OptionParent>
);

export const HoldingIcon = ({ image, symbol, color }: { image: string; symbol: string; color: string }) => {
  return <Avatar src={image} fallback={<FallbackIcon color={color}>{symbol.slice(0, 3)}</FallbackIcon>} />;
};
