import { StatefulTabs, Tab } from '@otto-finance/ui';
import { ReactNode } from 'react';

type TabsProps = {
  orientation?: 'vertical' | 'horizontal';
  items: {
    title: ReactNode;
    id: string;
    content: ReactNode;
    disabled?: boolean;
  }[];
};

export const Tabs = ({ orientation = 'horizontal', items }: TabsProps) => {
  return (
    <StatefulTabs
      orientation={orientation}
      overrides={{
        Root: { style: () => ({}) },
        TabHighlight: { style: () => ({}) },
        TabBorder: { style: () => ({}) },
        TabList: { style: () => ({}) },
        TabBar: { style: () => {} },
      }}
    >
      {items.map((t) => (
        <Tab key={t.id} title={t.title} disabled={t.disabled}>
          {t.content}
        </Tab>
      ))}
    </StatefulTabs>
  );
};
