import { Typography } from 'common/otto-ui/typography';

interface FormErrorInterface {
  message: string;
}

export const FormErrorPartial = ({ message }: FormErrorInterface) => (
  <Typography variant="subtitle" color="error" align="center">
    {message}
  </Typography>
);
