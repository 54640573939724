import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const UserNotesIcon: React.FC<IconProps> = ({ height = 24, width = 24, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.353615">
      <path d="M15.2287 14.3415C15.0645 14.3415 14.9313 14.4747 14.9313 14.6388V17.5653C14.9313 18.0916 14.5031 18.5192 13.9774 18.5192H2.63842C2.11272 18.5192 1.68454 18.091 1.68454 17.5653V5.43594H4.20247C5.05704 5.43594 5.75105 4.74134 5.75105 3.88736V1.53952H13.9774C14.5031 1.53952 14.9313 1.9677 14.9313 2.4934V4.52904C14.9313 4.69317 15.0645 4.82638 15.2287 4.82638C15.3928 4.82638 15.526 4.69317 15.526 4.52904V2.4934C15.526 1.63883 14.8314 0.944824 13.9774 0.944824H5.45429C5.37758 0.944824 5.30384 0.974559 5.24853 1.02749L1.18143 4.92451C1.12255 4.981 1.08984 5.05831 1.08984 5.13919V17.5659C1.08984 18.4205 1.78444 19.1145 2.63842 19.1145H13.978C14.832 19.1145 15.5266 18.4199 15.5266 17.5659V14.6394C15.526 14.4747 15.3934 14.3415 15.2287 14.3415ZM5.15695 1.93915V3.88736C5.15695 4.41367 4.72877 4.84125 4.20306 4.84125H2.12758L5.15695 1.93915Z" />
      <path d="M3.65672 15.946C3.49259 15.946 3.35938 16.0793 3.35938 16.2434C3.35938 16.4075 3.49259 16.5407 3.65672 16.5407H10.3101C10.2495 16.3582 10.2073 16.1566 10.1775 15.946H3.65672Z" />
      <path d="M10.5813 12.8953H3.65672C3.49259 12.8953 3.35938 13.0285 3.35938 13.1926C3.35938 13.3567 3.49259 13.49 3.65672 13.49H10.2727C10.2905 13.44 10.2977 13.3859 10.3244 13.3395L10.5813 12.8953Z" />
      <path d="M12.3452 9.84644H3.65672C3.49259 9.84644 3.35938 9.97965 3.35938 10.1438C3.35938 10.3079 3.49259 10.4411 3.65672 10.4411H12.0014L12.3452 9.84644Z" />
      <path d="M13.2604 7.09349C13.2604 6.92935 13.1272 6.79614 12.9631 6.79614H3.65672C3.49259 6.79614 3.35938 6.92935 3.35938 7.09349C3.35938 7.25762 3.49259 7.39084 3.65672 7.39084H12.9631C13.1272 7.39084 13.2604 7.25822 13.2604 7.09349Z" />
      <path d="M12.9635 3.74707H6.86375C6.69962 3.74707 6.56641 3.88028 6.56641 4.04442C6.56641 4.20855 6.69962 4.34176 6.86375 4.34176H12.9635C13.1276 4.34176 13.2609 4.20855 13.2609 4.04442C13.2609 3.88028 13.1276 3.74707 12.9635 3.74707Z" />
    </g>
    <g stroke={color} strokeWidth="0.884039" strokeLinecap="round" strokeLinejoin="round">
      <path d="M19.209 9.34424H13.0208C12.5325 9.34424 12.1367 9.74004 12.1367 10.2283V13.3224C12.1367 13.8107 12.5325 14.2064 13.0208 14.2064H19.209C19.6973 14.2064 20.0931 13.8107 20.0931 13.3224V10.2283C20.0931 9.74004 19.6973 9.34424 19.209 9.34424Z" />
      <path d="M13.9062 9.34414V7.57606C13.9062 6.98991 14.1391 6.42776 14.5536 6.01329C14.968 5.59882 15.5302 5.36597 16.1163 5.36597C16.7025 5.36597 17.2646 5.59882 17.6791 6.01329C18.0936 6.42776 18.3264 6.98991 18.3264 7.57606V9.34414" />
      ;
    </g>
  </svg>
);
