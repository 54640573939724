import { ArrowDownIcon, ArrowUpIcon, useTheme } from '@otto-finance/ui';
import { Table, flexRender } from '@tanstack/react-table';
import { ColorTheme } from 'configuration/ui';

export const TableTh = <T extends unknown>({ table, pos }: { table: Table<T>; pos: number }) => {
  const [css, theme] = useTheme();
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={`thead_tr_${headerGroup.id}`}>
          {headerGroup.headers.map((header) => {
            const sort = header.column.getIsSorted();
            const canSort = header.column.getCanSort();
            return (
              <th
                key={`table_th_${header.id}`}
                colSpan={header.colSpan}
                className={css({
                  userSelect: 'none',
                  paddingLeft: theme.sizing.scale400,
                  paddingRight: theme.sizing.scale400,
                  backgroundColor: theme.colors.white,
                  ...(header.getSize() !== 150 && { width: `${header.getSize()}px` }),
                  ...(canSort && {
                    ':hover': {
                      backgroundColor: ColorTheme.background50,
                    },
                  }),
                  ...(pos && {
                    position: 'sticky',
                    top: `${pos}px`,
                    borderBottom: `solid 1px ${ColorTheme.gray50}`,
                    zIndex: 1,
                  }),
                })}
              >
                <div
                  className={css({
                    cursor: canSort ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.sizing.scale200,
                    height: theme.sizing.scale1000,
                    ...(header?.column?.columnDef?.meta?.style && header.column.columnDef.meta.style),
                  })}
                  {...(canSort
                    ? {
                        onClick: header.column.getToggleSortingHandler(),
                      }
                    : {})}
                >
                  <div
                    className={css({
                      fontWeight: sort ? theme.weights.semiBold : theme.weights.medium,
                      fontSize: '14px',
                    })}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                  <div>
                    {sort === false ? null : sort === 'asc' ? <ArrowUpIcon size="sm" /> : <ArrowDownIcon size="sm" />}
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
