import { Badge, ParagraphSmall, useTheme } from '@otto-finance/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useHoldingTransactionsQuery } from 'api/holdings.api';
import { Table } from 'common/otto-ui/table';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'enums';
import { AssetsTransactionInterface } from 'slices/assets';
import { formatMoney } from 'utils';
import { useLoginHook } from 'views/login/hooks';

const columnHelper = createColumnHelper<AssetsTransactionInterface>();

const columns = [
  columnHelper.accessor('direction', {
    header: 'Operation',
    cell: (info) => (
      <Badge
        content={info.getValue() === 'IN' ? 'BUY' : 'SELL'}
        hierarchy="secondary"
        color={info.getValue() === 'OUT' ? 'positive' : 'warning'}
      />
    ),
  }),
  columnHelper.accessor('amount', {
    header: 'Shares',
    cell: (info) => Math.abs(info.getValue()),
  }),
  columnHelper.accessor('convertedAmount', {
    header: 'Value',
    cell: ({ row: { original } }) => formatMoney(Math.abs(original.convertedAmount), original.convertedCurrency),
  }),
  columnHelper.accessor('date', {
    header: 'Date',
    cell: (info) => format(new Date(info.getValue()), DATE_FORMAT.DATE_SHORT),
  }),
];

export const HoldingTransactions = ({
  accountId,
  holdingId,
}: {
  accountId: string;
  holdingId: string;
}): JSX.Element => {
  const { userData, currency } = useLoginHook();
  const [css, theme] = useTheme();
  const { data, isLoading } = useHoldingTransactionsQuery({
    userId: userData.id,
    accountId,
    convertTo: currency,
    holdingId,
  });
  return (
    <div
      className={css({
        maxWidth: '340px',
        overflowX: 'auto',
        [theme.mediaQuery.medium]: {
          maxWidth: '100%',
        },
      })}
    >
      {isLoading ? null : !data || !data.length ? (
        <ParagraphSmall>No transactions for this period</ParagraphSmall>
      ) : (
        <Table data={data} columns={columns} isLoading={isLoading} totalCount={data?.length} enablePagination={false} />
      )}
    </div>
  );
};
