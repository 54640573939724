export const darkScrollbar = {
  '::-webkit-scrollbar': {
    width: '24px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '16px',
    border: 'solid 8px transparent',
    backgroundClip: 'padding-box',
  },
};

const scrollbar = {
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    border: 'solid 8px transparent',
    backgroundClip: 'padding-box',
  },
};

export const lightScrollbar = {
  '::-webkit-scrollbar': {
    width: '24px',
  },
  ...scrollbar,
};

export const lightScrollbarHorizontal = {
  '::-webkit-scrollbar': {
    height: '24px',
  },
  ...scrollbar,
};
