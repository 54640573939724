import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { BenefitsUserInterface } from 'slices/benefits';
import { requestGql } from 'utils/request-gql';

export interface FetchUserBenefitActionRequestInterface extends QueryOptions {
  variables: {
    id: string;
  };
}

export const fetchUserBenefitAction = createAsyncThunk(
  'benefits/userBenefit',
  async (request: FetchUserBenefitActionRequestInterface, thunkApi): Promise<BenefitsUserInterface | SerializedError> =>
    await requestGql<BenefitsUserInterface>(request, thunkApi, 'userBenefit'),
);
