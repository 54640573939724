import { theme } from '@otto-finance/ui';
import { AssetsBalanceInterface } from 'common/interfaces';
import { HistoricalBalanceSerie } from 'slices/assets';

export const buildBalanceGraphData = (data: AssetsBalanceInterface[]): HistoricalBalanceSerie[] => {
  /**
   * Builds the graph data from the assets balances. If the currency conversion API fails
   * we fall back to the raw values.
   */
  if (!data || !Array.isArray(data) || !data.length) {
    return [];
  }

  const graphData = data.reverse().map((point) => ({
    x: point.date,
    y: point.convertedCurrency ? point.convertedAmount : point.amount,
  }));

  // Check if the final balance is negative to determine the graph colour
  const isNegative = graphData[graphData.length - 1].y < 0;
  return [
    {
      id: 'Balance',
      color: isNegative ? theme.colors.negative : theme.colors.positive,
      data: graphData,
    },
  ];
};
