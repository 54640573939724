import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { CoachNotepadInterface } from 'slices/coach-notepad';
import { requestGql } from 'utils/request-gql';

export interface FetchCoachNotepadActionRequestInterface extends QueryOptions {
  variables: {
    userId: string;
  };
}

export const fetchCoachNotepadAction = createAsyncThunk(
  'coachNotes/get',
  async (
    request: FetchCoachNotepadActionRequestInterface,
    thunkApi,
  ): Promise<CoachNotepadInterface | SerializedError> =>
    await requestGql<CoachNotepadInterface>(request, thunkApi, 'getCoachNotepad'),
);
