import { gql } from 'graphql-request';

export const CompaniesDocument = gql`
  query Companies {
    customerCompanies {
      data {
        id
        name
      }
      totalCount
    }
  }
`;

export const CreateCompanyDocument = gql`
  mutation createEmployer($name: String!) {
    createCustomerCompany(customerCompany: { name: $name }) {
      id
    }
  }
`;
