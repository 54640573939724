import { caching } from 'configuration/data';
import { CreateMessage, FetchMessages } from 'configuration/graphql/documents';

import { baseApi } from './base.api';
import { ChatMessageInterface } from 'common/interfaces';

export interface SendMessageInterface {
  args: {
    message: string;
    channel: string;
    event: string;
  };
}

export interface MessageStatusInterface {
  userId: string;
  read: boolean;
}
export interface MessageInterface {
  id: string | null;
  body: string;
  conversationId: string;
  authorId: string;
  createdAt: Date;
  messageStatus: { data: MessageStatusInterface[] };
}

interface CreateMessagePayload {
  message: { body: string; conversationId: string; authorId: string; channel?: string; event?: string };
}

interface CreateMessageInterface {
  id: string;
  body: string;
}

export interface FetchMessagesReturnInterface {
  data: ChatMessageInterface[];
  totalCount: number;
}

export interface CreateMessageReturnInterface {
  createMessage: ChatMessageInterface;
}

export const messageApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchMessages: builder.query<
      FetchMessagesReturnInterface,
      { conversationId: string; limit?: number; offset?: number }
    >({
      query: (variables) => ({
        variables,
        document: FetchMessages,
      }),
      keepUnusedDataFor: caching.day,
      transformResponse: ({ messages }: { messages: FetchMessagesReturnInterface }) => messages,
    }),
    createMessage: builder.mutation<ChatMessageInterface, CreateMessagePayload>({
      query: (variables) => ({
        variables,
        document: CreateMessage,
      }),
      transformResponse: (response: CreateMessageReturnInterface) => response.createMessage,
    }),
  }),
});

export const { useFetchMessagesQuery, useCreateMessageMutation, useLazyFetchMessagesQuery } = messageApi;
