import { DATE_RANGE } from 'enums';

export const investmentsDateOptionsMap = {
  [DATE_RANGE.LAST_5_DAYS]: '5 Days',
  [DATE_RANGE.LAST_30_DAYS]: 'Past month',
  [DATE_RANGE.LAST_3_MONTHS]: 'Three months',
  [DATE_RANGE.YEAR_TO_DATE]: 'Year to Date',
  [DATE_RANGE.LAST_YEAR]: 'Past year',
  [DATE_RANGE.ALL_TIME]: 'All time',
};

export const investmentsDateOptionsMapShort = {
  [DATE_RANGE.LAST_5_DAYS]: '5D',
  [DATE_RANGE.LAST_30_DAYS]: '1M',
  [DATE_RANGE.LAST_3_MONTHS]: '3M',
  [DATE_RANGE.YEAR_TO_DATE]: 'YTD',
  [DATE_RANGE.LAST_YEAR]: '1Y',
  [DATE_RANGE.ALL_TIME]: 'ALL',
};

export const investmentsDateOptions = Object.keys(investmentsDateOptionsMap).map((ok) => ({
  id: ok,
  label: investmentsDateOptionsMap[ok],
}));
