import { CompaniesDocument, CreateCompanyDocument } from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import sortBy from 'lodash/sortBy';
import { caching } from 'configuration/data';

interface CompanyInterface {
  id: string;
  name: string;
}

export const companiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    companies: builder.query<CompanyInterface[], {}>({
      query: () => ({
        document: CompaniesDocument,
      }),
      transformResponse: (response: { customerCompanies }) => sortBy(response.customerCompanies.data, ['name']),
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.COMPANIES],
    }),
    createCompany: builder.mutation<CompanyInterface, { name: string }>({
      query: (variables) => ({
        variables,
        document: CreateCompanyDocument,
      }),
      transformResponse: (response: { createCustomerCompany }) => response.createCustomerCompany,
    }),
  }),
});

export const { useCompaniesQuery, useCreateCompanyMutation } = companiesApi;
