import { caching } from 'configuration/data';
import { ListConnectionsDocument } from 'configuration/graphql/documents';
import { ConnectionInterface } from 'views/wealth/hooks/use-fetch-auth-url.hook';

import { API_TAGS, baseApi } from './base.api';

export interface ConnectionsReturnInterface {
  listAvailableConnections: {
    data: ConnectionInterface[];
    totalCount: number;
  };
}

export const connectionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchConnections: builder.query<ConnectionInterface[] | null, { provider: string }>({
      query: (variables) => ({
        variables,
        document: ListConnectionsDocument,
      }),
      keepUnusedDataFor: caching['10mins'],
      providesTags: [API_TAGS.CONNECTIONS],
      transformResponse: (response: ConnectionsReturnInterface) => {
        if (response?.listAvailableConnections?.data) {
          return response.listAvailableConnections.data;
        }
        return [];
      },
    }),
  }),
});

export const { useFetchConnectionsQuery } = connectionsApi;
