import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const FilterChevronUpIcon: React.FC<IconProps> = ({ color = '#A1A7A2', height = 4, width = 6 }) => (
  <svg width={width} height={height} viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66666 0.482642C2.90447 0.223162 3.31352 0.223163 3.55133 0.482642L5.74607 2.87739C6.09878 3.26224 5.82577 3.88278 5.30373 3.88278H0.914257C0.392222 3.88278 0.119211 3.26224 0.471924 2.87738L2.66666 0.482642Z"
      fill={color}
    />
  </svg>
);
