import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'configuration/redux/reducer';
import { ReactNode } from 'react';

export interface ConfirmAlertInterface {
  isOpen: boolean;
  isDeclined: boolean;
  isConfirmed: boolean;
  options: ConfirmAlertOptiomsInterface | null;
}

export interface ConfirmAlertOptiomsInterface {
  title?: string;
  msg?: string;
  confirmLabel?: string;
  declineLabel?: string;
  body?: ReactNode;
}

const defaultOptions = {
  title: 'Are you sure?',
  confirmLabel: 'Confirm',
  declineLabel: 'Cancel',
  msg: null,
};

const initialState: ConfirmAlertInterface = {
  isOpen: false,
  isDeclined: false,
  isConfirmed: false,
  options: defaultOptions,
};

const confirmAlertSlice = createSlice({
  initialState,
  name: 'confirmAlert',
  reducers: {
    open: (state, { payload }: PayloadAction<ConfirmAlertOptiomsInterface>) => {
      state.isOpen = true;
      state.isDeclined = false;
      state.isConfirmed = false;
      state.options = { ...state.options, ...payload };
    },
    confirm: (state) => {
      state.isConfirmed = true;
      state.isOpen = false;
      state.options = defaultOptions;
    },
    decline: (state) => {
      state.isDeclined = true;
      state.isOpen = false;
      state.options = defaultOptions;
    },
  },
});

export default confirmAlertSlice.reducer;
export const selectConfirmAlert = (state: RootState): ConfirmAlertInterface => state.confirmAlert;
export const confirmAlertActions = confirmAlertSlice.actions;
