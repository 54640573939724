import { ROUTE_ENUM } from 'configuration/data/routers';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect } from 'react';
import { useLoginHook } from 'views/login/hooks';

export const PublicPage = ({ children }: { children: ReactNode }) => {
  const { isLogged } = useLoginHook();
  const router = useRouter();
  useEffect(() => {
    router.events.on('beforeHistoryChange', onRouteChangeStart);
    return () => {
      router.events.off('beforeHistoryChange', onRouteChangeStart);
    };
  }, []);
  const onRouteChangeStart = useCallback(
    (p) => {
      if (isLogged && p === ROUTE_ENUM.LOGIN) {
        router.push(ROUTE_ENUM.HOME);
      }
    },
    [isLogged],
  );
  return children;
};
