import { KeyEnum } from 'enums';
import { useCallback, useEffect, useState } from 'react';

export const useKeypress = (k: KeyEnum | string): boolean => {
  const [pressed, setPressed] = useState(false);
  useEffect(() => {
    document.addEventListener('keyup', onKeyPress);
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keyup', onKeyPress);
      document.removeEventListener('keydown', onKeyPress);
    };
  }, []);
  const onKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === k) {
      setPressed(e.type !== 'keyup');
    }
  }, []);
  return pressed;
};
