import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const MinusIcon: React.FC<IconProps> = ({ height = 22, width = 22 }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.65344 12.144H13.5494V10.48H8.65344V12.144Z" fill="url(#paint0_linear_5:8661)" />
    <circle cx="11" cy="11" r="10.5" stroke="url(#paint1_linear_5:8661)" />
    <defs>
      <linearGradient id="paint0_linear_5:8661" x1="3" y1="10" x2="18.4872" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#53DADA" />
        <stop offset="1" stopColor="#61D57F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5:8661"
        x1="2.11287e-08"
        y1="11.0001"
        x2="21.032"
        y2="11.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#53DADA" />
        <stop offset="1" stopColor="#61D57F" />
      </linearGradient>
    </defs>
  </svg>
);
