import { ListActivityDocument } from 'configuration/graphql/documents';
import { baseApi } from './base.api';
import { Activity, PageModel } from 'common/interfaces';

export const activityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    activity: builder.query<PageModel<Activity<unknown, unknown>>, { limit?: number; offset?: number; userId: string }>(
      {
        query: (variables) => ({
          variables,
          document: ListActivityDocument,
        }),
        transformResponse: (data: { listActivity }) => data.listActivity,
      },
    ),
  }),
});

export const { useActivityQuery } = activityApi;
