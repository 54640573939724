import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const PaperclipIcon: React.FC<IconProps> = ({ color = 'currentColor', size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 154 173" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M88.3226 73.1151C90.4476 70.9901 90.4476 67.8025 88.3226 65.6775C86.1975 63.5524 83.0099 63.5524 80.8849 65.6775L33.868 112.694C26.696 119.866 26.696 131.82 33.868 138.992C37.5869 142.711 42.3683 144.304 46.884 144.304C51.6654 144.304 56.4468 142.445 60.1656 138.992L77.166 121.991L144.636 54.5209C157.121 42.0362 157.121 21.8482 144.636 9.36352C132.152 -3.12117 111.964 -3.12117 99.4791 9.36352L58.3062 50.5364L32.0086 76.834L13.1488 95.6938C-4.38292 113.226 -4.38292 141.914 13.1488 159.445C21.9146 168.211 33.6024 172.727 45.0246 172.727C56.7124 172.727 68.1345 168.211 76.9004 159.445L142.511 93.8344C144.636 91.7094 144.636 88.5218 142.511 86.3967C140.386 84.2717 137.199 84.2717 135.074 86.3967L69.4627 152.008C55.9155 165.555 34.1337 165.555 20.5865 152.008C7.03924 138.461 7.03924 116.679 20.5865 103.132L39.4463 84.2717L65.7439 57.9741L107.182 16.5356C115.417 8.30099 128.964 8.30099 137.199 16.5356C141.183 20.5201 143.308 25.8327 143.308 31.6766C143.308 37.2548 141.183 42.5675 137.199 46.8176L108.776 74.9746L52.4623 131.288C49.2747 134.476 44.2277 134.476 41.3057 131.288C38.1182 128.101 38.1182 123.054 41.3057 120.132L88.3226 73.1151Z"
    />
  </svg>
);
