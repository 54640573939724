export const uiJustify = {
  between: 'space-between',
  end: 'flex-end',
  center: 'center',
};

export const uiAlign = {
  center: 'center',
  baseline: 'baseline',
};
