import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { transactionsApi } from 'api';
import { TransactionCategoryInterface, TransactionInterface } from 'common/interfaces';
import { StoreInterface } from 'configuration/redux/store';
import { getDateRange } from 'utils/dates';
import { DATE_FORMAT, DATE_RANGE } from 'enums';
import { format } from 'date-fns';

type TransactionFilters = {
  period: string[];
  accountIds: string[];
  categories: string[];
};

export interface TransactionsStateInterface {
  totalCount: number;
  data: TransactionInterface[];
  filters: TransactionFilters;
  totals: {
    moneyIn: number;
    moneyOut: number;
    // Totals without `matching` transfers
    net: {
      moneyIn: number;
      moneyOut: number;
    };
  };
  categories: TransactionCategoryGroupedInterface[];
  isLoading: boolean;
}

export interface TransactionCategoryGroupedInterface extends TransactionCategoryInterface {
  color: string;
  count: number;
  totalAmount: number;
}

const initialState: TransactionsStateInterface = {
  totalCount: 0,
  data: [],
  categories: [],
  totals: {
    moneyIn: 0,
    moneyOut: 0,
    net: {
      moneyIn: 0,
      moneyOut: 0,
    },
  },
  filters: {
    period: getDateRange(DATE_RANGE.LAST_30_DAYS),
    accountIds: [],
    categories: [],
  },
  isLoading: false,
};

export const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    updateCategory: (state, { payload }: PayloadAction<{ id: string; category: TransactionCategoryInterface }>) => {
      const item = state.data.find((f) => f.id === payload.id);
      if (item) {
        item.category = payload.category;
      }
    },
    updateFilters: (state, { payload }: PayloadAction<TransactionFilters>) => {
      const period = payload?.period || getDateRange(DATE_RANGE.LAST_30_DAYS);
      state.filters = {
        ...state.filters,
        ...payload,
        period,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(transactionsApi.endpoints.transactions.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(transactionsApi.endpoints.transactions.matchFulfilled, (state, { payload }) => {
        if (payload && payload?.totalCount) {
          state.data = payload.data.map((t) => ({ ...t, dateDay: format(new Date(t.date), DATE_FORMAT.DEFAULT) }));
          state.totalCount = payload.totalCount;
          state.totals = {
            moneyIn: payload.data.filter((f) => f.convertedAmount > 0).reduce((a, c) => a + c.convertedAmount, 0),
            moneyOut: Math.abs(
              payload.data.filter((f) => f.convertedAmount < 0).reduce((a, c) => a + c.convertedAmount, 0),
            ),
            net: {
              moneyIn: payload.data
                .filter((f) => f.convertedAmount > 0 && f.matching === null)
                .reduce((a, c) => a + c.convertedAmount, 0),
              moneyOut: Math.abs(
                payload.data
                  .filter((f) => f.convertedAmount < 0 && f.matching === null)
                  .reduce((a, c) => a + c.convertedAmount, 0),
              ),
            },
          };
        }
        state.isLoading = false;
      });
  },
});

export default slice.reducer;

export const transactionActions = slice.actions;

const selectTransactions = (state: StoreInterface) => state.transactions;

export const transactionSelector = createSelector(selectTransactions, (v) => v.data);
export const transactionTotalsSelector = createSelector(selectTransactions, (v) => v.totals);
export const transactionFiltersSelector = createSelector(selectTransactions, (v) => v.filters);
export const transactionIsLoadingSelector = createSelector(selectTransactions, (v) => v.isLoading);
