import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface FetchAuthUrlRequestInterface extends QueryOptions {}

export const fetchAuthUrlAction = createAsyncThunk(
  'calendar/Oauth',
  async (request: FetchAuthUrlRequestInterface, thunkApi): Promise<any> =>
    await requestGql<any>(request, thunkApi, 'appointmentServiceProviderAuthorizationUrl'),
);
