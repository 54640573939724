import { hotjar as reactHotjar } from 'react-hotjar';
import { __DEV__, captureException } from 'utils';

// Only use Hotjar in production
const showHotjar = !__DEV__;

export const hotjar = {
  initialize: (): void => {
    if (showHotjar) {
      const hjid = parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID, 10);
      const hjsv = parseInt(process.env.NEXT_PUBLIC_HOTJAR_SV, 10);
      reactHotjar.initialize(hjid, hjsv);
    }
  },
  identify: (id: string, args = {}): void => {
    try {
      reactHotjar.identify(id, args);
    } catch (e) {
      if (showHotjar) {
        captureException(e);
      }
    }
  },
  event: (eventName: string): void => {
    try {
      reactHotjar.event(eventName);
    } catch (e) {
      if (showHotjar) {
        captureException(e);
      }
    }
  },
  stateChange: (path: string): void => {
    try {
      reactHotjar.stateChange(path);
    } catch (e) {
      if (showHotjar) {
        captureException(e);
      }
    }
  },
};
