/**
 * Price IDs available from the Stripe Dashboard
 */
export const stripePricing = {
  monthly: process.env.NEXT_PUBLIC_MONTHLY_PRICE_ID,
  annual: process.env.NEXT_PUBLIC_ANNUAL_PRICE_ID,
  stuartMonthly: process.env.NEXT_PUBLIC_STUART_MONTHLY_PRICE_ID,
  stuartQuarterly: process.env.NEXT_PUBLIC_STUART_QUARTERLY_PRICE_ID,
  stuartAnnual: process.env.NEXT_PUBLIC_STUART_ANNUAL_PRICE_ID,
};
