import { IconProps } from 'common/otto-ui/icons/icon.type';

export const RefreshIcon = ({ color = 'currentColor', height, width }: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="m23 20v-6h-6" />
        <path d="m1 4v6h6" />
        <path
          d="m20.49 8.9996c-0.5072-1.4332-1.3691-2.7146-2.5055-3.7246-1.1363-1.01-2.51-1.7157-3.9928-2.0512-1.4828-0.33551-3.0265-0.28992-4.4869 0.13251-1.4604 0.42243-2.7901 1.2079-3.8648 2.2832l-4.64 4.36m22 4-4.64 4.36c-1.0747 1.0753-2.4044 1.8608-3.8648 2.2832s-3.0041 0.468-4.4869 0.1325c-1.4828-0.3355-2.8565-1.0411-3.9928-2.0511-1.1363-1.01-1.9983-2.2914-2.5055-3.7246"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default RefreshIcon;
