import { useLazyConversationUsersQuery } from 'api/conversation.api';
import { ConversationInterface } from 'common/interfaces';
import { useAppDispatch } from 'configuration/redux/store';
import uniq from 'lodash/uniq';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { addChatUsers, chatUsersSelector } from 'slices/chat';
import { getArrayDifferences } from 'utils/arrays';
import { useLoginHook } from 'views/login/hooks';

export const useConversationMembers = () => {
  const users = useSelector(chatUsersSelector);
  const [fetchUsers] = useLazyConversationUsersQuery();
  const dispatch = useAppDispatch();
  const { userData } = useLoginHook();

  const getMembers = useCallback(async (c: ConversationInterface[]) => {
    const userIds = uniq(c.map((p) => p.memberIds).flat());
    await getMemberUsers(userIds);
  }, []);

  const getMemberUsers = useCallback(
    async (ids: string[]) => {
      const userIds = getArrayDifferences(users.ids, ids);
      if (userIds.length) {
        await fetchUsers(
          {
            key: 'chat_users',
            ids: userIds,
          },
          true,
        )
          .unwrap()
          .then(async (res) => {
            if (res.length) {
              await dispatch(
                addChatUsers([
                  ...res,
                  {
                    id: userData.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    avatar: userData.avatar,
                  },
                ]),
              );
            }
          });
      }
    },
    [users.ids],
  );

  return {
    getMembers,
  };
};
