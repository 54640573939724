import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface FetchOwnerVariablesInterface {
  userId: string;
}

export interface FetchOwnerActionRequestInterface extends QueryOptions {
  variables: FetchOwnerVariablesInterface;
}

export const fetchOwnerAction = createAsyncThunk(
  'calendar/fetch-owner',
  async (request: FetchOwnerActionRequestInterface, thunkApi): Promise<any> =>
    await requestGql<any>(request, thunkApi, 'appointmentOwnerByUserId'),
);
