import { ReactNode, useEffect, useState } from 'react';

export const SafeHydrate2 = ({ children }: { children: any }): JSX.Element => (
  <div suppressHydrationWarning>
    {children}
    {/* TODO: Remove */}
    {/* {typeof window === 'undefined' ? null : children} */}
  </div>
);

export const SafeHydrate: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);
  if (!hydrated) {
    return null;
  }
  return <div suppressHydrationWarning>{children}</div>;
};
