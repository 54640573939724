import {
  Button,
  CheckIcon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ParagraphSmall,
  ParagraphXSmall,
  Select,
  styled,
  theme,
  useSnackbar,
  useTheme,
} from '@otto-finance/ui';
import { useInvestmentOperationMutation, useLazySearchHoldingsQuery } from 'api/holdings.api';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssetsInterface, FinanceHoldingSecurity } from 'slices/assets';
import { HoldingEditType } from './types';
import { HoldingTitle } from './holding-title';
import { useLoginHook } from 'views/login/hooks';
import { InvestmentOperation } from 'common/interfaces';
import { format } from 'date-fns';
import { DATE_FORMAT, TRACK_EVENT } from 'enums';
import { captureException } from 'utils';
import useTranslation from 'next-translate/useTranslation';
import { useToast } from 'common/otto-ui/toast';
import { Form, Formik } from 'formik';
import { useAccounts } from 'common/roq-hooks/use-accounts';
import { useAnalytics } from 'use-analytics';
import { HoldingFormFields, holdingValidationSchema } from 'components/view-holding';

type HoldingOption = {
  id: string;
  value: FinanceHoldingSecurity;
};

type HoldingsFormProps = {
  account: Partial<AssetsInterface>;
};

export const HoldingsForm = ({ account }: HoldingsFormProps): JSX.Element => {
  const [css] = useTheme();
  const { userData } = useLoginHook();
  const { t } = useTranslation('common');
  const [edit, setEdit] = useState<HoldingEditType>(null);
  const { enqueue } = useSnackbar();
  const { toast } = useToast();
  const { refetch } = useAccounts();
  const { track } = useAnalytics();
  const [searchHoldings, { isFetching, data }] = useLazySearchHoldingsQuery();
  const [addInvestment, { isLoading: isAddingInvestment }] = useInvestmentOperationMutation();
  const onSearch = useCallback(
    debounce((e) => e.target.value.length > 2 && searchHoldings({ key: e.target.value }), 400),
    [],
  );
  useEffect(() => () => onSearch.cancel(), [data]);
  const onSelect = useCallback(
    (option: HoldingOption) => {
      setEdit(option.value);
    },
    [account.holdings],
  );

  const options = useMemo(() => {
    const symbols = account.holdings.map((h) => h.security.symbol);
    return data
      ? data
          .map((d) => ({
            id: d.symbol,
            label: `${d.symbol} - ${d.companyName}`,
            value: d,
          }))
          .filter((f) => !symbols.includes(f.id))
      : [];
  }, [data, account.holdings]);

  const onSave = useCallback(async (v: Partial<HoldingEditType>) => {
    await addInvestment({
      userId: userData.id,
      accountId: account.id,
      symbol: v.symbol,
      type: InvestmentOperation.BUY,
      amount: v.amount,
      date: format(v.date || new Date(), DATE_FORMAT.DEFAULT),
    })
      .unwrap()
      .then(async () => {
        enqueue({
          startEnhancer: ({ size }) => <CheckIcon size={`${size}px`} />,
          message: `${v.symbol} was added`,
        });
        void track(TRACK_EVENT.INVEST_HOLDING_ADDED, { symbol: v.symbol, amount: v.amount });
        setEdit(null);
        await refetch();
      })
      .catch((err) => {
        captureException(err.message);
        toast({ message: t('errors.request'), variant: 'error' });
      });
  }, []);

  return (
    <div className={css({ display: 'grid', gap: theme.sizing.scale300 })}>
      <Select
        options={options}
        autoFocus={true}
        valueKey="label"
        labelKey="id"
        placeholder="Search for investments"
        maxDropdownHeight="350px"
        type={'search'}
        size="large"
        onInputChange={onSearch}
        onChange={({ option }) => onSelect(option as HoldingOption)}
        value={[]}
        getOptionLabel={({ option }) => <OptionLabel option={option as HoldingOption} />}
        isLoading={isFetching}
        overrides={{
          SearchIconContainer: {
            style: () => ({
              width: '24px',
            }),
          },
          SearchIcon: {
            props: {
              overrides: {
                Svg: {
                  style: () => ({
                    width: '100%',
                  }),
                },
              },
            },
          },
        }}
      />

      {edit && (
        <Modal onClose={() => setEdit(null)} closeable={false} isOpen={!!edit} size="auto" animate autoFocus>
          <Formik
            initialValues={{
              amount: edit?.amount || 1,
              date: edit?.date || null,
              symbol: edit.symbol,
            }}
            enableReinitialize
            validationSchema={holdingValidationSchema}
            onSubmit={onSave}
          >
            {({ isValid }) => (
              <Form>
                <div style={{ width: '360px' }}>
                  <ModalHeader>
                    <HoldingTitle data={edit} />
                  </ModalHeader>
                  <ModalBody>
                    <HoldingFormFields />
                  </ModalBody>
                  <ModalFooter>
                    {!isAddingInvestment && (
                      <Button onClick={() => setEdit(null)} kind={'tertiary'} size="compact">
                        Cancel
                      </Button>
                    )}
                    <Button isLoading={isAddingInvestment} disabled={!isValid} type="submit" size="compact">
                      Save
                    </Button>
                  </ModalFooter>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </div>
  );
};

const OptionExtras = styled<'div', unknown>('div', () => ({ display: 'grid', justifyItems: 'end' }));

const OptionLabel = ({ option: { value } }: { option: HoldingOption }): JSX.Element => (
  <HoldingTitle
    data={value}
    extras={
      <OptionExtras>
        <ParagraphXSmall>{value.exchangeShortName}</ParagraphXSmall>
        <ParagraphSmall>
          {value.price} {value.currency}
        </ParagraphSmall>
      </OptionExtras>
    }
  />
);
