import { IconProps } from 'common/otto-ui/icons/icon.type';

export const BenchmarkChartIcon = ({ height = 24, width = 24, color = 'currentColor' }: IconProps): JSX.Element => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0,0,256,256">
    <g
      fillOpacity="0.94902"
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
    >
      <g transform="scale(5.12,5.12)">
        <path d="M39,0v2h7.5625l-12.5625,12.5625l-3.28125,-3.28125l-0.71875,-0.6875l-0.71875,0.6875l-11.28125,11.28125l-3.28125,-3.28125l-0.71875,-0.6875l-0.71875,0.6875l-13,13l1.4375,1.4375l12.28125,-12.28125l3.28125,3.28125l0.71875,0.6875l0.71875,-0.6875l11.28125,-11.28125l3.28125,3.28125l0.71875,0.6875l0.71875,-0.6875l13.28125,-13.28125v7.5625h2v-11zM42,14v36h2v-36zM48,15v35h2v-35zM30,20v30h2v-30zM36,20v30h2v-30zM24,24v26h2v-26zM12,28v22h2v-22zM18,30v20h2v-20zM6,34v16h2v-16zM0,38v12h2v-12z"></path>
      </g>
    </g>
  </svg>
);
