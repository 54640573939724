import { gql } from 'graphql-request';

export const FinanceOverviewDocument = gql`
  query financeOverview($userId: String!, $convertTo: String) {
    financeOverview(userId: $userId, convertTo: $convertTo) {
      id
      dateAdded
      dateModified
      userId
      income {
        employment {
          amount
          times
          lastPaycheck
          description
          category
        }
        totalLastMonth
        totalCurrentMonth
      }
      spending {
        currentMonth
        lastMonth
        last3MonthsAvg
      }
      emergencyFund {
        averageSpending
        totalCash
        months
        accountIds
      }
      allowance {
        totalGlobalIsa
        percentageGlobalIsa
        totalLisa
        percentageLisa
      }
      debt {
        totalDebt
        totalNetWorth
        ratio
      }
      savings {
        totalLastMonth
        totalCurrentMonth
        percentageLastMonth
        percentageCurrentMonth
      }
      investing {
        totalInvested
        totalLiquidAssets
        percentage
      }
    }
  }
`;
