import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const EditIcon: React.FC<IconProps> = ({ gradientColors = ['#53DADA', '#61D57F'], height = 24, width = 24 }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20H21" stroke="url(#paint0_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14 12L7 19L3 20L4 16L16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L16.375 9.62498"
      stroke="url(#paint1_linear)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="12" y1="20.5" x2="20.604" y2="20.5" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientColors[0]} />
        <stop offset="1" stopColor={gradientColors[1]} />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="3" y1="11.4394" x2="19.368" y2="11.4394" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientColors[0]} />
        <stop offset="1" stopColor={gradientColors[1]} />
      </linearGradient>
    </defs>
  </svg>
);
