import { colors, theme } from '@otto-finance/ui';

export const CategoryGroupColors = {
  bills: theme.colors.beige100,
  business: colors.lime100,
  cash: colors.yellow100,
  entertainment: colors.purple100,
  gifts: colors.magenta100,
  groceries: colors.green100,
  health: colors.red100,
  household: colors.brown100,
  income: colors.orange100,
  mixed: colors.gray100,
  other: colors.platinum100,
  rent: colors.blue100,
  repayments: colors.white,
  taxes: colors.cobalt100,
  transfers: theme.colors.oil100,
  transport: colors.teal100,
};
