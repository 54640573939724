import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface DeleteSessionsVariablesInterface {
  id: string;
}

export interface DeleteSessionsActionRequestInterface extends MutationOptions {
  variables: DeleteSessionsVariablesInterface;
}

export const deleteSessionsAction = createAsyncThunk(
  'sessions/deleteOne',
  async (request: DeleteSessionsActionRequestInterface, thunkApi): Promise<void | SerializedError> =>
    await requestGql<void>(request, thunkApi),
);
