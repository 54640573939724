import { useChannel } from 'common/roq-hooks/use-pusher';
import { getConversationChannel } from '../chat.utils';
import { useCallback, useEffect } from 'react';
import { ChannelEvent, DATE_FORMAT } from 'enums';
import { useAppDispatch } from 'configuration/redux/store';
import { addChatMessage } from 'slices/chat';
import { useLoginHook } from 'views/login/hooks';
import { randId } from 'utils';
import format from 'date-fns/format';

export const useConversationEvents = (
  conversationId: string,
  { onAddBefore, onAddAfter }: { onAddBefore: () => void; onAddAfter: () => void },
) => {
  const channel = useChannel(getConversationChannel(conversationId));
  const dispatch = useAppDispatch();
  const { userData } = useLoginHook();

  useEffect(() => {
    if (!channel) return;

    channel.bind(ChannelEvent.messageAdded, onMessageAddedEvent);
    return () => {
      if (channel) {
        channel.unbind(ChannelEvent.messageAdded, onMessageAddedEvent);
      }
    };
  }, [channel]);

  const onMessageAddedEvent = useCallback(
    async (payload: { authorId: string; message: string; conversationId: string }) => {
      if (userData.id !== payload?.authorId) {
        onAddBefore();
        await dispatch(
          addChatMessage({
            id: randId(),
            body: payload.message,
            authorId: payload.authorId,
            createdAt: new Date().toISOString(),
            date: format(new Date(), DATE_FORMAT.DEFAULT),
            conversationId: payload.conversationId,
            messageStatus: {
              data: [],
            },
          }),
        );
        onAddAfter();
      }
    },
    [conversationId],
  );
};
