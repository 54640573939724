import { useAppDispatch } from 'configuration/redux/store';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { updateUIRouteAction } from 'slices/ui';
import { useAnalytics } from 'use-analytics';

// type PageEvent = 'routeChangeStart' | 'routeChangeComplete' | 'beforeHistoryChange' | 'routeChangeError' | 'hashChangeStart' | 'hashChangeComplete';

export const usePageChange = (): void => {
  const router = useRouter();
  const { page } = useAnalytics();
  const dispatch = useAppDispatch();

  useEffect(() => {
    onRouteChangeComplete(router.asPath);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, []);

  // new route landed
  const onRouteChangeComplete = useCallback((r: string) => {
    dispatch(updateUIRouteAction(r));
    page();
  }, []);
};
