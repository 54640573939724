import { useTheme } from '@otto-finance/ui';
import Img, { ImageProps } from 'next/image';
import { useState } from 'react';

interface Props extends ImageProps {
  fallbackComponent?: JSX.Element;
  unoptimized?: boolean;
  circle?: boolean;
  backgroundColor?: string;
  radius?: string;
}

export const Image = ({
  fallbackComponent,
  unoptimized = true,
  circle,
  backgroundColor,
  radius = '50%',
  ...rest
}: Props): JSX.Element => {
  const [imageError, setImageError] = useState(false);
  const [css] = useTheme();

  if (fallbackComponent && imageError) {
    return fallbackComponent;
  }

  return (
    <Img
      unoptimized={unoptimized}
      {...rest}
      onError={() => setImageError(true)}
      {...(circle && {
        className: css({
          borderRadius: radius,
          ...(backgroundColor && { backgroundColor }),
        }),
      })}
    />
  );
};
