import Pusher, { Channel } from 'pusher-js';
import React, { ReactNode, createContext, useCallback, useEffect, useRef } from 'react';
import { captureException, hasTokenExpired } from 'utils';
import { useLoginHook } from 'views/login/hooks';

const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_KEY;

const channelAuthEndpoint = `${process.env.NEXT_PUBLIC_BACKEND_URL}/pusher/auth`;

export interface PusherContextInterface {
  client?: Pusher;
  getChannel: <T extends Channel>(channelName: string) => T;
  subscribe: <T extends Channel>(channelName: string) => T;
  unsubscribe: (channelName: string) => void;
}

export const PusherContext = createContext<PusherContextInterface>(null);

const createChannelAuthorization = (accessToken: string) =>
  ({
    endpoint: channelAuthEndpoint,
    transport: 'ajax',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }) as const;

export const PusherProvider = ({ children }: { children: ReactNode }) => {
  const client = useRef<Pusher | null>(null);
  const { accessToken, isLogged } = useLoginHook();

  useEffect(() => {
    if (!isLogged || client.current) return;

    if (accessToken) {
      if (!PUSHER_KEY) {
        captureException('PUSHER_KEY_REQUIRED');
        return;
      }
      if (hasTokenExpired(accessToken)) {
        disconnect();
        return;
      }

      client.current = new Pusher(PUSHER_KEY, {
        cluster: 'eu',
        channelAuthorization: {
          ...createChannelAuthorization(accessToken),
        },
      });
    }

    return () => {
      disconnect();
    };
  }, [isLogged, accessToken]);

  const disconnect = useCallback(() => {
    if (client.current) {
      client.current.disconnect();
      client.current = null;
    }
  }, []);

  const subscribe = useCallback(<T extends Channel>(channelName: string): T => {
    if (!client.current) return;

    return client.current.subscribe(channelName) as T;
  }, []);

  const unsubscribe = useCallback((channelName: string) => {
    if (!client.current) return;

    client.current.unsubscribe(channelName);
  }, []);

  const getChannel = useCallback(<T extends Channel>(channelName: string): T => {
    if (!client.current) return;

    return client.current.channels.find(channelName) as T;
  }, []);

  return (
    <PusherContext.Provider
      value={{
        client: client.current,
        subscribe,
        unsubscribe,
        getChannel,
      }}
    >
      {children}
    </PusherContext.Provider>
  );
};
