import { gql } from 'graphql-request';

export const GetHistoricalNetWorthDocument = gql`
  query HistoricalNetWorth($userId: String!, $accountIds: [String], $convertTo: String, $from: String, $to: String) {
    historicalNetWorth(userId: $userId, accountIds: $accountIds, convertTo: $convertTo, from: $from, to: $to) {
      totalCount
      data {
        date
        amount
        currency
        currencyName
        liabilities
        assets
      }
    }
  }
`;

export const GetHistoricalIncomeExpensesDocument = gql`
  query HistoricalIncomeExpenses(
    $userId: String!
    $accountIds: [String]
    $convertTo: String!
    $from: String
    $to: String
  ) {
    historicalIncomeExpenses(userId: $userId, accountIds: $accountIds, convertTo: $convertTo, from: $from, to: $to) {
      totalCount
      data {
        date
        income
        expenses
        currency
      }
    }
  }
`;

export const GetHistoricalBalancesDocument = gql`
  query HistoricalBalances($userId: String!, $accountIds: [String], $convertTo: String, $from: String, $to: String) {
    historicalBalances(userId: $userId, accountIds: $accountIds, convertTo: $convertTo, from: $from, to: $to) {
      totalCount
      data {
        growth {
          finalAmount
          growthPercentage
          growthValue
          startAmount
          totalContributions
          totalWithdrawals
        }
        accountSummary {
          accountId
          dateAdded
          dateModified
          accountName
          type
          entity
          accountHolderName
          resource
          provider
        }
        balanceHistory {
          date
          amount
          currency
          convertedAmount
          convertedCurrency
          convertedTotalAmount
        }
        holdings {
          id
          symbol
          name
          amount
          growth {
            finalAmount
            growthPercentage
            growthValue
            startAmount
            totalContributions
            totalWithdrawals
          }
          balanceHistory {
            date
            amount
            currency
            convertedAmount
            convertedCurrency
            convertedTotalAmount
          }
          contributions {
            date
            amount
            currency
            convertedAmount
            convertedCurrency
          }
          withdrawals {
            date
            amount
            currency
            convertedAmount
            convertedCurrency
          }
        }
      }
    }
  }
`;
