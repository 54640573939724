import { useLazySiteSettingsQuery } from 'api/settings.api';
import { Backdrop } from 'common/otto-ui';
import { useAppDispatch } from 'configuration/redux/store';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uiSettingsSelector, updateThemeSettings } from 'slices/ui';
import { SETTINGS_VER } from 'slices/ui/ui.slice';
import { captureException } from 'utils';

export const AppConfigProvider = ({ children }: { children: ReactNode }) => {
  const [isLoaded, setLoaded] = useState(false);
  const { version } = useSelector(uiSettingsSelector);
  const [getSiteSettings, { isError, error }] = useLazySiteSettingsQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (SETTINGS_VER !== version) {
      getSiteSettings(undefined)
        .unwrap()
        .then((res) => {
          dispatch(updateThemeSettings(res));
          setLoaded(true);
        });
    } else {
      setLoaded(true);
    }
  }, []);

  if (isError) {
    captureException(error);
    return children;
  }

  return isLoaded ? children : <Backdrop fullScreen open={true} />;
};
