import * as Sentry from '@sentry/react';
import { __DEV__ } from 'utils';

export const captureException = (
  err?:
    | string
    | number
    | {
        [key: string]: any;
      },
  extra?: any,
  showDialog = false,
): void => {
  if (__DEV__) {
    console.error(err);
    if (extra) {
      console.log(extra);
    }
  }
  Sentry.captureException(err, {
    extra: {
      data: extra,
      showDialog,
    },
  });
};
