import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { Breadcrumbs } from 'common/otto-ui/breadcrumbs';
import { PageHeaderNav } from 'components/page-header-nav';
import { useTheme } from '@otto-finance/ui';
import { BreadcrumbItem } from 'common/otto-ui/breadcrumbs/breadcrumbs';

export const PageBreadcrumbs = ({ items }: { items: BreadcrumbItem[] }) => {
  const [css, theme] = useTheme();
  const router = useRouter();
  const onSelect = useCallback((item: BreadcrumbItem) => {
    if (item.href) {
      router.push(item.href);
    }
  }, []);
  return (
    <PageHeaderNav
      className={css({
        paddingLeft: theme.sizing.scale800,
        paddingRight: theme.sizing.scale800,
        minHeight: '40px',
        borderBottom: 'none',
      })}
    >
      <Breadcrumbs items={items} onSelect={onSelect} />
    </PageHeaderNav>
  );
};
