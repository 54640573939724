import { createSlice } from '@reduxjs/toolkit';
import { UserBenefitEnum } from 'enums';
import { fetchBenefitsAction, fetchUserBenefitAction } from 'slices/benefits/actions';

export interface BenefitsInterface {
  id: string;
  logo?: string;
  name?: string;
  link?: string;
}

export interface BenefitsUserInterface {
  id: string;
  benefit: BenefitsInterface;
  sessions: number;
  expiresAt: string;
}

export interface BenefitsUserStateInterface {
  isLoading: boolean;
  error: Error;
  benefit: BenefitsUserInterface;
}

export interface BenefitsStateInterface {
  isLoading: boolean;
  error: Error;
  list: BenefitsInterface[];
  userBenefit?: BenefitsUserStateInterface;
}

const initialState: BenefitsStateInterface = {
  isLoading: true,
  error: null,
  list: [],
  userBenefit: {
    benefit: null,
    isLoading: true,
    error: null,
  },
};

const benefitsSlice = createSlice({
  initialState,
  name: 'benefits',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBenefitsAction.fulfilled as any, (state, { payload }) => {
        state.list = payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchBenefitsAction.pending as any, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBenefitsAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })
      .addCase(fetchUserBenefitAction.fulfilled as any, (state, { payload }) => {
        state.userBenefit.benefit = payload;
        state.userBenefit.isLoading = false;
        state.userBenefit.error = null;
      })
      .addCase(fetchUserBenefitAction.pending as any, (state, { payload }) => {
        state.userBenefit.isLoading = true;
      })
      .addCase(fetchUserBenefitAction.rejected as any, (state, { payload }) => {
        state.userBenefit.error = payload;
        state.userBenefit.isLoading = false;
      });
  },
});

const availableBenefitCodes = [
  UserBenefitEnum.PERKBOX,
  UserBenefitEnum.DEEL,
  UserBenefitEnum.JUNO,
  UserBenefitEnum.HEKA,
];

export const benefitHasCodes = (name: string): boolean => availableBenefitCodes.includes(name as UserBenefitEnum);

export default benefitsSlice.reducer;
