import { createSlice } from '@reduxjs/toolkit';
import { contactUsAction } from 'slices/settings/actions';

export interface SettingsStateInterface {
  contactUs: {
    success: boolean;
    error: Error;
    request: boolean;
  };
}

const initialState: SettingsStateInterface = {
  contactUs: {
    success: false,
    error: null,
    request: false,
  },
};

const userSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    ResetRequest: (state) => {
      state.contactUs.success = false;
      state.contactUs.request = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactUsAction.pending as any, (state) => {
        state.contactUs.request = true;
        state.contactUs.success = false;
      })
      .addCase(contactUsAction.fulfilled as any, (state) => {
        state.contactUs.request = false;
        state.contactUs.success = true;
        state.contactUs.error = null;
      })
      .addCase(contactUsAction.rejected as any, (state, { payload }) => {
        state.contactUs.error = payload;
        state.contactUs.success = false;
        state.contactUs.request = false;
      });
  },
});
export const { ResetRequest } = userSlice.actions;
export default userSlice.reducer;
export { contactUsAction };
