import { DatesModel } from './common.interface';

export interface NotificationData {
  title: string;
  body: string;
}

export interface NotificationInterface {
  id: string;
  type: NotificationType;
  readAt?: Date;
  data: NotificationData;
  dates: DatesModel;
  isDeleted?: boolean;
}

export enum NotificationType {
  DEFAULT = 'default',
  SESSION_BOOKED = 'session.booked',
  DATA_SHARING_ACCEPT = 'dataSharingAccept',
  DATA_SHARING_REQUEST = 'dataSharingRequest',
}
