import { styled } from '@otto-finance/ui';
import { uiAlign, uiJustify } from './ui.constants';

export const Row = styled<
  'div',
  { gap?: string; align?: 'between' | 'end' | 'center'; crossalign?: 'center' | 'baseline'; wrap?: 'wrap' | 'nowrap' }
>('div', ({ $theme, gap, align, crossalign, wrap = 'wrap' }) => ({
  display: 'flex',
  flexWrap: wrap,
  gap: gap ?? $theme.sizing.scale400,
  ...(align && {
    justifyContent: uiJustify[align],
  }),
  ...(crossalign && {
    alignItems: uiAlign[crossalign],
  }),
}));
