import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SessionsInterface } from 'slices/sessions';
import { requestGql } from 'utils/request-gql';

export interface GetSessionActionRequestInterface extends QueryOptions {
  variables: {
    id: string;
  };
}

export const getSessionAction = createAsyncThunk('getSession', (request: GetSessionActionRequestInterface, thunkApi) =>
  requestGql<SessionsInterface>(request, thunkApi, 'appointment'),
);
