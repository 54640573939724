import { usePrevious } from 'common/roq-hooks/use-previous';
import { useChannel } from 'common/roq-hooks/use-pusher';
import { ChannelEvent } from 'enums';
import { useCallback, useEffect } from 'react';
import { useLoginHook } from 'views/login/hooks';

export const useIsTyping = ({ channelName, value }: { channelName: string; value: string }) => {
  const channel = useChannel(channelName);
  const prevCount = usePrevious(value);
  const { userData } = useLoginHook();
  useEffect(() => {
    if (value && prevCount.value) return;

    if (value && !prevCount.value) {
      triggerTyping(true);
    }
    if (!value && prevCount.value) {
      triggerTyping(false);
    }
  }, [value, prevCount.value, channelName]);

  const triggerTyping = useCallback(
    (status: boolean) =>
      channel.trigger(ChannelEvent.isTyping, { status, authorId: userData.id, name: userData.fullName }),
    [channelName],
  );

  return {
    triggerTyping,
  };
};
