import { combineReducers } from '@reduxjs/toolkit';
import { baseApi, categoriesApi, financeApi } from 'api';
import actions from 'slices/actions/actions.slice';
import admins from 'slices/admins/admins.slice';
import assets from 'slices/assets/assets.slice';
import auth from 'slices/auth-management/auth.slice';
import benefits from 'slices/benefits';
import calendar from 'slices/calendar/calendar.slice';
import chat from 'slices/chat/chat.slice';
import coachNotepad from 'slices/coach-notepad';
import confirmAlert from 'slices/confirm-alert/confirm-alert.slice';
import financeAuth from 'slices/finance-auth';
import financeOverview from 'slices/finance-overview';
import investments from 'slices/investments';
import investmentsWatchlist from 'slices/investments-watchlist';
import platform from 'slices/platform/platform.slice';
import seenFunctionality from 'slices/seen-functionality/seen-functionality.slice';
import session from 'slices/session/session.slice';
import sessions from 'slices/sessions/sessions.slice';
import settings from 'slices/settings/settings.slice';
import skipButton from 'slices/skip-button';
import survey from 'slices/survey/survey.slice';
import ui from 'slices/ui/ui.slice';
import userCoach from 'slices/user-coach';
import transactions from 'slices/transactions';
import { clearState } from './local-store';
import { companiesApi } from 'api/companies.api';
import documents from 'slices/documents';
import { logoutAction, logoutOnExpiredAction } from 'slices/auth-management/actions';

const appReducer = combineReducers({
  auth,
  assets,
  skipButton,
  survey,
  platform,
  chat,
  calendar,
  actions,
  sessions,
  seenFunctionality,
  admins,
  settings,
  session,
  ui,
  benefits,
  coachNotepad,
  confirmAlert,
  financeOverview,
  financeAuth,
  investments,
  investmentsWatchlist,
  userCoach,
  transactions,
  [baseApi.reducerPath]: baseApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  financeApi: financeApi.reducer,
  companiesApi: companiesApi.reducer,
  documents,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (state: any, action: any) => {
  /**
   * On logout resets the entire state
   */
  if (
    action.type === logoutAction.type ||
    action.type === 'logout/rejected' ||
    action.type === logoutOnExpiredAction.type
  ) {
    state = {
      ui: state.ui,
    };
    clearState(['auth']);
  }
  return appReducer(state, action);
};

// Adding the api middleware enables caching, invalidation, polling,
// and other useful features of `rtk-query`.
export const middlewares = [baseApi.middleware];

export default rootReducer;
