import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const InvestmentAnalysisIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
    >
      <g transform="scale(2,2)">
        <path d="M54,1c-29.22,0 -53,23.78 -53,53c0,29.22 23.78,53 53,53c13.54,0 25.89953,-5.11023 35.26953,-13.49023l32.32031,32.32031c0.59,0.59 1.3511,0.88086 2.1211,0.88086c0.77,0 1.53914,-0.29086 2.11914,-0.88086c1.17,-1.17 1.17,-3.07024 0,-4.24024l-32.32031,-32.32031c8.38,-9.37 13.49023,-21.73953 13.49023,-35.26953c0,-3.32 -0.30992,-6.65062 -0.91992,-9.89062c-0.31,-1.63 -1.87,-2.69867 -3.5,-2.38867c-1.63,0.31 -2.70063,1.88 -2.39063,3.5c0.53,2.88 0.81055,5.8293 0.81055,8.7793c0,25.92 -21.08,47 -47,47c-25.92,0 -47,-21.08 -47,-47c0,-25.92 21.08,-47 47,-47c12.55,0 24.36047,4.88953 33.23047,13.76953c1.17,1.17 3.07023,1.17 4.24023,0c1.17,-1.17 1.17,-3.07023 0,-4.24023c-10,-10.02 -23.3107,-15.5293 -37.4707,-15.5293zM102,1c-1.66,0 -3,1.34 -3,3c0,1.66 1.34,3 3,3h14.75977l-45.34961,45.33984c-0.76,0.75 -2.07008,0.76 -2.83008,0l-12.91992,-12.92969c-1.51,-1.51 -3.52016,-2.33984 -5.66016,-2.33984c-2.14,0 -4.15016,0.82984 -5.66016,2.33984l-23.45898,23.4707c-1.17,1.17 -1.17,3.06828 0,4.23828c0.58,0.59 1.34914,0.88086 2.11914,0.88086c0.77,0 1.53914,-0.29086 2.11914,-0.88086l23.46094,-23.45898c0.76,-0.75 2.07008,-0.76 2.83008,0l12.92969,12.92969c1.51,1.51 3.52016,2.33984 5.66016,2.33984c2.14,0 4.15016,-0.82984 5.66016,-2.33984l45.33984,-45.34961v14.75977c0,1.66 1.34,3 3,3c1.66,0 3,-1.34 3,-3v-22c0,-1.66 -1.34,-3 -3,-3z" />
      </g>
    </g>
  </svg>
);
