import { HistoricalBalance } from 'common/interfaces';
import { caching } from 'configuration/data';
import {
  GetHistoricalBalancesDocument,
  GetHistoricalIncomeExpensesDocument,
  GetHistoricalNetWorthDocument,
} from 'configuration/graphql/documents';
import { CURRENCY } from 'enums';
import meanBy from 'lodash/meanBy';
import omit from 'lodash/omit';
import { calcPercentageChange } from 'utils';
import { groupByMonth, SumArray } from 'utils/arrays';
import { getPercentage } from 'utils/numbers';

import { API_TAGS, baseApi } from './base.api';

export interface IncomeExpensesVariables {
  userId: string;
  accountIds?: string[];
  convertTo?: string;
  from?: string;
  to?: string;
  period?: PERIOD;
  raw?: boolean;
}

export enum PERIOD {
  ANNUAL = 'ANNUAL',
  MONTH = 'MONTH',
  SEMIANNUAL = 'SEMIANNUAL',
}

type IncomeExpense = {
  date: string;
  income: number;
  expenses: number;
};

type NetWorth = {
  date: string;
  amount: number;
  currency: string;
  currencyName?: string;
  assets?: number;
  liabilities?: number;
  convertedAmount?: number;
  convertedCurrency?: string;
  convertedCurrencyName?: string;
};

interface IncomeExpensesResponse {
  data: IncomeExpense[];
}

export interface IncomeExpensesInterface extends IncomeExpensesResponse {
  income?: number;
  expenses?: number;
  dailyAvg?: number;
  percentage?: number;
}

interface NetWorthResponse {
  data: NetWorth[];
}

export interface NetWorthInterfaceVariables {
  userId: string;
  accountIds?: string[];
  convertTo?: string;
  from?: string;
  to?: string;
  period?: PERIOD;
}

export interface NetWorthItemInterface {
  value: number;
  percentageChange: number;
  series: Series[];
}

export interface NetWorthInterface {
  amount: NetWorthItemInterface;
  liabilities: NetWorthItemInterface;
  assets: NetWorthItemInterface;
  currency?: string;
}

export enum NETWORTH_ITEM {
  AMOUNT = 'amount',
  LIABILITIES = 'liabilities',
  ASSETS = 'assets',
}

interface Series {
  x: string;
  y: number;
}

const defaultArgs = {
  convertTo: CURRENCY.GBP,
};

const transformNetworth = (data: NetWorth[], period: PERIOD) =>
  Object.values(NETWORTH_ITEM).reduce<NetWorthInterface>(
    (p, c) => {
      p[c] = {
        value: data[0][c] as number,
        percentageChange: 0,
        series: getNetWorthSeries(data, c, c === NETWORTH_ITEM.LIABILITIES, period !== PERIOD.MONTH).reverse(),
      };
      // Update start value
      p[c].percentageChange = Number(calcPercentageChange(p[c].series[0].y, p[c].value).toFixed(1));
      p.currency = data[0].currency;
      return p;
    },
    {
      amount: {
        value: 0,
        percentageChange: 0,
        series: [],
      },
      liabilities: {
        value: 0,
        percentageChange: 0,
        series: [],
      },
      assets: {
        value: 0,
        percentageChange: 0,
        series: [],
      },
    },
  );

export const balancesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    netWorth: builder.query<NetWorthInterface | null, NetWorthInterfaceVariables>({
      query: (arg) => ({
        variables: { ...defaultArgs, ...omit(arg, 'period') },
        document: GetHistoricalNetWorthDocument,
      }),
      providesTags: [API_TAGS.NETWORTH],
      transformResponse: (
        { historicalNetWorth: { data } }: { historicalNetWorth: NetWorthResponse },
        __,
        { period },
      ) => {
        if (!data || !data.length) return null;
        return transformNetworth(data, period!);
      },
    }),
    incomeExpenses: builder.query<IncomeExpensesInterface, IncomeExpensesVariables>({
      query: (arg) => ({
        variables: { ...defaultArgs, ...omit(arg, 'period', 'raw') },
        document: GetHistoricalIncomeExpensesDocument,
      }),
      providesTags: [API_TAGS.INCOME_EXPENSES],
      transformResponse: (
        { historicalIncomeExpenses: { data } }: { historicalIncomeExpenses: IncomeExpensesResponse },
        __,
        { raw },
      ) => {
        if (!data || !data.length) {
          return {
            income: 0,
            expenses: 0,
            data: [],
            dailyAvg: 0,
            percentage: 0,
          };
        }
        if (raw) {
          return { data };
        }
        const expenses = Math.abs(SumArray(data, 'expenses'));
        const income = SumArray(data, 'income');
        return {
          income,
          expenses,
          data,
          dailyAvg: Math.abs(meanBy(data, 'expenses')),
          percentage: getPercentage(income, expenses),
        };
      },
    }),
    historicalBalances: builder.query<
      {
        data: HistoricalBalance[];
        totalCount: number;
      },
      {
        userId: string;
        convertTo: string;
        from: string;
        to: string;
        accountIds?: string[];
      }
    >({
      query: (arg) => ({
        variables: arg,
        document: GetHistoricalBalancesDocument,
      }),
      providesTags: [
        {
          type: API_TAGS.HISTORICAL_BALANCES,
          id: 'ALL',
        },
      ],
      keepUnusedDataFor: caching.oneHour,
      transformResponse: (res: { historicalBalances }) => res.historicalBalances,
    }),
  }),
});

export const {
  useIncomeExpensesQuery,
  useNetWorthQuery,
  useLazyIncomeExpensesQuery,
  useHistoricalBalancesQuery,
  useLazyHistoricalBalancesQuery,
} = balancesApi;

/**
 * Generate chart series for NetWorth
 * for ANNUAL period we group dates by month.
 * @param n original array of dates
 * @param v Field that holds the Y-axis value
 * @param forcePos When true convert number to positive
 * @param groupMonths Group series by month when true
 * @returns
 */
const getNetWorthSeries = (n: NetWorth[], v: NETWORTH_ITEM, forcePos: boolean, groupMonths: boolean) =>
  groupMonths
    ? Object.values(groupByMonth(n)).map((s: NetWorth[]) => ({
        x: s[s.length - 1].date,
        y: forcePos ? Math.abs(s[0][v]) : s[0][v],
      }))
    : n.map((d) => ({ x: d.date, y: forcePos ? Math.abs(d[v]) : d[v] }));
