import { FinanceStockDatePeriod } from 'common/interfaces';
import { DATE_RANGE } from 'enums';

export const datePeriodMap = (p: DATE_RANGE): FinanceStockDatePeriod => {
  const priods = {
    [DATE_RANGE.LAST_5_DAYS]: FinanceStockDatePeriod.FIVE_DAYS,
    [DATE_RANGE.LAST_30_DAYS]: FinanceStockDatePeriod.ONE_MONTH,
    [DATE_RANGE.LAST_3_MONTHS]: FinanceStockDatePeriod.THREE_MONTHS,
    [DATE_RANGE.YEAR_TO_DATE]: FinanceStockDatePeriod.YEAR_TO_DATE,
    [DATE_RANGE.LAST_YEAR]: FinanceStockDatePeriod.ONE_YEAR,
    [DATE_RANGE.ALL_TIME]: FinanceStockDatePeriod.MAX,
  };
  return priods[p];
};
