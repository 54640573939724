import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const MailIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 22, width = 17 }) => (
  <svg width={width} height={height} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M3.64648 16.5137H18.5586C19.4635 16.5137 20.1667 16.2533 20.668 15.7324C21.1758 15.2116 21.4297 14.4499 21.4297 13.4473V3.23242C21.4297 2.23633 21.166 1.47786 20.6387 0.957031C20.1178 0.436198 19.3529 0.175781 18.3438 0.175781H3.44141C2.52995 0.175781 1.82031 0.436198 1.3125 0.957031C0.811198 1.47786 0.560547 2.23633 0.560547 3.23242V13.4473C0.560547 14.4499 0.820964 15.2116 1.3418 15.7324C1.86914 16.2533 2.63737 16.5137 3.64648 16.5137ZM3.67578 14.668C3.25911 14.668 2.9401 14.5605 2.71875 14.3457C2.4974 14.1309 2.38672 13.8021 2.38672 13.3594V3.32031C2.38672 2.88411 2.4974 2.55859 2.71875 2.34375C2.9401 2.12891 3.25911 2.02148 3.67578 2.02148H18.3242C18.7344 2.02148 19.0501 2.12891 19.2715 2.34375C19.4928 2.55859 19.6035 2.88737 19.6035 3.33008V13.3691C19.6035 13.8053 19.4928 14.1309 19.2715 14.3457C19.0501 14.5605 18.7344 14.668 18.3242 14.668H3.67578ZM11.0098 10.8789C11.3613 10.8789 11.7031 10.804 12.0352 10.6543C12.3737 10.498 12.709 10.2539 13.041 9.92188L20.4434 2.64648L19.2812 1.46484L12.0449 8.61328C11.8496 8.80208 11.6706 8.9388 11.5078 9.02344C11.3451 9.10807 11.179 9.15039 11.0098 9.15039C10.8405 9.15039 10.6712 9.10807 10.502 9.02344C10.3392 8.93229 10.1634 8.79557 9.97461 8.61328L2.71875 1.43555L1.53711 2.61719L8.96875 9.92188C9.30729 10.2539 9.64258 10.498 9.97461 10.6543C10.3132 10.804 10.6582 10.8789 11.0098 10.8789ZM19.1445 15.0488L20.3164 13.8672L14.457 8.04688L13.2754 9.21875L19.1445 15.0488ZM1.69336 13.8672L2.86523 15.0488L8.73438 9.21875L7.5625 8.04688L1.69336 13.8672Z"
    />
  </svg>
);
