import { InvestmentAccountInterface, InvestmentPortfolioType } from 'common/interfaces';
import { AccountTypeEnum } from 'enums';

export const getInvestmentPortfolio = (accounts: InvestmentAccountInterface[]): InvestmentPortfolioType => {
  return {
    isa: getPortfolioAccounts(accounts.filter((f) => f.policy !== null)),
    pension: getPortfolioAccounts(accounts.filter((f) => f.type === AccountTypeEnum.PENSION && f.policy === null)),
    other: getPortfolioAccounts(accounts.filter((f) => f.type !== AccountTypeEnum.PENSION && f.policy === null)),
  };
};

const getPortfolioAccounts = (accounts: InvestmentAccountInterface[]) => ({
  total: accounts.reduce((a, c) => a + c.values.value, 0),
  growth: accounts.reduce((a, c) => a + c.values.growthValue, 0),
  accounts,
});
