import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const TrophyIcon: React.FC<IconProps> = ({ height = 23, width = 21, color = '#CDD3CF' }) => (
  <svg width={width} height={height} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2555 16.8177V17.3329H10.2252V16.8177L9.71008 12.6965H11.7707L11.2555 16.8177ZM8.53039 12.1664C8.26973 12.1 2.1333 10.4994 1.11743 5.58905C0.665649 3.40225 1.2251 2.14014 1.77476 1.46582C2.45424 0.630763 3.38047 0.333008 4.02028 0.333008C4.87594 0.333008 5.53739 0.56843 5.98711 1.03309C6.55275 1.61881 6.5445 2.33126 6.54244 2.41163C6.54244 3.22144 5.99381 4.45418 4.48186 4.45418C4.41849 4.45418 4.0924 4.44491 3.75344 4.26615C3.26044 4.00755 2.98999 3.52434 2.98999 2.90874H4.02028C4.02028 3.20444 4.13516 3.30231 4.23407 3.35537C4.35152 3.41668 4.48134 3.42389 4.48237 3.42389C5.4627 3.42389 5.51266 2.56514 5.51266 2.39359C5.51266 2.36835 5.51163 2.01651 5.23706 1.73987C4.98825 1.49003 4.5787 1.3633 4.02028 1.3633C3.71017 1.3633 3.05748 1.52248 2.57324 2.11593C1.98803 2.83508 1.83349 3.96325 2.1266 5.3799C3.01266 9.66489 8.7246 11.1521 8.7823 11.166L8.53039 12.1664ZM12.8473 12.1814L12.5388 11.1985C12.5903 11.1835 17.7268 9.51807 19.1877 5.33766C19.6354 4.06009 19.6045 3.04989 19.095 2.33332C18.6031 1.6389 17.769 1.38751 17.3291 1.38751C15.8012 1.38751 15.7837 2.37556 15.7837 2.41781C15.7878 2.65529 15.8733 3.4481 16.814 3.4481C17.0458 3.44192 17.3291 3.34765 17.3291 2.93295H18.3594C18.3594 4.06576 17.4352 4.47839 16.814 4.47839C15.3015 4.47839 14.7534 3.24462 14.7534 2.41781C14.7534 1.70536 15.2917 0.35722 17.3291 0.35722C18.1126 0.35722 19.263 0.787882 19.9363 1.73575C20.4262 2.42605 20.863 3.67167 20.1619 5.67766C18.5268 10.3521 13.0786 12.1087 12.8473 12.1814Z"
      fill={color}
    />
    <path
      d="M13.3161 19.3933H8.1646V18.363C8.1646 17.7943 10.2252 16.8176 10.2252 16.8176H11.2555C11.2555 16.8176 13.3161 17.7943 13.3161 18.363V19.3933ZM15.8918 0.848022C15.8918 4.9692 14.8182 13.7267 10.7403 13.7267C6.66243 13.7267 5.58887 4.9692 5.58887 0.848022H15.8918Z"
      fill={color}
    />
    <path d="M7.13574 18.8784H14.3478V22.9996H7.13574V18.8784Z" fill={color} />
  </svg>
);
