import { IconProps } from 'common/otto-ui/icons';
import { FeatureFlagEnum } from 'configuration/data';
import { ROUTE_ENUM } from 'configuration/data/routers';

export interface SidebarItem {
  title: string;
  name: string;
  icon: React.FC<IconProps>;
  route?: ROUTE_ENUM;
  href: ROUTE_ENUM;
  acl?: string;
  /**
   * Is the link accessible
   */
  disabled?: boolean;
  /**
   * The feature tier associated with this page
   */
  tierSlug?: FeatureFlagEnum;
  /**
   * Shows a "New"
   */
  isNew?: boolean;
  /**
   * If this is a feature coming soon
   */
  isComingSoon?: boolean;
  /**
   * Description of the page
   */
  description?: string;
  isActive?: boolean;
  isExternal?: boolean;
}

export interface SidebarData extends SidebarItem {
  /**
   * If the page has any nested links
   */
  children?: Partial<SidebarItem>[];
}

export enum ACL {
  READ = 'read',
  CREATE = 'create',
}

export const AuthTypes = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  COACH: 'coach',
};

export const USER_STATUS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Invited', value: 'INVITED' },
];
