'use client';

import { themeUseStyletron } from '@otto-finance/ui';
import { MainHeader } from 'components/main-header';
import { useAppDispatch } from 'configuration/redux/store';
import { ReactNode, useEffect } from 'react';
import { setPageName } from 'slices/ui/ui.slice';
import { __DEV__ } from 'utils';
import styles from './main-layout.module.scss';
import { Navbar } from './navbar';
import { ColorTheme } from 'configuration/ui';
import { useLoginHook } from 'views/login/hooks';

export interface MainLayoutInterface {
  title?: string;
  pageTitle?: string;
  titleExtra?: ReactNode;
  pageColor?: string;
  className?: string;
  fixedWidth?: boolean;
  pageName?: string;
  children?: ReactNode;
}

export const MainLayout: React.FC<MainLayoutInterface> = ({
  title,
  pageTitle,
  pageColor = ColorTheme.mainLayoutColor,
  children,
  pageName,
}) => {
  const { guest } = useLoginHook();
  const dispatch = useAppDispatch();
  const [css] = themeUseStyletron();
  useEffect(() => {
    dispatch(setPageName(pageName));
  }, []);
  return (
    <div className={styles.root}>
      <MainHeader title={title || pageTitle} />
      {!guest ? <Navbar /> : <div />}
      <div className={styles.main}>
        <div className={css({ backgroundColor: pageColor })}>{children}</div>
      </div>
    </div>
  );
};
