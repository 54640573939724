import { SecurityClassStatus } from 'common/interfaces';

const RISK_CLASS_OFFSET = 0.1;

export const getAssetClassStatus = (target: number, current: number): SecurityClassStatus => {
  if (current < target - RISK_CLASS_OFFSET) {
    return SecurityClassStatus.UNDERWEIGHT;
  } else if (current > target + RISK_CLASS_OFFSET) {
    return SecurityClassStatus.OVERWEIGHT;
  }
  return SecurityClassStatus.BALANCED;
};
