import { createSlice } from '@reduxjs/toolkit';

export interface SeenFunctionalityInterface {
  activeCoach: any;
  selectedConversationOwnerId: string;
}

const initialState: SeenFunctionalityInterface = {
  activeCoach: null,
  selectedConversationOwnerId: '',
};

export const seenFunctionalitySlice = createSlice({
  name: 'seenFunctionality',
  initialState,
  reducers: {
    setActiveCoachForCustomer: (state, { payload }) => {
      state.activeCoach = payload;
    },
    setSelectedCustomerForCoach: (state, { payload }) => {
      state.selectedConversationOwnerId = payload;
    },
  },
});

export const { setActiveCoachForCustomer, setSelectedCustomerForCoach } = seenFunctionalitySlice.actions;

export default seenFunctionalitySlice.reducer;
