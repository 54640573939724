import {
  Button,
  LabelMedium,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  useSnackbar,
  useTheme,
} from '@otto-finance/ui';
import { useInvestmentOperationMutation } from 'api/holdings.api';
import { InvestmentHoldingSecurity, InvestmentOperation } from 'common/interfaces';
import { useToast } from 'common/otto-ui/toast';
import { useAccounts } from 'common/roq-hooks/use-accounts';
import { format } from 'date-fns';
import { DATE_FORMAT, TRACK_EVENT } from 'enums';
import { Form, Formik } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import { memo, useCallback, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { captureException } from 'utils';
import { useLoginHook } from 'views/login/hooks';
import { holdingValidationSchema } from './holding.validation';
import { HoldingFormFields } from './holding-form-fields';

type BuySellButtonProps = {
  accountId: string;
  security: InvestmentHoldingSecurity;
};

type FormValues = {
  amount: number;
  date: string;
};

const buttonLabel = {
  [InvestmentOperation.BUY]: 'Buy',
  [InvestmentOperation.SELL]: 'Sell',
};

const initialFormData: FormValues = {
  amount: 1,
  date: null,
};

export const BuySellButton = memo(({ accountId, security }: BuySellButtonProps): JSX.Element => {
  const [css, theme] = useTheme();
  const [option, setOption] = useState<InvestmentOperation>(null);
  const [investmentOperation, { isLoading }] = useInvestmentOperationMutation();
  const { refetch } = useAccounts();
  const { userData } = useLoginHook();
  const { t } = useTranslation('common');
  const { toast } = useToast();
  const { enqueue } = useSnackbar();
  const { track } = useAnalytics();
  const onSave = useCallback(
    async (v: FormValues) => {
      await investmentOperation({
        userId: userData.id,
        symbol: security.symbol,
        accountId,
        date: format(v.date ? new Date(v.date) : new Date(), DATE_FORMAT.DEFAULT),
        type: option,
        amount: v.amount,
      })
        .unwrap()
        .then(async () => {
          void track(
            option === InvestmentOperation.BUY ? TRACK_EVENT.INVEST_HOLDING_BUY : TRACK_EVENT.INVEST_HOLDING_SELL,
            {
              symbol: security.symbol,
              amount: v.amount,
            },
          );
          enqueue({
            startEnhancer: () => <LabelMedium color={theme.colors.white}>{security.symbol}</LabelMedium>,
            message: t(`investments:buySell.success.${buttonLabel[option].toLowerCase()}`, { count: v.amount }),
          });
          setOption(null);
          await refetch();
        })
        .catch((err) => {
          captureException(err.message);
          toast({ message: t('errors.request'), variant: 'error' });
        });
    },
    [option],
  );
  return (
    <>
      <div className={css({ display: 'flex', gap: theme.sizing.scale200 })}>
        <Button onClick={() => setOption(InvestmentOperation.BUY)} type="button" size="compact" kind="secondary">
          Buy shares
        </Button>
        <Button onClick={() => setOption(InvestmentOperation.SELL)} type="button" size="compact" kind="secondary">
          Sell shares
        </Button>
      </div>
      {option && (
        <Modal isOpen={true} onClose={() => setOption(null)}>
          <Formik
            initialValues={initialFormData}
            enableReinitialize
            validationSchema={holdingValidationSchema}
            onSubmit={onSave}
          >
            {({ values, isValid }) => (
              <Form>
                <ModalHeader>
                  {buttonLabel[option]} - {security.symbol} {security.name}
                </ModalHeader>
                <ModalBody>
                  <HoldingFormFields />
                </ModalBody>
                <ModalFooter>
                  <ModalButton kind="tertiary" size="compact" shape="pill" disabled={isLoading}>
                    Cancel
                  </ModalButton>
                  <ModalButton type="submit" size="compact" shape="pill" isLoading={isLoading} disabled={!isValid}>
                    {values.amount
                      ? t(`investments:buySell.buttonLabel.${buttonLabel[option].toLowerCase()}`, {
                          count: values.amount,
                        })
                      : 'Select amount'}
                  </ModalButton>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
});
