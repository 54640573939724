import { useTheme } from '@otto-finance/ui';
import { HTMLAttributes, ReactNode } from 'react';

interface InfoMessageInterface extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  kind: 'warning' | 'positive' | 'negative' | 'info';
  place?: 'start' | 'center' | 'end';
}

export const InfoMessage = ({ children, kind = 'info', place, ...rest }: InfoMessageInterface) => {
  const [css, theme] = useTheme();
  return (
    <div
      className={
        place
          ? css({
              display: 'grid',
              justifyItems: place,
            })
          : null
      }
    >
      <div
        {...rest}
        className={css({
          padding: theme.sizing.scale400,
          borderRadius: theme.sizing.scale200,
          backgroundColor: theme.colors[`${kind}50`],
        })}
      >
        {children}
      </div>
    </div>
  );
};
