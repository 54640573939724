import { RegionEnum } from 'enums';

export const securityRegionMap = {
  [RegionEnum.EUROPE]: 'Europe',
  [RegionEnum.NORTH_AMERICA]: 'North America',
  [RegionEnum.SOUTH_AMERICA]: 'South America',
  [RegionEnum.AUSTRALIA]: 'Australia',
  [RegionEnum.ASIA]: 'Asia',
  [RegionEnum.AFRICA]: 'Africa',
  [RegionEnum.OTHER]: 'Unknown',
};
