import { useTheme } from '@otto-finance/ui';

type SkeletonBoxProps = {
  width?: string;
  height?: string;
  radius?: string;
  animate?: boolean;
  delay?: string;
};

export const SkeletonBox = ({ animate, delay, height, width, radius }: SkeletonBoxProps) => {
  const [css, theme] = useTheme();
  return (
    <div
      className={css({
        backgroundColor: theme.colors.white,
        borderRadius: radius || theme.sizing.scale600,
        width,
        height,
        ...(animate && {
          opacity: 0.4,
          animationDuration: '2s',
          animationIterationCount: 'infinite',
          animationDelay: delay,
          animationName: {
            '50%': {
              opacity: 1,
            },
          },
        }),
      })}
    />
  );
};
