import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const OttoShortIcon = ({ color = 'currentColor', size = 24 }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0246 12.0548C19.0246 12.8857 18.9152 13.6882 18.6964 14.4621C18.4857 15.2278 18.1859 15.9488 17.797 16.625C17.4081 17.293 16.9381 17.9039 16.3871 18.4579C15.8361 19.0119 15.2284 19.4884 14.564 19.8876C13.8996 20.2786 13.1825 20.5801 12.4127 20.7919C11.643 21.0118 10.8448 21.1218 10.0184 21.1218C9.19188 21.1218 8.39376 21.0118 7.624 20.7919C6.86234 20.5801 6.14525 20.2786 5.47272 19.8876C4.80829 19.4884 4.20059 19.0119 3.6496 18.4579C3.09861 17.9039 2.6246 17.293 2.22757 16.625C1.83863 15.9488 1.53478 15.2278 1.31601 14.4621C1.10534 13.6882 1 12.8857 1 12.0548C1 11.2239 1.10534 10.4214 1.31601 9.64751C1.53478 8.8736 1.83863 8.15264 2.22757 7.48463C2.6246 6.81662 3.09861 6.20563 3.6496 5.65167C4.20059 5.09771 4.80829 4.62522 5.47272 4.23419C6.14525 3.84316 6.86234 3.54174 7.624 3.32993C8.39376 3.10998 9.19188 3 10.0184 3C10.8448 3 11.643 3.10998 12.4127 3.32993C13.1825 3.54174 13.8996 3.84316 14.564 4.23419C15.2284 4.62522 15.8361 5.09771 16.3871 5.65167C16.9381 6.20563 17.4081 6.81662 17.797 7.48463C18.1859 8.15264 18.4857 8.8736 18.6964 9.64751C18.9152 10.4214 19.0246 11.2239 19.0246 12.0548ZM15.5606 12.0548C15.5606 11.289 15.4148 10.5681 15.1231 9.8919C14.8314 9.2076 14.4344 8.61698 13.932 8.12005C13.4377 7.61497 12.8503 7.21579 12.1696 6.92252C11.4971 6.62925 10.78 6.48261 10.0184 6.48261C9.2486 6.48261 8.52746 6.62925 7.85493 6.92252C7.1824 7.21579 6.59495 7.61497 6.09258 8.12005C5.59021 8.61698 5.19317 9.2076 4.90148 9.8919C4.60978 10.5681 4.46393 11.289 4.46393 12.0548C4.46393 12.8206 4.60978 13.5415 4.90148 14.2177C5.19317 14.8857 5.59021 15.4722 6.09258 15.9773C6.59495 16.4824 7.1824 16.8816 7.85493 17.1748C8.52746 17.4681 9.2486 17.6147 10.0184 17.6147C10.78 17.6147 11.4971 17.4681 12.1696 17.1748C12.8503 16.8816 13.4377 16.4824 13.932 15.9773C14.4344 15.4722 14.8314 14.8857 15.1231 14.2177C15.4148 13.5415 15.5606 12.8206 15.5606 12.0548Z"
      fill={color}
    />
    <path
      d="M23 19.4966C23 19.7899 22.9473 20.0668 22.842 20.3275C22.7367 20.5801 22.5908 20.8 22.4044 20.9874C22.2181 21.1748 21.9993 21.3214 21.7481 21.4273C21.4969 21.5413 21.2255 21.5984 20.9338 21.5984C20.6502 21.5984 20.3788 21.5413 20.1195 21.4273C19.8683 21.3214 19.6455 21.1748 19.451 20.9874C19.2646 20.8 19.1147 20.5801 19.0013 20.3275C18.896 20.0668 18.8433 19.7899 18.8433 19.4966C18.8433 19.2115 18.896 18.9426 19.0013 18.6901C19.1147 18.4375 19.2646 18.2176 19.451 18.0302C19.6455 17.8428 19.8683 17.6962 20.1195 17.5903C20.3788 17.4763 20.6502 17.4192 20.9338 17.4192C21.2255 17.4192 21.4969 17.4763 21.7481 17.5903C21.9993 17.6962 22.2181 17.8428 22.4044 18.0302C22.5908 18.2176 22.7367 18.4375 22.842 18.6901C22.9473 18.9426 23 19.2115 23 19.4966Z"
      fill={color}
    />
  </svg>
);
