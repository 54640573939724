import { HeadingSmall, useTheme } from '@otto-finance/ui';
import { ButtonAccent } from 'common/otto-ui/button';
import { Link } from 'common/otto-ui/link/link-v2';
import { Column, Row } from 'common/otto-ui/ui';
import { FormTextField } from 'components/form';
import { InfoMessage } from 'components/info-message';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { Form, Formik } from 'formik';
import { loginFormSchema } from 'views/login/schemas';

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormPartialInterface {
  onSubmit: (formValues: LoginFormValues) => void;
  isLoading?: boolean;
  errorMessage?: string;
}

export const LoginFormPartial: React.FC<LoginFormPartialInterface> = ({ isLoading, errorMessage, onSubmit }) => {
  const [css, theme] = useTheme();
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      enableReinitialize
      validationSchema={loginFormSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <div className={css({ display: 'grid', gap: theme.sizing.scale1200 })}>
            <HeadingSmall className={css({ fontWeight: theme.weights.medium })}>Welcome Back!</HeadingSmall>
            <div>
              <FormTextField
                label="Email address"
                name="email"
                type="email"
                placeholder="Email Address"
                autoComplete="on"
              />
              <FormTextField
                label="Password"
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="current-password"
              />
              <Row align="end">
                <Link href={ROUTE_ENUM.FORGOT_PASS}>Forgot Password?</Link>
              </Row>
            </div>
            <Column justify="stretch">
              {errorMessage && <InfoMessage kind="negative">{errorMessage}</InfoMessage>}
              <ButtonAccent isLoading={isLoading} disabled={isLoading} type="submit" size="large">
                Login
              </ButtonAccent>
            </Column>
          </div>
        </Form>
      )}
    </Formik>
  );
};
