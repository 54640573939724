import { useCallback, useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T): { value: T; clear: () => void } => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  const clear = useCallback(() => {
    ref.current = null;
  }, []);
  return {
    value: ref.current,
    clear,
  };
};
