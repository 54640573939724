import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { AnchorHTMLAttributes, MouseEventHandler, ReactNode } from 'react';

export enum LinkUnderlineEnum {
  ALWAYS = 'always',
  HOVER = 'hover',
  NONE = 'none',
}

export enum LinkVariantEnum {
  HEADER1 = 'header1',
  HEADER2 = 'header2',
  HEADER3 = 'header3',
  SUBTITLE = 'subtitle',
  BODY1 = 'body1',
  BODY2 = 'body2',
  CAPTION = 'caption',
  INHERIT = 'inherit',
}

export enum LinkColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  INHERIT = 'inherit',
}

export interface LinkInterface extends AnchorHTMLAttributes<HTMLAnchorElement> {
  /** Variant of link style */
  variant?: Lowercase<keyof typeof LinkVariantEnum>;
  /** Color of link */
  color?: Lowercase<keyof typeof LinkColorEnum>;
  /** Underline  */
  underline?: Lowercase<keyof typeof LinkUnderlineEnum>;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  /**
   * Is the link disabled
   */
  isDisabled?: boolean;
}

/** Just a simple link component */
export const Link = ({
  className,
  href,
  target,
  variant = LinkVariantEnum.INHERIT,
  color = LinkColorEnum.PRIMARY,
  underline = LinkUnderlineEnum.HOVER,
  children,
  onClick,
  isDisabled,
  ...props
}: LinkInterface) => {
  const mainClassName = clsx(
    {
      'text-6xl': variant === LinkVariantEnum.HEADER1,
      'text-4xl': variant === LinkVariantEnum.HEADER2,
      'text-3xl': variant === LinkVariantEnum.HEADER3,
      'text-2xl': variant === LinkVariantEnum.SUBTITLE,
      'text-lg': variant === LinkVariantEnum.BODY1,
      'text-base': variant === LinkVariantEnum.BODY2,
      'text-sm': variant === LinkVariantEnum.CAPTION,
      'text-primary dark:text-primary-lightest': color === LinkColorEnum.PRIMARY,
      'text-secondary': color === LinkColorEnum.SECONDARY,
      'text-title dark:text-offWhite': color === LinkColorEnum.DEFAULT,
      'text-current': color === LinkColorEnum.INHERIT,
      'no-underline hover:no-underline': underline === LinkUnderlineEnum.NONE,
      'no-underline hover:underline': underline === LinkUnderlineEnum.HOVER,
      'underline hover:underline': LinkUnderlineEnum.ALWAYS,
      'cursor-pointer': !isDisabled,
    },
    className,
  );

  const router = useRouter();

  return Boolean(router) && href && !isDisabled ? (
    <NextLink href={href} passHref>
      <a className={mainClassName} target={target} onClick={onClick && !isDisabled ? onClick : undefined} {...props}>
        {children}
      </a>
    </NextLink>
  ) : (
    <a
      className={mainClassName}
      href={isDisabled ? undefined : href}
      target={target}
      onClick={onClick && !isDisabled ? onClick : undefined}
      {...props}
    >
      {children}
    </a>
  );
};
