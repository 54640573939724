export enum ChannelEvent {
  isTyping = 'client-is-typing',
  memberAdded = 'pusher:member_added',
  memberRemoved = 'pusher:member_removed',
  taskUpdated = 'client-taskUpdated',
  taskCreated = 'client-taskCreated',
  taskDeleted = 'client-taskDeleted',
  goalEdited = 'client-goalEdited',
  goalDeleted = 'client-goalDeleted',
  messageAdded = 'message-added',
}
