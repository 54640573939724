import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const Edit2Icon: React.FC<IconProps> = ({ height = 22, width = 22, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5563 3.397C15.072 2.88143 15.7714 2.5918 16.5006 2.5918C17.2299 2.5918 17.9293 2.88143 18.445 3.397L18.6027 3.55467C19.1182 4.0704 19.4079 4.76978 19.4079 5.49902C19.4079 6.22825 19.1182 6.92764 18.6027 7.44336L17.0103 9.03752L7.98164 18.0653C7.8643 18.1827 7.71728 18.266 7.55629 18.3064L3.88944 19.2231C3.73581 19.2616 3.57484 19.2596 3.42221 19.2174C3.26957 19.1751 3.13048 19.0941 3.0185 18.9821C2.90651 18.8701 2.82545 18.731 2.78321 18.5784C2.74097 18.4257 2.73899 18.2648 2.77747 18.1111L3.69418 14.4443C3.73456 14.2833 3.81788 14.1363 3.93528 14.0189L13.0162 4.93799L14.5563 3.39792V3.397ZM17.1488 4.69323C16.9768 4.52137 16.7437 4.42483 16.5006 4.42483C16.2576 4.42483 16.0244 4.52137 15.8525 4.69323L14.9486 5.59711L16.3741 7.07943L17.3064 6.14713C17.4783 5.97522 17.5748 5.7421 17.5748 5.49902C17.5748 5.25594 17.4783 5.02281 17.3064 4.8509L17.1488 4.69323ZM15.077 8.37566L13.6515 6.89425L5.41118 15.1355L4.92624 17.0734L6.86509 16.5885L15.0779 8.37566H15.077Z"
    />
  </svg>
);
