import { IconProps } from 'common/otto-ui/icons/icon.type';

export const DateSpanIcon = ({ height = 24, width = 24, color = 'currentColor' }: IconProps): JSX.Element => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0,0,256,256">
    <g
      fillOpacity="0.94902"
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
    >
      <g transform="scale(5.12,5.12)">
        <path d="M12,0c-1.09375,0 -2,0.90625 -2,2v2h-6c-1.09375,0 -2,0.90625 -2,2v40c0,1.09375 0.90625,2 2,2h42c1.09375,0 2,-0.90625 2,-2v-40c0,-1.09375 -0.90625,-2 -2,-2h-6v-2c0,-1.09375 -0.90625,-2 -2,-2h-2c-1.09375,0 -2,0.90625 -2,2v2h-18v-2c0,-1.09375 -0.90625,-2 -2,-2zM12,2h2v6h-2zM36,2h2v6h-2zM4,6h6v2c0,1.09375 0.90625,2 2,2h2c1.09375,0 2,-0.90625 2,-2v-2h18v2c0,1.09375 0.90625,2 2,2h2c1.09375,0 2,-0.90625 2,-2v-2h6v8h-42zM4,16h42v30h-42zM16.98047,22.98828c-0.26172,0.00781 -0.50781,0.11719 -0.6875,0.30469l-6.70703,6.70703l6.70703,6.70703c0.25,0.26172 0.625,0.36719 0.97266,0.27344c0.35156,-0.08984 0.625,-0.36328 0.71484,-0.71484c0.09375,-0.34766 -0.01172,-0.72266 -0.27344,-0.97266l-4.29297,-4.29297h23.17188l-4.29297,4.29297c-0.26172,0.25 -0.36719,0.625 -0.27344,0.97266c0.08984,0.35156 0.36328,0.625 0.71484,0.71484c0.34766,0.09375 0.72266,-0.01172 0.97266,-0.27344l6.70703,-6.70703l-6.70703,-6.70703c-0.1875,-0.19531 -0.44531,-0.30078 -0.71875,-0.30469c-0.40625,0.00391 -0.76953,0.25 -0.92578,0.625c-0.15234,0.37891 -0.0625,0.80859 0.23047,1.09375l4.29297,4.29297h-23.17187l4.29297,-4.29297c0.29688,-0.28906 0.38281,-0.72656 0.22266,-1.10547c-0.16016,-0.38281 -0.53516,-0.625 -0.94922,-0.61328z"></path>
      </g>
    </g>
  </svg>
);
