export enum DATE_RANGE {
  TODAY = 'TODAY',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_5_DAYS = 'LAST_5_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_WEEK = 'LAST_WEEK',
  LAST_DAY = 'LAST_DAY',
  ALL_TIME = 'ALL_TIME',
}
