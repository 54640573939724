import { MutationOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface SubmitSurveyResultInterface {
  selectedOptions: any[];
}
export interface SubmitSurveyResultVariablesInterface {
  sectionName: string;
  pageName: string;
  elementName: string;
  sessionId: string;
  result: SubmitSurveyResultInterface;
}

export interface SubmitSurveyResultActionRequestInterface extends MutationOptions {
  variables: SubmitSurveyResultVariablesInterface;
}

export const submitSurveyResultAction = createAsyncThunk(
  'survey/submit',
  async (request: SubmitSurveyResultActionRequestInterface, thunkApi): Promise<any> =>
    await requestGql<any>(request, thunkApi, 'createResult'),
);
