import clsx from 'clsx';
import { CheckBox } from 'common/otto-ui/check-box';
import noop from 'lodash/noop';
import { MouseEventHandler, OptionHTMLAttributes, ReactNode, ReactText, useEffect, useState } from 'react';

export interface SelectOptionInterface extends Omit<OptionHTMLAttributes<HTMLElement>, 'value' | 'onChange'> {
  value: ReactText;
  checkbox?: boolean;
  selected?: boolean;
  highlight?: boolean;
  children?: ReactNode;
  onChange?: (value: ReactText) => void;
  finalOption?: boolean;
}

export const SelectOption = ({
  value /** Value of option */,
  checkbox /** Checkbox inside option */,
  selected /** Selected option in select */,
  highlight /** Highlighted option in select */,
  disabled /** Disabled status of select */,
  className /** Classes will be added to default component classes */,
  children /** Options children */,
  onClick /** Click event listener function */,
  onChange /** Change event listener function */,
  ...props
}: SelectOptionInterface) => {
  const [ref, setRef] = useState<HTMLElement>(null);

  const classes = {
    wrapper: clsx(
      'h-12 px-3 flex flex-row justify-start items-center text-base transition duration-150 cursor-pointer rounded-lg',
      {
        'pointer-events-auto': !disabled,
        'pointer-events-none': disabled,
      },
      disabled
        ? 'text-line'
        : {
            'rounded-t-sm bg-transparent text-body dark:text-offWhite': !selected && !highlight,
            'bg-primary dark:bg-background-dark-background3 dark:hover:bg-background-dark-background4 text-offWhite dark:text-white':
              selected || highlight,
          },
      'dark:bg-background-dark-background3 dark:text-white',
      className,
    ),
  };

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    if (onChange) {
      onChange(value);
    }
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    if (ref && highlight) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [ref, highlight]);

  return (
    <div ref={setRef} className={classes.wrapper} onClick={handleClick} {...props}>
      <span className="pointer-events-none">
        {checkbox ? (
          <CheckBox
            labelComponent={children}
            hoveredClassName="hover:bg-primary dark:hover:bg-primary-light hover:text-offWhite dark:hover:text-title"
            checked={selected}
            onChange={noop}
          />
        ) : (
          <span>{children}</span>
        )}
      </span>
    </div>
  );
};
