import { Badge, ChevronRightIcon, LabelSmall, Modal, ModalBody, ParagraphSmall, useTheme } from '@otto-finance/ui';
import { HoldingTitle } from './holding-title';
import { HoldingsForm } from './holdings-form';
import { memo } from 'react';
import { Backdrop } from 'common/otto-ui';
import { useInvestmentAsset } from 'common/roq-hooks';

type AddHoldingsProps = {
  account: string;
  onClose?: () => void;
};

export const AddHoldings = memo(({ account, onClose }: AddHoldingsProps): JSX.Element => {
  const acc = useInvestmentAsset(account);
  const [css, theme] = useTheme();
  return !acc ? (
    <Backdrop open={true} fullScreen />
  ) : (
    <Modal isOpen={true} onClose={onClose}>
      <ModalBody>
        <div className={css({ display: 'grid', gap: theme.sizing.scale400 })}>
          <div className={css({ display: 'flex', alignItems: 'center' })}>
            <Badge content={acc.name} hierarchy="secondary" color="primary" />
            <ChevronRightIcon />
            <Badge content="Holdings" hierarchy="secondary" color="primary" />
          </div>
          <div className={css({ marginBottom: theme.sizing.scale400 })}>
            <HoldingsForm account={acc} />
          </div>
          <div className={css({ display: 'grid', gap: theme.sizing.scale400 })}>
            <div className={css({ display: 'flex', alignItems: 'center' })}>
              <LabelSmall>Holdings</LabelSmall>
              <ParagraphSmall color={theme.colors.grayScale300}>- {acc.holdings.length}</ParagraphSmall>
            </div>
            <div className={css({ display: 'grid', gap: theme.sizing.scale600 })}>
              {acc.holdings.map((h) => (
                <HoldingTitle
                  key={h.id}
                  data={{
                    companyName: h.security.name,
                    symbol: h.security.symbol,
                    image: h.security.iconUrl,
                  }}
                  color={acc?.color}
                />
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});
