import { HistoricalBalance } from 'common/interfaces';
import { AccountSubtypeEnum } from 'configuration/assets';
import { AccountPolicyEnum } from 'configuration/assets/account-types';
import { caching } from 'configuration/data';
import {
  CreateManualAccountDocument,
  GetAccountsDocument,
  GetCryptoAccountsDocument,
  GetHistoricalBalancesDocument,
  RemoveAccountDocument,
  UpdateAccountDocument,
} from 'configuration/graphql/documents';
import { CURRENCY, WealthProviderEnum, AccountTypeEnum } from 'enums';
import { AssetsInterface } from 'slices/assets';

import { accountsCacheTags, API_TAGS, baseApi } from './base.api';

interface FetchAccountsArgs {
  userId: string;
  convertTo?: string;
  accountIds?: string[];
  from?: string;
  to?: string;
}

interface FetchAccountsResponse {
  financeAccounts: {
    data: AssetsInterface[];
    totalCount: number;
  };
}

interface FetchHistoricalBalanceResponse {
  historicalBalances: {
    data: HistoricalBalance[];
    totalCount: number;
  };
}

interface FetchCryptoAccountsResponse {
  cryptoAccounts: {
    data: AssetsInterface[];
    totalCount: number;
  };
}

interface AccountUpdateArgs {
  name?: string;
  alias?: string;
  type?: AccountTypeEnum;
  subtype?: AccountSubtypeEnum;
  policy?: AccountPolicyEnum;
  currency?: string;
  balance?: number;
  accountHolderName?: string;
  accountHolderEmail?: string;
  entityName?: string;
}

export interface UpdateAccountArgs {
  id: string;
  account: AccountUpdateArgs;
}

interface CreateManualAccountArgsInterface {
  userId: string;
  alias?: string;
  name: string;
  type: AccountTypeEnum;
  subtype: AccountSubtypeEnum;
  currency: string;
  balance: number;
  entityName: string;
  country: string;
}

export const accountsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAccounts: builder.query<AssetsInterface[], FetchAccountsArgs>({
      query: (variables) => ({
        variables: variables,
        document: GetAccountsDocument,
      }),
      transformResponse: (response: FetchAccountsResponse) => {
        const { totalCount, data } = response.financeAccounts;
        if (!totalCount) {
          return [];
        }
        return data.map((a) => {
          a.entity.iconUrl =
            !a.entity.iconUrl && !a.entity.iconBase64
              ? null
              : a.entity.iconUrl
              ? a.entity.iconUrl
              : 'data:image/png;base64,' + a.entity.iconBase64;
          a.entity.iconBase64 = null;
          a.nameOriginal = a.name;
          a.name = a.alias || a.name;
          return a;
        });
      },
      keepUnusedDataFor: caching.oneHour,
      providesTags: [
        {
          type: API_TAGS.ACCOUNTS,
          id: 'ALL',
        },
      ],
    }),
    fetchHistoricalBalances: builder.query<HistoricalBalance[], FetchAccountsArgs>({
      query: (args) => ({
        variables: {
          ...args,
          convertTo: CURRENCY.GBP,
        },
        document: GetHistoricalBalancesDocument,
      }),
      transformResponse: (response: FetchHistoricalBalanceResponse) => response.historicalBalances?.data || [],
      keepUnusedDataFor: caching.day,
      providesTags: [
        {
          type: API_TAGS.HISTORICAL_BALANCES,
          id: 'ALL',
        },
      ],
    }),
    fetchCryptoAccounts: builder.query<AssetsInterface[], FetchAccountsArgs>({
      query: (args) => ({
        variables: {
          ...args,
          convertTo: CURRENCY.GBP,
        },
        document: GetCryptoAccountsDocument,
      }),
      transformResponse: (response: FetchCryptoAccountsResponse) => {
        const { totalCount, data } = response.cryptoAccounts;
        if (!totalCount) {
          return [];
        }
        return data.map((a) => {
          a.entity = {
            name: a.provider as string,
            iconBase64: null,
            iconUrl:
              a.provider === WealthProviderEnum.Otto
                ? null
                : `/static/assets/${(a.provider as string).toLowerCase()}.png`,
          };
          return a;
        });
      },
      keepUnusedDataFor: caching.day,
      providesTags: [
        {
          type: API_TAGS.CRYPTO_ACCOUNTS,
          id: 'ALL',
        },
      ],
    }),
    removeAccount: builder.mutation<string, { id: string }>({
      query: (variables) => ({ variables, document: RemoveAccountDocument }),
      invalidatesTags: accountsCacheTags,
    }),
    createManualAccount: builder.mutation<string, CreateManualAccountArgsInterface>({
      query: (args) => ({
        variables: { account: args },
        document: CreateManualAccountDocument,
      }),
      transformResponse: (res: { createDataIntegrationAccount: string }) => res?.createDataIntegrationAccount,
      invalidatesTags: accountsCacheTags,
    }),
    updateAccount: builder.mutation<UpdateAccountArgs, UpdateAccountArgs>({
      query: (variables) => ({ variables, document: UpdateAccountDocument }),
      transformResponse: (_, __, arg) => arg,
      // invalidatesTags: accountsCacheTags
    }),
  }),
});

export const {
  useFetchAccountsQuery,
  useCreateManualAccountMutation,
  useFetchCryptoAccountsQuery,
  useFetchHistoricalBalancesQuery,
  useLazyFetchHistoricalBalancesQuery,
  useLazyFetchAccountsQuery,
  useRemoveAccountMutation,
  useUpdateAccountMutation,
} = accountsApi;
