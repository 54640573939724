import { Channel } from 'pusher-js';
import { useEffect } from 'react';

export const useEvent = <T>(channel: Channel | undefined, eventName: string, callback: (data?: T) => void): void => {
  /**
   * Binds to specific Pusher events
   * See: https://pusher.com/docs/channels/using_channels/events/
   */
  useEffect(() => {
    if (!channel) return;
    channel.bind(eventName, callback);
    return () => {
      channel.unbind(eventName, callback);
    };
  }, [channel, eventName, callback]);
};
