import { gql } from 'graphql-request';

const documentModel = `
  createdAt
  folderId
  id
  labels
  name
  updatedAt
  userId
  size
`;

const folderModel = `
  id
  name
  path
  createdAt
  updatedAt
`;

export const GetDocumentDocument = gql`
  query GetDocument($id: String!) {
    document(id: $id) {
      ${documentModel}
      contentType
      shares
    }
  }
`;

export const GetDocumentDownloadDocument = gql`
  query GetDocumentDownload($id: String!) {
    document(id: $id) {
      ${documentModel}
      viewUrl
    }
  }
`;

export const GetDocumentsDocument = gql`
  query GetDocuments($folderId: String, $label: DocumentLabelEnum, $limit: Int, $name: String, $offset: Int, $order: DocumentsOrderArgType, $sharedWith: String, $userId: String!, $owner: String) {
    documents(folderId: $folderId, label: $label, limit: $limit, name: $name, offset: $offset, order: $order, sharedWith: $sharedWith, userId: $userId, owner: $owner) {
      data {
        ${documentModel}
        contentType
        shares
      }
      totalCount
    }
  }
`;

export const GetFolderDocument = gql`
  query GetFolder($id: String!) {
    folder(id: $id) {
      ${folderModel}
    }
  }
`;

export const GetFoldersDocument = gql`
  query GetFolders($userId: String!) {
    folders(userId: $userId) {
      data {
        ${folderModel}
      }
      totalCount
    }
  }
`;

export const UpdateFolderDocument = gql`
  mutation UpdateFolder($id: String!, $name: String!) {
    updateFolder(id: $id, name: $name) {
      ${folderModel}
    }
  }
`;

export const DeleteFolderDocument = gql`
  mutation DeleteFolder($id: String!) {
    deleteFolder(id: $id)
  }
`;

export const CreateFolderDocument = gql`
  mutation CreateFolder(
    $userId: String!
    $name: String!
    $parent: String
  ){
  createFolder(
    userId: $userId
    name: $name
    parent: $parent
  ) {
    ${folderModel}
  }
}
`;

export const CreateDocumentDocument = gql`
  mutation CreateDocument($document: CreateDocumentDto!) {
    createDocument(document: $document) {
      id
      #status
      userId
      name
      #path
      #folderId
      #size
      #customMetaData
      #contentType
      createdAt
      updatedAt
      #url
      #downloadUrl
      uploadUrl
    }
  }
`;

export const UpdateDocumentDocument = gql`
  mutation UpdateDocument($id: String!, $document: UpdateDocumentDto!) {
    updateDocument(id: $id, document: $document) {
      id
      #status
      userId
      name
      #path
      #folderId
      #size
      #customMetaData
      #contentType
      createdAt
      updatedAt
      #url
      #downloadUrl
      uploadUrl
    }
  }
`;

export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id)
  }
`;

export const ShareDocumentDocument = gql`
  mutation ShareDocument($id: String!, $shareWith: [String!], $stopSharingWith: [String!]){
    shareDocument(id: $id, shareWith: $shareWith, stopSharingWith: $stopSharingWith) {
      ${documentModel}
    }
  }
`;
