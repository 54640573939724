import { Input, Notification, SearchIcon, useTheme } from '@otto-finance/ui';
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  TableState,
  useReactTable,
  SortingState,
  Row,
  Header,
} from '@tanstack/react-table';
import { Paginator } from 'common/otto-ui/table';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import { Fragment, useCallback, useState } from 'react';
import { TableTh } from './table-th';
import { ColorTheme } from 'configuration/ui';

import styles from './styles.module.scss';
import clsx from 'clsx';
import { Skeleton } from 'components';
import { EmptyState } from 'components/empty-state';

interface TableInterface<T> {
  data: T[];
  columns: any[];
  pagination?: PaginationState;
  totalCount: number;
  enableExpanding?: boolean;
  enablePagination?: boolean;
  headRight?: JSX.Element;
  isLoading?: boolean;
  error?: boolean | string;
  state?: Partial<TableState>;
  enableRowSelection?: boolean;
  enableGlobalFilter?: boolean;
  manualSorting?: boolean;
  stickyPos?: number;
  searchPlaceholder?: string;
  expandedView?: (data: T) => JSX.Element;
  // expandedView?: ({ data }: { data: T; }) => JSX.Element;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  // onExpandedChange?: (e: ExpandedState) => void;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  // onRowSelectionChange?: (p: RowSelectionState) => void;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onSortingChange?: OnChangeFn<SortingState>;
}

// TODO: Implement all features & fix styles before replacing existing table components
export const Table = <T extends unknown>({
  data,
  columns,
  pagination,
  totalCount,
  error = false,
  state = {},
  enableRowSelection,
  headRight,
  enablePagination,
  enableGlobalFilter,
  manualSorting,
  searchPlaceholder = 'Search...',
  stickyPos,
  isLoading,
  expandedView,
  onExpandedChange,
  onRowSelectionChange,
  onSortingChange,
  onPaginationChange,
}: TableInterface<T>): JSX.Element => {
  const [css, theme] = useTheme();
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    enableHiding: true,
    pageCount: !enablePagination || !pagination ? 1 : Math.ceil(totalCount / pagination.pageSize),
    manualPagination: enablePagination,
    state: {
      ...state,
      globalFilter,
    },
    enableRowSelection,
    enableGlobalFilter,
    manualSorting,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: (s) => setGlobalFilter(s),
    onExpandedChange,
    onRowSelectionChange,
    onPaginationChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const onGlobalSearch = useCallback(
    debounce((e) => table.setGlobalFilter(e.target.value), 200),
    [],
  );

  if (error) {
    return (
      <div>
        <Notification kind="negative">Unable to load table</Notification>
      </div>
    );
  }
  return (
    <div
      className={css({
        display: 'grid',
        gap: theme.sizing.scale600,
        overflow: 'hidden',
      })}
    >
      {enableGlobalFilter ? (
        <div
          className={css({
            display: 'grid',
            justifyContent: 'space-between',
            padding: theme.sizing.scale600,
            gap: theme.sizing.scale400,
            [theme.mediaQuery.medium]: {
              display: 'flex',
            },
          })}
        >
          <div>
            <Input
              onChange={onGlobalSearch}
              endEnhancer={<SearchIcon size="sm" />}
              placeholder={searchPlaceholder}
              clearable={true}
              clearOnEscape={true}
              autoFocus={false}
            />
          </div>
          {headRight}
        </div>
      ) : null}
      <div
        className={css({
          overflowX: 'auto',
        })}
      >
        <table className={styles.root}>
          <TableTh table={table} pos={stickyPos} />
          <tbody>
            {isLoading && data.length === 0 && <TableSkeleton headers={table.getHeaderGroups()[0].headers} />}

            {table.getRowModel().rows.map((row) => (
              <Fragment key={`tbody_tr_${row.id}`}>
                <tr className={clsx(styles.tableRow, row.getIsExpanded() && styles.tableRowExpanded)}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={`tbody_td_${cell.id}`}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
                <AnimatePresence initial={false}>
                  {/* Expanded Rows */}
                  {row.getIsExpanded() && (
                    <motion.tr
                      key={`tbody_tr_expanded_${row.id}`}
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className={css({ backgroundColor: ColorTheme.background50 })}
                    >
                      <motion.td colSpan={table.getAllColumns().length}>{expandedView(row.original)}</motion.td>
                    </motion.tr>
                  )}
                </AnimatePresence>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {!isLoading && table.getRowModel().rows.length === 0 && <EmptyState>No records</EmptyState>}
      {/* Pagination */}
      {enablePagination && totalCount > pagination.pageSize ? <Paginator table={table} /> : null}
    </div>
  );
};

const TableSkeleton = ({ headers }: { headers: Header<any, unknown>[] }) => {
  const rows = [0.6, 0.5, 0.4, 0.3];
  return rows.map((r) => (
    <tr key={`tbody_tr_loading_${r}`} className={styles.tableRow}>
      {headers.map((h) => (
        <td key={`tbody_td__loading${h.id}`}>
          <Skeleton width="100%" height="40px" animation opacity={r} />
        </td>
      ))}
    </tr>
  ));
};
