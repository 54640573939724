import { gql } from 'graphql-request';

export const FetchTransactionsDocument = gql`
  query financeTransactions(
    $userId: String!
    $accountId: String
    $holdingId: String
    $accountIds: [String]
    $convertTo: String
    $limit: Int
    $offset: Int
    $to: String
    $from: String
    $categories: [String]
    $counterparties: [String]
    $direction: [TransactionDirectionEnum]
    $order: [FinanceTransactionsOrderArgType]
  ) {
    financeTransactions(
      userId: $userId
      accountId: $accountId
      holdingId: $holdingId
      accountIds: $accountIds
      convertTo: $convertTo
      limit: $limit
      offset: $offset
      from: $from
      to: $to
      categories: $categories
      counterparties: $counterparties
      direction: $direction
      order: $order
    ) {
      totalCount
      data {
        id
        accountId
        amount
        currency
        convertedAmount
        convertedCurrency
        direction
        categoryId
        holdingId
        category {
          name
          group
        }
        date
        fullDescription
        cleanDescription
        notes
        counterpartyId
        status
        projectId
        counterparty {
          id
          shortName
          fullName
          logo
        }
        matching {
          id
          cleanDescription
        }
      }
    }
  }
`;

export const financeSpendingAnalysisDocument = gql`
  query financeSpendingAnalysis(
    $limit: Int
    $offset: Int
    $convertTo: String!
    $ranges: [FinanceRequestedDateRangeArgType!]!
    $userId: String!
  ) {
    financeSpendingAnalysis(limit: $limit, offset: $offset, ranges: $ranges, convertTo: $convertTo, userId: $userId) {
      totals {
        name
        value
      }
      categories {
        category
        group
        values {
          name
          value
        }
      }
    }
  }
`;

export const UpdateTransactionDocument = gql`
  mutation updateTransaction($id: String!, $transaction: TransactionUpdateDto!) {
    updateTransaction(id: $id, transaction: $transaction)
  }
`;
