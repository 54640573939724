import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const UserIcon: React.FC<IconProps> = ({ height = 24, width = 24, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M3.91052 15.6711C3.78102 15.8953 3.85775 16.182 4.08191 16.3115C4.30608 16.441 4.59278 16.3643 4.72229 16.1401L3.91052 15.6711ZM15.2791 16.1402C15.4086 16.3644 15.6953 16.4411 15.9194 16.3116C16.1436 16.1821 16.2203 15.8954 16.0908 15.6713L15.2791 16.1402ZM18.5312 10C18.5312 14.7117 14.7117 18.5312 10 18.5312V19.4688C15.2294 19.4688 19.4688 15.2294 19.4688 10H18.5312ZM10 18.5312C5.28832 18.5312 1.46875 14.7117 1.46875 10H0.53125C0.53125 15.2294 4.77055 19.4688 10 19.4688V18.5312ZM1.46875 10C1.46875 5.28832 5.28832 1.46875 10 1.46875V0.53125C4.77055 0.53125 0.53125 4.77055 0.53125 10H1.46875ZM10 1.46875C14.7117 1.46875 18.5312 5.28832 18.5312 10H19.4688C19.4688 4.77055 15.2294 0.53125 10 0.53125V1.46875ZM13.2813 8.87518C13.2813 10.6874 11.8122 12.1564 10 12.1564V13.0939C12.33 13.0939 14.2188 11.2051 14.2188 8.87518H13.2813ZM10 12.1564C8.18784 12.1564 6.71877 10.6874 6.71877 8.87518H5.78127C5.78127 11.2051 7.67007 13.0939 10 13.0939V12.1564ZM6.71877 8.87518C6.71877 7.063 8.18784 5.59393 10 5.59393V4.65643C7.67007 4.65643 5.78127 6.54523 5.78127 8.87518H6.71877ZM10 5.59393C11.8122 5.59393 13.2813 7.063 13.2813 8.87518H14.2188C14.2188 6.54523 12.33 4.65643 10 4.65643V5.59393ZM4.72229 16.1401C5.25744 15.2138 6.02694 14.4447 6.95346 13.9099L6.48484 13.0979C5.41581 13.7149 4.52798 14.6024 3.91052 15.6711L4.72229 16.1401ZM6.95346 13.9099C7.87998 13.3752 8.93022 13.0939 10 13.0939L10 12.1564C8.76576 12.1564 7.55388 12.4809 6.48484 13.0979L6.95346 13.9099ZM10 13.0939C11.0699 13.0939 12.1215 13.3753 13.0479 13.91L13.5166 13.098C12.4475 12.4809 11.2342 12.1564 10 12.1564L10 13.0939ZM13.0479 13.91C13.9745 14.4447 14.7439 15.2139 15.2791 16.1402L16.0908 15.6713C15.4734 14.6025 14.5856 13.715 13.5166 13.098L13.0479 13.91Z"
    />
  </svg>
);
