import { StoreInterface } from 'configuration/redux/store';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AuthStateInterface } from 'slices/auth-management';

export interface AuthStateErrorInterface {
  message: string;
}

export interface UseUserHookInterface {
  isLoading: boolean;
  error: AuthStateErrorInterface;
  accessToken: string;
  email: string;
  id: string;
  type: string;
}

export interface UseUserHookValuesInterface {
  redirectTo?: string;
  redirectIfFound?: boolean;
}

export const useAuth = ({ redirectTo, redirectIfFound }: UseUserHookValuesInterface): UseUserHookInterface => {
  const router = useRouter();

  const { isLoading, error, accessToken, userData, isLogged }: AuthStateInterface = useSelector<
    StoreInterface,
    AuthStateInterface
  >((state) => state.auth);

  useEffect(() => {
    if (!redirectTo || isLoading) {
      return;
    }

    if ((redirectTo && !redirectIfFound && !accessToken) || (redirectIfFound && accessToken)) {
      void router.push({
        pathname: redirectTo,
        query: isLogged
          ? {
              link: router.asPath,
            }
          : {},
      });
    }
  }, [accessToken, redirectTo, redirectIfFound, isLoading]);

  return {
    error,
    isLoading,
    accessToken,
    email: userData?.email,
    id: userData?.id,
    type: userData?.type,
  };
};
