import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const MessagesIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <g transform="scale(2,2)">
        <path d="M44,1c-23.7,0 -43,19.3 -43,43c0,23.7 19.3,43 43,43h40c1.2,0 2.29922,-0.70078 2.69922,-1.80078c0.5,-1.2 0.20039,-2.39883 -0.59961,-3.29883l-9.5,-9.90039c6.7,-7.8 10.40039,-17.6 10.40039,-28c0,-23.7 -19.3,-43 -43,-43zM44,7c20.4,0 37,16.6 37,37c0,9.8 -3.79922,19 -10.69922,26c-1.1,1.2 -1.1,2.99922 0,4.19922l6.59961,6.80078h-32.90039c-20.4,0 -37,-16.6 -37,-37c0,-20.4 16.6,-37 37,-37zM29,34c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h30c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM95.60156,42.77734c-1.17656,0.11719 -2.22656,0.92305 -2.60156,2.12305c-0.5,1.6 0.4,3.29883 2,3.79883c15.6,4.8 26,19.00078 26,35.30078c0,20.4 -16.6,37 -37,37h-32.90039l6.59961,-6.80078c1.2,-1.2 1.1,-2.99922 0,-4.19922c-4.2,-4.2 -7.3,-9.30039 -9,-14.90039c-0.5,-1.6 -2.19883,-2.5 -3.79883,-2c-1.6,0.5 -2.5,2.20078 -2,3.80078c1.7,5.5 4.60039,10.69961 8.40039,15.09961l-9.5,9.90039c-0.8,0.9 -1.10156,2.19883 -0.60156,3.29883c0.5,1.1 1.60078,1.80078 2.80078,1.80078h40c23.7,0 43,-19.3 43,-43c0,-18.9 -12.09922,-35.39961 -30.19922,-41.09961c-0.4,-0.125 -0.80703,-0.16211 -1.19922,-0.12305zM29,49c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h10c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM55,49c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h4c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3z" />
      </g>
    </g>
  </svg>
);
