export const getHslColor = (hue: number, saturation: number, lightness: number, opacity: number): string =>
  `hsl(${hue}deg ${saturation}% ${lightness}% / ${opacity}%)`;

export const generateRandomColors = (limit: number, saturation = 50, lightness = 50, opacity = 100): string[] => {
  const colors = [];
  const range = [0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 320, 340];
  for (let i = 0; i < limit; i++) {
    colors.push(getHslColor(range[Math.floor(Math.random() * range.length)], saturation, lightness, opacity));
  }
  return colors;
};

export const posNegColor = (v: number): string => (v > 0 ? 'green' : v < 0 ? 'red' : 'black');

// hsl(334.4deg 100% 55.88%) > [334.4, 100, 55.88]
export const hslToObj = (hsl: string) =>
  hsl
    .slice(4, -1)
    .split(' ')
    .map((v) => parseFloat(v));
// [334.4, 100, 55.88] > hsl(334.4deg 100% 55.88%)
export const objToHsl = (arr: number[]) => `hsl(${arr[0]}deg ${arr[1]}% ${arr[2]}%)`;

export const lightenColor = (hslObj: number[], value: number): string => {
  hslObj[2] = value;
  return objToHsl(hslObj);
};

export const generateColorPalette = (name: string, color: number[], steps = 5) => {
  let p = {};
  const len = Array(steps).fill(0).length;
  for (let i = 0; i < len; i++) {
    p[`${name}${i === 0 ? 50 : i * 100}`] = lightenColor(color, i === 0 ? 95 : 100 - i * 10);
  }
  return p;
};

export const hslToHex = (hsl: number[]) => {
  const l = hsl[2] / 100;
  const a = (hsl[1] * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + hsl[0] / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};
