import { ReactNode } from 'react';

import styles from './empty-state.module.scss';

type EmptyStateProps = {
  icon?: ReactNode;
  title?: string;
  description?: ReactNode;
  children?: ReactNode;
};

export const EmptyState = ({ icon, title, description, children }: EmptyStateProps) => {
  return (
    <div className={styles.root}>
      {icon && icon}
      <div>
        {title && <h4>{title}</h4>}
        {description && <p>{description}</p>}
      </div>
      {children && <div className={styles.actions}>{children}</div>}
    </div>
  );
};
