import { IconProps } from 'common/otto-ui/icons/icon.type';

export const TransactionIcon = ({ color = 'currentColor', size = 24 }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 255 255" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(2,2)">
      <path
        fill={color}
        d="M64,6c-15.5,0 -30.1,6 -41,17c-17,16.9 -21.79922,42.09961 -12.19922,64.09961c0.5,1.1 1.59883,1.80078 2.79883,1.80078c0.4,0 0.80117,-0.10117 1.20117,-0.20117c1.5,-0.7 2.19961,-2.39844 1.59961,-3.89844c-8.7,-19.8 -4.40117,-42.40156 10.79883,-57.60156c9.9,-9.8 22.90078,-15.19922 36.80078,-15.19922c11.7,0 22.80039,3.80039 31.90039,10.90039l-5.30078,0.59961c-1.6,0.2 -2.79961,1.70078 -2.59961,3.30078c0.2,1.5 1.5,2.59961 3,2.59961h0.40039l12,-1.40039c1.6,-0.2 2.69961,-1.59922 2.59961,-3.19922l-0.69922,-11.30078c-0.1,-1.7 -1.50117,-2.90078 -3.20117,-2.80078c-1.7,0.1 -2.89883,1.50117 -2.79883,3.20117l0.19922,4.09961c-10.1,-7.7 -22.5,-12 -35.5,-12zM114.25977,38.82813c-0.39063,0.00937 -0.78516,0.09648 -1.16016,0.27148c-1.5,0.7 -2.2,2.4 -1.5,4c8.7,19.8 4.50117,42.50078 -10.79883,57.80078c-9.9,9.7 -22.90078,15.09961 -36.80078,15.09961c-11.7,0 -22.80039,-3.80039 -31.90039,-10.90039l5.30078,-0.59961c1.6,-0.2 2.79961,-1.70078 2.59961,-3.30078c-0.2,-1.6 -1.70078,-2.79961 -3.30078,-2.59961l-12,1.40039c-1.6,0.2 -2.69961,1.59922 -2.59961,3.19922l0.70117,11.30078c0.1,1.6 1.4,2.80078 3,2.80078h0.19922c1.7,-0.1 2.90078,-1.50117 2.80078,-3.20117l-0.20117,-4.09961c10,7.7 22.40039,12 35.40039,12c15.5,0 30.1,-6 41,-17c17.1,-17.1 21.79961,-42.30039 12.09961,-64.40039c-0.525,-1.125 -1.66797,-1.79961 -2.83984,-1.77148zM61.19922,41c-1.7,0 -3,1.3 -3,3v23.5h-5c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h5v2.5c0,2.8 -2.2,5 -5,5c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h21c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3h-11.19922c0.8,-1.5 1.19922,-3.2 1.19922,-5v-2.5h5c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3h-5v-20.5h4.5c1.4,0 2.5,1.1 2.5,2.5c0,1.7 1.3,3 3,3c1.7,0 3,-1.3 3,-3c0,-4.7 -3.8,-8.5 -8.5,-8.5z"
      />
    </g>
  </svg>
);
