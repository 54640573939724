import {
  ContactExpertArgsInterface,
  ContactVariablesInterface,
  SettingsInterface,
  UpdateSettingsArgsInterface,
} from 'common/interfaces/settings.interface';
import { caching } from 'configuration/data';
import {
  ContactExpertDocument,
  ContactSupportDocument,
  GetSettingsDocument,
  ShareFinancialInformationDocument,
  SiteSettingsDocument,
  UpdateSettingsDocument,
} from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { SiteSettings } from 'slices/ui/ui.slice';

export const settingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    contact: builder.mutation<string, ContactVariablesInterface>({
      query: (data) => ({
        variables: { data },
        document: ContactSupportDocument,
      }),
    }),
    contactExpert: builder.mutation<string, ContactExpertArgsInterface>({
      query: (variables) => ({
        variables,
        document: ContactExpertDocument,
      }),
    }),
    settings: builder.query<SettingsInterface, undefined>({
      query: (variables) => ({
        document: GetSettingsDocument,
        variables,
      }),
      transformResponse: (response: { settings }) => response.settings,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.SETTINGS],
    }),
    updateSettings: builder.mutation<SettingsInterface, UpdateSettingsArgsInterface>({
      query: (data) => ({
        variables: { input: data },
        document: UpdateSettingsDocument,
      }),
      invalidatesTags: [API_TAGS.SETTINGS],
    }),
    siteSettings: builder.query<SiteSettings, undefined>({
      query: () => ({
        document: SiteSettingsDocument,
        variables: { url: location.hostname },
      }),
      transformResponse: (response: { siteSettings }) => response.siteSettings,
      keepUnusedDataFor: caching.day,
    }),
    shareFinancialInformation: builder.mutation<boolean, { share: boolean; userId: string }>({
      query: (variables) => ({
        variables,
        document: ShareFinancialInformationDocument,
      }),
    }),
  }),
});

export const {
  useContactMutation,
  useSettingsQuery,
  useUpdateSettingsMutation,
  useLazySettingsQuery,
  useContactExpertMutation,
  useLazySiteSettingsQuery,
  useShareFinancialInformationMutation,
} = settingsApi;
