import { gql } from 'graphql-request';

export const CreateFormSubmissionDocument = gql`
  mutation CreateFormSubmission($input: CreateFormSubmissionDto!) {
    createFormSubmission(input: $input) {
      id
      formConfigId
      customerId
      coachId
      payload
    }
  }
`;

export const FormSubmissionsDocument = gql`
  query FormSubmissions($limit: Float, $offset: Float) {
    formSubmissions(limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        formConfigId
        customerId
        coachId
        submissionDate
        createdAt
        updatedAt
        payload
      }
    }
  }
`;

export const FormSubmissionDocument = gql`
  query FormSubmission($id: String!) {
    formSubmission(id: $id) {
      id
      formConfigId
      customerId
      coachId
      submissionDate
      createdAt
      updatedAt
      payload
    }
  }
`;

export const UpdateFormSubmissionDocument = gql`
  mutation UpdateFormSubmission($id: String!, $payload: JsonObject!, $submit: Boolean) {
    updateFormSubmission(id: $id, payload: $payload, submit: $submit) {
      id
      formConfigId
      customerId
      coachId
      submissionDate
      createdAt
      updatedAt
      payload
    }
  }
`;
