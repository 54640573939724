import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface FetchSurveyDetailsVariablesInterface {
  userId: string;
}

export interface FetchSurveyDetailsActionRequestInterface extends QueryOptions {
  variables: FetchSurveyDetailsVariablesInterface;
}

export const fetchSurveyDetailsAction = createAsyncThunk(
  'survey/results',
  async (request: FetchSurveyDetailsActionRequestInterface, thunkApi): Promise<any> =>
    await requestGql<any>(request, thunkApi, 'results'),
);
