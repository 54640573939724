import { IconProps } from 'common/otto-ui/icons/icon.type';

export const BiritshPoundCircleIcon = ({ size = 24, color = 'currentColor' }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1C5.91633 1 1 5.91633 1 12C1 18.0837 5.91633 23 12 23C18.0837 23 23 18.0837 23 12C23 5.91633 18.0837 1 12 1ZM12 21.1727C6.94449 21.1727 2.82735 17.0555 2.82735 12C2.82735 6.94449 6.94449 2.82735 12 2.82735C17.0555 2.82735 21.1727 6.94449 21.1727 12C21.1727 17.0555 17.0555 21.1727 12 21.1727Z"
      fill={color}
    />
    <path
      d="M15.9288 15.4162C15.6908 15.0526 15.197 14.9358 14.8423 15.1827C13.8276 15.8966 12.4672 15.2097 12.3415 15.1737C11.8745 15.0526 11.2729 15.0749 10.6892 15.1558C10.7745 14.9627 10.8463 14.7652 10.9137 14.5542C11.0933 13.9884 11.1876 13.4137 11.1966 12.8705H12.8668C13.3023 12.8705 13.6525 12.5203 13.6525 12.0848C13.6525 11.6492 13.3023 11.2991 12.8668 11.2991H10.9541C10.9541 11.2946 10.9496 11.2856 10.9496 11.2811C10.577 10.2754 10.797 8.53335 11.8925 8.38069C13.3561 8.18314 13.6255 9.70069 13.63 9.75009C13.666 10.1497 14.0027 10.4595 14.4113 10.4595C15.17 10.4595 15.2015 9.73662 15.188 9.57498C15.179 9.50314 14.8018 6.64764 11.888 6.80927C9.1986 6.96192 9.0684 10.446 9.30187 11.2991H8.74064C8.30514 11.2991 7.95493 11.6492 7.95493 12.0848C7.95493 12.5203 8.30514 12.8705 8.74064 12.8705H9.62514C9.59371 14.0379 9.00554 15.3264 8.3186 15.7664C7.98636 15.9819 7.82922 16.4264 8.03126 16.7676C8.33656 17.2749 8.90677 17.2031 8.98759 17.1717C9.8586 16.8799 11.3492 16.5387 11.9418 16.6958C12.0586 16.7272 13.9758 17.7015 15.6953 16.4982C16.0545 16.2513 16.1668 15.7799 15.9288 15.4162Z"
      fill={color}
    />
  </svg>
);
