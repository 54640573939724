import { IconProps } from 'common/otto-ui/icons/icon.type';

export const AssetsIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
    >
      <g transform="scale(2,2)">
        <path d="M122.02,28.66l-54,-27c-2.52,-1.26 -5.53,-1.26 -8.05,0l-54,27c-3.06,1.53 -4.97,4.62 -4.97,8.05v5.29c0,4.96 4.04,9 9,9h108c4.96,0 9,-4.04 9,-9v-5.29c0,-3.43 -1.91,-6.52 -4.98,-8.05zM121,42c0,1.65 -1.35,3 -3,3h-108c-1.65,0 -3,-1.35 -3,-3v-5.29c0,-1.14 0.64,-2.17 1.66,-2.68l54,-27c0.42,-0.21 0.88,-0.32 1.34,-0.32c0.46,0 0.92,0.11 1.34,0.32l54,27c1.02,0.51 1.66,1.54 1.66,2.68zM34,102v-32c0,-6.07 -4.93,-11 -11,-11c-6.07,0 -11,4.93 -11,11v32c0,6.07 4.93,11 11,11c6.07,0 11,-4.93 11,-11zM18,102v-32c0,-2.76 2.24,-5 5,-5c2.76,0 5,2.24 5,5v32c0,2.76 -2.24,5 -5,5c-2.76,0 -5,-2.24 -5,-5zM75,102v-32c0,-6.07 -4.93,-11 -11,-11c-6.07,0 -11,4.93 -11,11v32c0,6.07 4.93,11 11,11c6.07,0 11,-4.93 11,-11zM59,102v-32c0,-2.76 2.24,-5 5,-5c2.76,0 5,2.24 5,5v32c0,2.76 -2.24,5 -5,5c-2.76,0 -5,-2.24 -5,-5zM116,102v-32c0,-6.07 -4.93,-11 -11,-11c-6.07,0 -11,4.93 -11,11v32c0,6.07 4.93,11 11,11c6.07,0 11,-4.93 11,-11zM100,102v-32c0,-2.76 2.24,-5 5,-5c2.76,0 5,2.24 5,5v32c0,2.76 -2.24,5 -5,5c-2.76,0 -5,-2.24 -5,-5zM124,121h-120c-1.66,0 -3,1.34 -3,3c0,1.66 1.34,3 3,3h120c1.66,0 3,-1.34 3,-3c0,-1.66 -1.34,-3 -3,-3z" />
      </g>
    </g>
  </svg>
);
