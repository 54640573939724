import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const IncomeProtectionIcon: React.FC<IconProps> = ({ color = 'black', width = 57, height = 66 }) => (
  <div>
    <svg width={width} height={height} viewBox="0 0 57 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.4652 9.55681L28.7977 0.0693724C28.554 -0.0231241 28.2849 -0.0231241 28.0412 0.0693724L3.37359 9.55681C2.38329 9.94261 1.53196 10.6174 0.930304 11.4935C0.328649 12.3696 0.00449833 13.4065 0 14.4693V32.6291C0 56.2893 27.8284 65.1262 28.11 65.2126C28.3117 65.2743 28.5272 65.2743 28.7288 65.2126C29.0104 65.1262 56.8388 56.2893 56.8388 32.6291V14.4693C56.8343 13.4065 56.5101 12.3696 55.9085 11.4935C55.3068 10.6174 54.4555 9.94261 53.4652 9.55681ZM54.7336 32.6291C54.7336 53.4184 31.4013 62.0774 28.4194 63.0971C25.4375 62.0774 2.10514 53.4184 2.10514 32.6291V14.4693C2.10796 13.8315 2.30259 13.2094 2.66373 12.6838C3.02486 12.1582 3.53579 11.7534 4.13007 11.5221L28.4194 2.17967L52.7087 11.5223C53.3029 11.7536 53.8138 12.1584 54.175 12.6839C54.5361 13.2095 54.7308 13.8316 54.7336 14.4693V32.6291Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9459 43.6165C22.5976 43.6165 17.4335 38.4507 17.4335 32.104C17.4335 25.7557 22.5976 20.5915 28.9459 20.5915C35.2926 20.5915 40.4584 25.7557 40.4584 32.104C40.4584 38.4507 35.2926 43.6165 28.9459 43.6165ZM28.9459 18.9469C36.2004 18.9469 42.1031 24.8495 42.1031 32.104C42.1031 39.3585 36.2004 45.2611 28.9459 45.2611C21.6914 45.2611 15.7888 39.3585 15.7888 32.104C15.7888 24.8495 21.6914 18.9469 28.9459 18.9469ZM35.5245 27.1701C35.2975 27.1701 35.092 27.2622 34.9423 27.4119L27.2503 35.1039L22.8937 31.4725L22.8871 31.4807C22.744 31.3623 22.568 31.2817 22.3674 31.2817C21.9135 31.2817 21.5451 31.6501 21.5451 32.104C21.5451 32.3573 21.6668 32.576 21.8477 32.7273L21.8411 32.7356L26.775 36.8472L26.7816 36.8389C26.9247 36.9574 27.1007 37.0379 27.3013 37.0379C27.5283 37.0379 27.7338 36.9458 27.8835 36.7962L36.1051 28.5746C36.2547 28.425 36.3468 28.2194 36.3468 27.9924C36.3468 27.5385 35.9784 27.1701 35.5245 27.1701Z"
        fill={color}
        stroke={color}
        strokeWidth="0.421028"
      />
    </svg>
  </div>
);
