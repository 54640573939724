import { caching } from 'configuration/data';
import {
  CreateTargetDocument,
  DeleteTargetDocument,
  GetTargetDocument,
  GetTargetsDocument,
  UpdateTargetDocument,
} from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';

export enum TargetRepeatEnum {
  ONE_OFF = 'one_off',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum TargetTypeEnum {
  SPENDING = 'spending',
  SAVING = 'saving',
}

export interface TargetInterface {
  id: string;
  title: string;
  amount: number;
  currentAmount: number;
  progression: number;
  description?: string;
  repeat: TargetRepeatEnum;
  type: TargetTypeEnum;
  notifications: boolean;
  categories?: string[];
  expires?: string;
  resetsOn?: number;
  createdAt?: string;
}

interface SaveArgs {
  title?: string;
  amount: number;
  description?: string;
  repeat: TargetRepeatEnum;
  type: TargetTypeEnum;
  notifications: boolean;
  categories?: string[];
  expires?: string;
  resetsOn?: number;
}

export const targetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTargets: builder.query<TargetInterface[], null>({
      query: () => ({
        document: GetTargetsDocument,
      }),
      transformResponse: (data: { getTargets }) => data.getTargets,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.TARGETS],
    }),
    getTarget: builder.mutation<TargetInterface, { targetId: string }>({
      query: ({ targetId }) => ({
        variables: { targetId },
        document: GetTargetDocument,
      }),
    }),
    createTarget: builder.mutation<boolean, { data: SaveArgs }>({
      query: ({ data }) => ({
        variables: { data },
        document: CreateTargetDocument,
      }),
      invalidatesTags: [API_TAGS.TARGETS],
    }),
    updateTarget: builder.mutation<boolean, { targetId: string; data: SaveArgs }>({
      query: ({ targetId, data }) => ({
        variables: { targetId, data },
        document: UpdateTargetDocument,
      }),
    }),
    deleteTarget: builder.mutation<boolean, { targetId: string }>({
      query: ({ targetId }) => ({
        variables: { targetId },
        document: DeleteTargetDocument,
      }),
      invalidatesTags: [API_TAGS.TARGETS],
    }),
  }),
});

export const {
  useGetTargetsQuery,
  useGetTargetMutation,
  useUpdateTargetMutation,
  useDeleteTargetMutation,
  useCreateTargetMutation,
  useLazyGetTargetsQuery,
} = targetsApi;
