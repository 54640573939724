import clsx from 'clsx';
import { Children, cloneElement, CSSProperties, HTMLAttributes, isValidElement, ReactText } from 'react';

export enum SkeletonAnimationEnum {
  PULSE = 'pulse',
  WAVE = 'wave',
  NONE = 'none',
}

export enum SkeletonVariantEnum {
  RECT = 'rect',
  CIRCLE = 'circle',
  TEXT = 'text',
}

export interface SkeletonInterface extends HTMLAttributes<HTMLElement> {
  /** The type of content that will be rendered. */
  variant?: Lowercase<keyof typeof SkeletonVariantEnum>;
  /** The animation. If false the animation effect is disabled. */
  animation?: Lowercase<keyof typeof SkeletonAnimationEnum>;
  /** Width of the skeleton. Useful when the skeleton is inside an inline element with no width of its own. */
  width?: ReactText;
  /** Height of the skeleton. Useful when you don't want to adapt the skeleton to a text element but for instance a card. */
  height?: ReactText;
  /** Delay in milliseconds of animation */
  delay?: number;
}

/** Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration. */
export const Skeleton = ({
  variant = SkeletonVariantEnum.TEXT,
  animation = SkeletonAnimationEnum.PULSE,
  width,
  height,
  delay,
  style,
  className,
  children,
  ...props
}: SkeletonInterface) => {
  const classes = {
    root: clsx(
      'block bg-gray-100 dark:bg-background-dark-background3',
      {
        'animate-pulse': animation === SkeletonAnimationEnum.PULSE,
        'relative overflow-hidden transform translate-x-0': animation === SkeletonAnimationEnum.WAVE,
      },
      {
        'rounded-full': variant === SkeletonVariantEnum.CIRCLE,
        'my-0 h-auto leading-none rounded-md': variant === SkeletonVariantEnum.TEXT,
      },
      className,
    ),
    inner:
      'absolute left-0 top-0 w-full h-full animate-wave bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-100 dark:to-gray-200',
  };

  const styleCSS: CSSProperties = { ...style };
  if (width) {
    styleCSS.width = width;
  } else if (children) {
    styleCSS.maxWidth = 'fit-content';
  }
  if (height) {
    styleCSS.height = height;
  } else if (children) {
    styleCSS.height = 'auto';
  }
  if (delay && animation !== SkeletonAnimationEnum.NONE) {
    styleCSS.animationDelay = `${delay}ms`;
  }

  return (
    <span className={classes.root} style={styleCSS} {...props}>
      {animation === SkeletonAnimationEnum.WAVE && <span className={classes.inner} />}
      {variant === SkeletonVariantEnum.TEXT && '\u00a0'}
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...child.props,
            key: `skeleton-child-${index}`,
            className: clsx(child.props.className, 'invisible'),
          });
        } else {
          return null;
        }
      })}
    </span>
  );
};
