import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SessionRoomResponseInterface } from 'slices/session';
import { requestGql } from 'utils/request-gql';

export interface FetchSessionRoomVariablesInterface {
  id: string;
}

export interface FetchSessionRoomActionRequestInterface extends QueryOptions {
  variables: FetchSessionRoomVariablesInterface;
}

export const fetchSessionRoomAction = createAsyncThunk(
  'session/appointmentRoom',
  async (request: FetchSessionRoomActionRequestInterface, thunkApi) =>
    requestGql<SessionRoomResponseInterface>(request, thunkApi, 'appointmentRoom'),
);
