import { ParagraphSmall, useTheme } from '@otto-finance/ui';
import { ReactNode } from 'react';

export const HoldingFormItem: React.FC<{ label: string; children?: ReactNode }> = ({ label, children }) => {
  const [css, theme] = useTheme();
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `solid 1px ${theme.colors.grayScale100}`,
        paddingTop: theme.sizing.scale400,
        paddingBottom: theme.sizing.scale400,
        ':last-child': {
          borderBottom: 'none',
        },
      })}
    >
      <div>{label}</div>
      <div
        className={css({
          textAlign: 'right',
          width: '60%',
        })}
      >
        {children}
      </div>
    </div>
  );
};
