import { Button, HintDot, useTheme } from '@otto-finance/ui';
import { useNotificationsUnreadCountQuery } from 'api/notifications.api';
import { BellIcon } from 'common/otto-ui/icons';
import { Popover, PopoverTrigger, PopoverContent } from 'common/otto-ui/popover';
import dynamic from 'next/dynamic';

const NotificationsList = dynamic(
  () => import('components/notifications/notifications-list').then((c) => c.NotificationsList),
  { ssr: false },
);

export const Notifications = () => {
  const [css, theme] = useTheme();
  const { data } = useNotificationsUnreadCountQuery(undefined, { pollingInterval: 1000 * 60 * 2 });
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className={css({ opacity: data === 0 ? 0.6 : 1 })}>
          <HintDot
            hidden={data === 0}
            horizontalOffset="0px"
            verticalOffset="4px"
            overrides={{
              Badge: {
                style: () => ({
                  border: 'none',
                }),
              },
            }}
          >
            <Button kind="tertiary" size="compact" shape="circle">
              <BellIcon size={20} />
            </Button>
          </HintDot>
        </div>
      </PopoverTrigger>
      <PopoverContent autoFocus={false}>
        <div
          className={css({
            display: 'grid',
            gap: theme.sizing.scale600,
            width: '380px',
          })}
        >
          <NotificationsList />
        </div>
      </PopoverContent>
    </Popover>
  );
};
