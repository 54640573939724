import { FeatureFlagEnum } from 'configuration/data';
import { useLoginHook } from '.';

type FeatureType = (feature: FeatureFlagEnum) => boolean;
interface UseFeatureInterface {
  hasAccess: FeatureType;
  hasFullAccess: FeatureType;
  hasLimitedAccess: FeatureType;
}

export const useFeature = (): UseFeatureInterface => {
  const { isCustomer, userData } = useLoginHook();

  const features = userData.tier?.features || {
    full: [],
    limited: [],
  };

  /**
   * The user has any access type (FULL or LIMITED)
   */
  const hasAccess = (feature: FeatureFlagEnum) => {
    if (!isCustomer) {
      return true;
    } else {
      const access = [...features.full, ...features.limited];
      return access.includes(feature);
    }
  };

  /**
   * The user only has FULL access type
   */
  const hasFullAccess = (feature: FeatureFlagEnum): boolean => features.full.includes(feature);

  /**
   * The user only has LIMITED access type
   */
  const hasLimitedAccess = (feature: FeatureFlagEnum): boolean => features.limited.includes(feature);

  return {
    hasAccess,
    hasFullAccess,
    hasLimitedAccess,
  };
};
