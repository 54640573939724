import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import { ClickAwayListener } from 'common/otto-ui/click-away-listener';
import { Portal } from 'common/otto-ui/portal/portal';
import { ReactNode, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

export interface MenuInterface {
  /** A HTML element, or a function that returns it. It's used to set the position of the menu. */
  anchor: Element;
  /** If true, the menu is visible. */
  open: boolean;
  /** Distance between anchor and menu vertically. */
  offsetY?: number;
  /** Distance between anchor and menu horizontally. */
  offsetX?: number;
  /** Menu contents, normally MenuItems. */
  children?: ReactNode;
  /** Placement option */
  placement?: Placement;
  /** Classes */
  className?: string;
  /** Content classes */
  innerClassName?: string;
  /**
   * Callback fired when the component requests to be closed.\n
   * function() => void
   */
  onClose?: () => void;
}

/** Menus display a list of choices on temporary surfaces. */
export const Menu = ({
  anchor,
  open,
  offsetX,
  offsetY,
  placement,
  className,
  innerClassName,
  onClose,
  children,
}: MenuInterface) => {
  const [menuElement, setMenuElement] = useState<HTMLElement>(null);

  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [offsetX ?? 0, offsetY ?? 0],
        },
      },
    ],
    [offsetX, offsetY],
  );

  const { styles, attributes } = usePopper(anchor, menuElement, {
    placement: placement ?? 'bottom-start',
    modifiers,
  });

  const handleClickAway = () => {
    if (onClose) {
      onClose();
    }
  };

  const classes = {
    wrapper: clsx(
      'z-[200] p-3 bg-white dark:bg-background-dark-background2 border border-solid border-white dark:border-background-dark-background3 shadow-shadow1 dark:shadow-none rounded-lg overflow-hidden',
      className,
    ),
    content: clsx('min-w-full', innerClassName),
  };

  return open && Boolean(anchor) ? (
    <Portal>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div ref={setMenuElement} className={classes.wrapper} style={styles.popper} {...attributes.popper}>
          <div className={classes.content}>{children}</div>
        </div>
      </ClickAwayListener>
    </Portal>
  ) : null;
};
