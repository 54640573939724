import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COUNTRY_AUTH_FLOW, financeAuthApi, OpenBankingCountry } from 'api/finance-auth.api';
import { ConnectionInterface, DataIntegrationAuthUrl } from 'common/interfaces';
import { COUNTRIES } from 'configuration/data';
import { saveState } from 'configuration/redux/local-store';
import { RootState } from 'configuration/redux/reducer';
import { WealthProviderEnum } from 'enums';

export interface FinanceAuthStateInterface {
  categoryId: string;
  category: string[];
  country?: OpenBankingCountry;
  connection: Partial<ConnectionInterface>;
  authUrlResponse: Partial<DataIntegrationAuthUrl>;
  /**
   * This is to differentiate between normal auth url and refresh one.
   */
  isRenew?: boolean;
}

const initialState: FinanceAuthStateInterface = {
  categoryId: null,
  category: [],
  country: {
    providers: [WealthProviderEnum.MoneyHub as string],
    authFlow: COUNTRY_AUTH_FLOW.BANK_CONNECTIONS,
    flag: '',
    name: COUNTRIES.GB,
    code: 'GB',
  },
  connection: null,
  authUrlResponse: null,
  isRenew: false,
};

export const financeAuthSlice = createSlice({
  name: 'financeAuth',
  initialState,
  reducers: {
    clear: (state) => {
      state = { ...initialState, country: state.country };
      saveState({ financeAuth: state });
      return state;
    },
    update: (state, { payload }: PayloadAction<Partial<FinanceAuthStateInterface>>) => {
      state = { ...state, ...payload };
      saveState({ financeAuth: state });
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      financeAuthApi.endpoints.openBankingCountries.matchFulfilled,
      (state, action: PayloadAction<OpenBankingCountry[]>) => {
        state.country = action.payload.find((f) => f.code === 'GB') || null;
      },
    );
  },
});

export default financeAuthSlice.reducer;
export const selectFinanceAuth = (state: RootState): FinanceAuthStateInterface => state.financeAuth;
export const financeAuthActions = financeAuthSlice.actions;
