import Head from 'next/head';
import { ReactNode } from 'react';

export interface AuthLayoutInterface {
  title?: string;
  children?: ReactNode;
}

export const AuthLayout = ({ title, children }: AuthLayoutInterface) => (
  <div>
    <Head>
      <title>{`Otto - ${title}`}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4bbc7d" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
    <div>{children}</div>
  </div>
);
