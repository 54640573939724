import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const MoneyBagIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  height = 25,
  width = 17,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.60964 16.5126H7.36428L7.36682 16.6946C7.38999 17.596 6.43054 18.5078 6.42122 18.5167C6.37684 18.56 6.34612 18.6153 6.33286 18.6759C6.3196 18.7365 6.32439 18.7996 6.34662 18.8575C6.36839 18.915 6.40669 18.9647 6.45669 19.0004C6.50669 19.0361 6.56614 19.0562 6.62755 19.0582H10.6021C10.6417 19.0582 10.681 19.0503 10.7177 19.0352C10.7543 19.02 10.7876 18.9977 10.8156 18.9697C10.8437 18.9416 10.8659 18.9083 10.8811 18.8717C10.8963 18.8351 10.9041 18.7958 10.9041 18.7561C10.9041 18.7165 10.8963 18.6772 10.8811 18.6405C10.8659 18.6039 10.8437 18.5706 10.8156 18.5426C10.7876 18.5145 10.7543 18.4923 10.7177 18.4771C10.681 18.4619 10.6417 18.4541 10.6021 18.4541H7.29326C7.61656 18.0226 7.98826 17.3738 7.97066 16.6926L7.96833 16.5126H9.76064C9.84074 16.5126 9.91756 16.4808 9.9742 16.4242C10.0308 16.3675 10.0627 16.2907 10.0627 16.2106C10.0627 16.1305 10.0308 16.0537 9.9742 15.997C9.91756 15.9404 9.84074 15.9086 9.76064 15.9086H7.95866V14.3198C7.95866 13.6606 8.50434 13.1041 9.16362 13.1041H9.8339C9.914 13.1041 9.99083 13.0722 10.0475 13.0156C10.1041 12.959 10.1359 12.8821 10.1359 12.802C10.1359 12.7219 10.1041 12.6451 10.0475 12.5885C9.99083 12.5318 9.914 12.5 9.8339 12.5H9.16362C8.17125 12.5 7.35461 13.3274 7.35461 14.3198V15.9086H6.60964C6.52954 15.9086 6.45272 15.9404 6.39608 15.997C6.33944 16.0537 6.30762 16.1305 6.30762 16.2106C6.30762 16.2907 6.33944 16.3675 6.39608 16.4242C6.45272 16.4808 6.52954 16.5126 6.60964 16.5126Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.309327"
      />
      <path
        d="M6.30188 5.18895L6.89466 5.26799C6.97369 4.67521 6.53899 3.96388 6.10429 3.29207C5.86718 2.89688 5.51151 2.30411 5.55103 2.14603L5.59055 2.10651C5.74862 2.02748 5.94622 2.14603 6.22284 2.30411C6.42044 2.42266 6.57851 2.54122 6.7761 2.54122C7.25032 2.58074 7.52695 2.30411 7.76406 2.10651C7.96165 1.90892 8.04069 1.8694 8.19876 1.8694C8.23828 1.90892 8.23828 1.94844 8.2778 1.98796C8.39636 2.14603 8.55443 2.34363 8.75202 2.42266C9.42383 2.65977 9.93757 2.26459 10.3328 1.94844C10.5699 1.75085 10.807 1.59278 11.0441 1.59278C11.2022 1.59278 11.2812 1.59278 11.3207 1.63229C11.2812 1.75085 11.0836 2.02748 11.0046 2.14603L10.9255 2.26459C10.3723 3.05496 9.97709 4.08243 9.81902 5.14943L10.4118 5.22847C10.5303 4.24051 10.886 3.33159 11.3998 2.62025L11.4788 2.5017C11.7554 2.14603 12.0716 1.71133 11.874 1.31615C11.6369 1 11.1626 1 11.0046 1C10.6094 1 10.2537 1.23711 9.93757 1.51374C9.54239 1.82989 9.26576 1.98796 8.91009 1.8694C8.87058 1.8694 8.75202 1.71133 8.7125 1.63229C8.59395 1.51374 8.51491 1.35567 8.35684 1.31615C7.8431 1.15807 7.52695 1.4347 7.32936 1.67181C7.13177 1.8694 7.01321 1.94844 6.81562 1.94844C6.7761 1.94844 6.65755 1.8694 6.57851 1.79037C6.30188 1.59278 5.86718 1.31615 5.35344 1.51374C5.11633 1.63229 5.03729 1.79037 4.99777 1.90892C4.87922 2.34363 5.19537 2.89688 5.63007 3.5687C5.94622 4.12195 6.3414 4.79377 6.30188 5.18895Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.382114"
      />
      <path
        d="M12.546 10.4056C11.7951 9.45718 11.0047 8.46922 10.3724 7.40222L9.85871 7.71837C10.5305 8.82488 11.3209 9.81284 12.0717 10.7613C12.9016 11.7888 13.7315 12.8953 14.4428 14.0413C15.0356 15.0293 16.7744 18.5464 14.68 21.0361C12.6645 23.4862 8.75219 23.8024 5.90687 22.8539C3.25914 21.9845 1.6784 20.1271 1.55985 17.8351C1.44129 15.3849 2.82444 13.6066 4.2471 11.7097C5.27458 10.4847 6.26254 9.22007 6.81579 7.71837L6.26254 7.52078C5.7488 8.94344 4.76084 10.1685 3.85191 11.3936C2.38973 13.2509 0.888035 15.2269 1.00659 17.9141C1.12514 20.4828 2.86395 22.5378 5.7488 23.4862C6.73676 23.8024 7.88279 23.9999 9.02882 23.9999C11.3209 23.9999 13.692 23.2491 15.1542 21.4708C17.4858 18.665 15.5889 14.8712 14.9566 13.7647C14.2453 12.5396 13.3758 11.4331 12.546 10.4056Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.382114"
      />
      <path
        d="M11.4789 6.45366C11.4789 6.29559 11.3603 6.13751 11.1627 6.13751H5.66966C5.51159 6.13751 5.35352 6.25607 5.35352 6.45366C5.35352 6.61173 5.47207 6.76981 5.66966 6.76981H11.1627C11.3208 6.73029 11.4789 6.61173 11.4789 6.45366Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.382114"
      />
    </svg>
  );
};
