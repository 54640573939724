import { ArrowLeftIcon, ArrowRightIcon, Button, CloseIcon, styled, theme } from '@otto-finance/ui';
import { TourProvider } from '@reactour/tour';
import { ReactNode } from 'react';

const CloseDiv = styled<'div', unknown>('div', () => ({
  position: 'absolute',
  right: '5%',
  top: '5%',
}));

export const AppTourProvider: React.FC<{ children: ReactNode }> = ({ children }) => (
  <TourProvider
    steps={[]}
    prevButton={({ currentStep, setCurrentStep, steps }) => {
      const first = currentStep === 0;
      return (
        <Button
          size="mini"
          kind="tertiary"
          onClick={() => {
            if (first) {
              setCurrentStep(() => steps.length - 1);
            } else {
              setCurrentStep((s) => s - 1);
            }
          }}
        >
          <ArrowLeftIcon />
        </Button>
      );
    }}
    nextButton={({ currentStep, stepsLength, steps, setIsOpen, setCurrentStep }) => {
      const last = currentStep === stepsLength - 1;
      return (
        <Button
          size="mini"
          kind="tertiary"
          onClick={() => {
            if (last) {
              setIsOpen(false);
            } else {
              setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
            }
          }}
        >
          <ArrowRightIcon />
        </Button>
      );
    }}
    styles={{
      popover: (base) => ({
        ...base,
        boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
        backgroundColor: theme.colors.white,
        '--reactour-accent': theme.colors.black,
        borderRadius: theme.borders.radius500,
      }),
      maskArea: (base) => ({ ...base, rx: 15 }),
      maskWrapper: (base) => ({ ...base }),
    }}
    padding={{ popover: 15 }}
    showCloseButton
    showDots={true}
    components={{
      Close: ({ onClick }) => (
        <CloseDiv>
          <Button onClick={onClick} shape="circle" size="mini" kind="tertiary">
            <CloseIcon />
          </Button>
        </CloseDiv>
      ),
    }}
    showBadge={false}
  >
    {children}
  </TourProvider>
);
