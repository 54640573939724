import { gql } from 'graphql-request';

const taskFields = `
  id
  summary
  description
  parent
  taskOrder
  taskStatus {
    name
  }
  parentInfo {
    summary
  }
  deadlineAt
  createdAt
  updatedAt
  createdBy
  taskLabelId
  taskLabel {
    id
    name
  }
  children {
    data {
      id
      summary
      description
      deadlineAt
      taskOrder
      taskLabelId
      taskStatus {
        name
      }
    }
  }
`;

const taskDetailsFields = `
  id
  summary
  taskOrder
  createdBy
  createdAt
  description
  assigneeUserId
  priority
  parent
  deadlineAt
  updatedAt
  parentInfo {
    summary
  }
  taskStatus {
    name
  }
  taskLabelId
`;

export const GetGoalsDocument = gql`
  query GetGoals($taskType: String, $assigneeUserId: String, $taskLabelId: String, $limit: Float, $offset: Float) {
    tasks(taskType: $taskType, assigneeUserId: $assigneeUserId, taskLabelId: $taskLabelId, limit: $limit, offset: $offset) {
      data {
        ${taskFields}
      }
    }
  }
`;

export const GetTasksDocument = gql`
  query GetTasks($taskType: String, $assigneeUserId: String, $taskLabelId: String, $limit: Float, $offset: Float, $status: String) {
    tasks(taskType: $taskType, assigneeUserId: $assigneeUserId, taskLabelId: $taskLabelId, limit: $limit, offset: $offset, status: $status) {
      data {
        ${taskDetailsFields}
      }
    }
  }
`;

export const UpdateTaskOrderDocument = gql`
  mutation UpdateTaskOrder($args: [TaskUpdateOrderDto!]!, $userId: String!) {
    updateTaskOrder(args: $args, userId: $userId)
  }
`;

export const DeleteTaskDocument = gql`
  mutation DeleteTask($id: String!) {
    deleteTask(id: $id)
  }
`;

export const UpdateTaskDocument = gql`
  mutation TaskUpdate($id: String!, $task: TaskUpdateDto!){
    updateTask(id: $id, task: $task) {
      ${taskDetailsFields}
    }
  }
`;

export const CreateTaskDocument = gql`
  mutation CreateTask($task: TaskCreateDto!){
    createTask(task: $task) {
      ${taskFields}
    }
  }
`;

export const GetTaskDocument = gql`
  query GetTask($id: String!) {
    getTask(id: $id) {
      ${taskDetailsFields}
    }
  }
`;

export const AutoPopulateTasksDocument = gql`
  mutation autoPopulateTasks {
    autoPopulateTasks
  }
`;

export const SaveTaskFileDocument = gql`
  mutation SaveTaskFile($fileId: String!, $status: StatusEnum!, $taskId: String!) {
    saveTaskFile(fileId: $fileId, status: $status, taskId: $taskId) {
      user {
        id
        firstName
        lastName
        type
      }
      file {
        id
        name
        path
        size
        url
        downloadUrl
        contentType
        updatedAt
      }
    }
  }
`;

export const TaskFilesDocument = gql`
  query TaskFiles($taskId: String!) {
    taskFiles(taskId: $taskId) {
      data {
        user {
          id
          firstName
          lastName
          type
        }
        file {
          id
          name
          path
          size
          url
          downloadUrl
          contentType
          updatedAt
        }
      }
      totalCount
    }
  }
`;

export const DeleteTaskFileDocument = gql`
  mutation DeleteTaskFile($fileId: String!, $taskId: String!) {
    deleteTaskFile(fileId: $fileId, taskId: $taskId)
  }
`;
