import { gql } from 'graphql-request';

export const UnreadNotificationsCountDocument = gql`
  query unreadNotificationsCount {
    unreadNotificationsCount
  }
`;

export const ReadAllNotificationsDocument = gql`
  mutation readAllNotifications {
    readAllNotifications
  }
`;

export const ReadNotificationDocument = gql`
  mutation readNotification($ids: [String!]!) {
    readNotification(ids: $ids)
  }
`;

export const NotificationsDocument = gql`
  query listNotifications(
    $userId: String
    $id: String
    $limit: Float
    $offset: Float
    $order: [NotificationOrderArgType!]
  ) {
    listNotifications(userId: $userId, id: $id, limit: $limit, offset: $offset, order: $order) {
      totalCount
      data {
        id
        userId
        type
        status
        schedule {
          date
        }
        sentAt
        readAt
        payload {
          channel
          data
        }
        dates {
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DeleteNotificationDocument = gql`
  mutation DeleteNotification($id: String!) {
    deleteNotificationById(id: $id)
  }
`;
