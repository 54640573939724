import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const AcceptIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 8, width = 11 }) => (
  <svg width={width} height={height} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.54545 1L3.67045 6.875L1 4.20455"
      stroke={color}
      strokeWidth="1.06818"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
