import { ListItem, ListItemInterface } from 'common/otto-ui/list/list-item';
import { Fragment, ReactNode } from 'react';

export type ListItemType = ListItemInterface | string;

export interface ListInterface {
  /** Items of list */
  items?: ListItemType[];
  /** Childrens of list instead using of items prop */
  children?: ReactNode;
}

function isItem(item: ListItemType): item is ListItemInterface {
  return typeof item !== 'string';
}

/** List component with items */
export const List = ({ items, children }: ListInterface) => (
  <div className="w-full flex rounded-lg flex-col bg-offWhite dark:bg-body text-body dark:text-white">
    {items && !children && (
      <Fragment>
        <ul className="w-full">
          {items.map((item: ListItemType, index: number) => {
            if (isItem(item)) {
              return <ListItem key={index} {...item} />;
            } else {
              return (
                <ListItem key={index} divider>
                  {item}
                </ListItem>
              );
            }
          })}
        </ul>
      </Fragment>
    )}
    {!items && children}
  </div>
);
