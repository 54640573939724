import { ReactNode } from 'react';
import styles from './sidebar-item.module.scss';
import Link from 'next/link';

type SidebarItemProps = {
  title: string;
  icon?: ReactNode;
  endEnhancer?: ReactNode;
  href: string;
  selected?: boolean;
  subItem?: boolean;
  onClick?: () => void;
};

export const SidebarItem = ({ title, icon, href, endEnhancer, selected, subItem, onClick }: SidebarItemProps) => {
  return (
    <Link
      href={href}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }
          : null
      }
      prefetch={false}
      className={styles.root}
      title={title}
      data-selected={selected}
      data-subitem={subItem}
    >
      {icon ? icon : <span />}
      <span>{title}</span>
      <span>{endEnhancer}</span>
    </Link>
  );
};
