import { ChatMessageInterface } from 'common/interfaces';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

export type GroupedChatMessages = {
  date: string;
  items: ChatMessageInterface[];
};

export const groupChatMessages = (messages: ChatMessageInterface[]): GroupedChatMessages[] => {
  const groupByDate = groupBy(messages, (item) => item.date);
  return sortBy(
    Object.keys(groupByDate).map((d) => ({ date: d, items: sortBy(groupByDate[d], (m) => m.createdAt) })),
    (item) => item.date,
  );
};
