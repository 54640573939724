export enum WealthProviderEnum {
  MoneyHub = 'MONEYHUB',
  Coinbase = 'COINBASE',
  Binance = 'BINANCE',
  Otto = 'OTTO',
  Plaid = 'PLAID',
  Manual = 'MANUAL',
  /**
   * Currently we can only get the most recent 3 months of data
   * from a user's connection. In future when we capture more data
   * we can extend this to 6, 12, 24 months with additional granularity (days/weeks/years).
   */
  MONTHS = 3,
  TERMS_BANK_NAME_VAR = '{BANK}',
  TERMS_CRYPTO_NAME_VAR = '{EXCHANGE}',
}
