import { gql } from 'graphql-request';

export const CalendarAvailabilityDocument = gql`
  query GetCalendarAvailability(
    $userId: String!
    $calendarId: String!
    $duration: Int!
    $start: DateTime!
    $end: DateTime!
  ) {
    appointmentOwnersAvailability(
      availabilityCheckOptions: {
        members: [{ userId: $userId, calendarIds: [$calendarId] }]
        duration: $duration
        start: $start
        end: $end
      }
    ) {
      serviceProviderEnabledUsers {
        # participants {
        #   userId
        # },
        start
        end
      }
    }
  }
`;

export const AvailabilityDocument = gql`
  query GetAvailability {
    coachAvailability {
      totalCount
      data {
        id
        dayOfWeek
        available
        startTime
        endTime
        bufferTime
      }
    }
  }
`;

export const UpdateAvailabilityDocument = gql`
  mutation UpdateAvailability($data: [SetCoachAvailabilityInput!]!) {
    setCoachAvailability(data: $data) {
      totalCount
      data {
        id
        dayOfWeek
        available
        startTime
        endTime
        bufferTime
      }
    }
  }
`;
