export enum CURRENCY {
  GBP = 'GBP',
  EUR = 'EUR',
}

export enum CURRENCY_SYMBOL {
  GBP = '£',
  EUR = '€',
  USD = '$',
}
