import { ColorTheme } from 'configuration/ui';
import { generateColorPalette, hslToHex, hslToObj, lightenColor } from 'utils/colors';

const THEME_STORAGE_KEY = 'ui_override';

export const overrideThemeUtil = (theme: Record<string, string>): Record<string, string> => {
  const hslArr = hslToObj(ColorTheme.accentDefault);
  let overrides: {} = {
    colors: {
      primary: 'hsl(135deg 5.13% 15.29%)',
      accent: ColorTheme.accentDefault,
      ...generateColorPalette('accent', hslArr),
    },
    typography: {
      ParagraphSmall: {
        lineHeight: '1.25rem',
      },
      HeadingXSmall: {
        fontWeight: 500,
      },
      HeadingSmall: {
        fontWeight: 500,
      },
      HeadingMedium: {
        fontWeight: 500,
      },
    },
  };
  if (theme && Object.keys(theme).length) {
    const primaryColor = theme['primaryColor'];
    const accentColor = theme['accentColor'];
    const primaryColorHsl = hslToObj(primaryColor);
    const accentColorHsl = hslToObj(accentColor);

    overrides = {
      ...overrides,
      colors: {
        black: primaryColor,
        primary: primaryColor,
        accent: hslToHex(accentColorHsl),
        ...generateColorPalette('accent', accentColorHsl),
        buttonPrimaryFill: primaryColor,
        buttonPrimaryHover: lightenColor(primaryColorHsl, primaryColorHsl[2] + 10),
        buttonPrimaryActive: lightenColor(primaryColorHsl, primaryColorHsl[2] + 15),
        listBodyFill: 'transparent',
      },
    };
  }
  return overrides;
};

export const saveThemeOverride = (overrides: Record<string, string>) => {
  localStorage.setItem(THEME_STORAGE_KEY, JSON.stringify(overrides));
};

export const getThemeOverride = (): { colors: Record<string, string>; typography: any } => {
  try {
    return JSON.parse(localStorage.getItem(THEME_STORAGE_KEY));
  } catch (error) {
    return {
      colors: {},
      typography: {},
    };
  }
};
