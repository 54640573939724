import { RISK_LEVEL } from 'enums';

export const RISK_PROFILES = {
  [RISK_LEVEL.CAUTIOUS]: {
    percentage: 0.05,
    id: RISK_LEVEL.CAUTIOUS,
    value: 1,
  },
  [RISK_LEVEL.MODERATELY_CAUTIOUS]: {
    percentage: 0.15,
    id: RISK_LEVEL.MODERATELY_CAUTIOUS,
    value: 2,
  },
  [RISK_LEVEL.BALANCED]: {
    percentage: 0.25,
    id: RISK_LEVEL.BALANCED,
    value: 3,
  },
  [RISK_LEVEL.MODERATELY_ADVENTUROUS]: {
    percentage: 0.35,
    id: RISK_LEVEL.MODERATELY_ADVENTUROUS,
    value: 4,
  },
  [RISK_LEVEL.ADVENTUROUS]: {
    percentage: 0.45,
    id: RISK_LEVEL.ADVENTUROUS,
    value: 5,
  },
};

export const getRiskScore = (
  value: number,
): {
  id: RISK_LEVEL;
  percentage: number;
  value: number;
} => {
  if (value >= 0 && value <= 10) {
    return RISK_PROFILES[RISK_LEVEL.CAUTIOUS];
  } else if (value >= 11 && value <= 16) {
    return RISK_PROFILES[RISK_LEVEL.MODERATELY_CAUTIOUS];
  } else if (value >= 17 && value <= 25) {
    return RISK_PROFILES[RISK_LEVEL.BALANCED];
  } else if (value >= 26 && value <= 34) {
    return RISK_PROFILES[RISK_LEVEL.MODERATELY_ADVENTUROUS];
  } else if (value >= 35 && value <= 48) {
    return RISK_PROFILES[RISK_LEVEL.ADVENTUROUS];
  }
  return {
    percentage: 0,
    id: null,
    value: 0,
  };
};
