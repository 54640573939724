import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CancelIcon: React.FC<IconProps> = ({ color = 'currentColor', width = 9, height = 9 }) => (
  <svg width={width} height={height} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.40681 1.40765L4.81361 4.81445M8.22042 8.22126L4.81361 4.81445M4.81361 4.81445L8.22042 1.40765M4.81361 4.81445L1.40681 8.22126"
      stroke={color}
      strokeWidth="1.13026"
      strokeLinecap="round"
    />
  </svg>
);
