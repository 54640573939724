import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { ROUTE_ENUM } from 'configuration/data/routers';
import type { RootState } from 'configuration/redux/reducer';
import { DocumentNode } from 'graphql';
import { ClientError, GraphQLClient } from 'graphql-request';
import Router from 'next/router';
import { logoutOnExpiredAction } from 'slices/auth-management/actions';
import { captureException, parseJwt } from 'utils';

export const client = new GraphQLClient(`${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`);

type ApiResponseError = Partial<ClientError & { statusCode: number }>;

const errorWhitelist = ['Unauthorized'];

const gqlBaseQuery = graphqlRequestBaseQuery<ApiResponseError>({
  client,
  prepareHeaders: (headers, { getState }) => {
    const {
      accessToken,
      userData: { timezone },
    } = (getState() as RootState).auth;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    headers.set('timezone', timezone ?? 'UTC');
    return headers;
  },
  customErrors: ({ name, stack, response }) => {
    const { message = '', extensions = {} } = response?.errors?.length ? response?.errors[0] : {};
    const errors = response?.errors;
    if (errors && !errorWhitelist.includes(message)) {
      captureException(message, JSON.stringify(errors));
    }
    return {
      name,
      message,
      statusCode: response?.status || extensions?.response?.statusCode || extensions?.exception?.status,
      stack,
      errors: errors || null,
    };
  },
});

export const baseQuery: BaseQueryFn<
  {
    document: string | DocumentNode;
    variables?: any;
  },
  unknown,
  ApiResponseError
> = async (args, api, extraOptions) => {
  const { accessToken } = (api.getState() as RootState).auth;
  if (accessToken) {
    const parsedAccessToken = parseJwt(accessToken);
    if (parsedAccessToken.exp < Math.floor(new Date().getTime() / 1000)) {
      await Router.push(ROUTE_ENUM.LOGIN);
      api.dispatch(logoutOnExpiredAction());
      return {
        data: null,
        error: {
          name: 'authExpired',
        },
      };
    }
  }
  const result = await gqlBaseQuery(args, api, extraOptions);
  if (result.error) {
    const { statusCode, message } = result.error;
    if (statusCode === 401) {
      await Router.push(ROUTE_ENUM.LOGIN);
    } else if (statusCode >= 500) {
      if (!errorWhitelist.includes(message)) {
        captureException(new Error(message));
      }
    }
  }
  return result;
};
