import { ApolloError } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteSessionsAction, fetchSessionsAction, getSessionAction } from 'slices/sessions/actions';
import { createNoteAction } from 'slices/sessions/actions/create-note.action';

export interface SessionsAppointmentNoteInterface {
  createdBy: string;
  note: string;
  createdAt: string;
}

export interface SessionsInterface {
  title: string;
  start: string;
  end: string;
  joinUrl: string;
  id: string;
  appointmentOwnerId: string;
  createdById: string;
  createdAt: string;
  appointmentParticipants: {
    data: {
      userId: string;
    }[];
  };
  appointmentNotes: {
    totalCount: number;
    data: SessionsAppointmentNoteInterface[];
  };
}

export interface SessionsDetailsInterface {
  isLoading: boolean;
  error: ApolloError;
  session: SessionsInterface;
}

export interface SessionsStateInterface {
  isLoading: boolean;
  error: Error;
  sessions: SessionsInterface[];
  sessionDetails: SessionsDetailsInterface;
}

const initialState: SessionsStateInterface = {
  isLoading: false,
  error: null,
  sessions: [],
  sessionDetails: {
    isLoading: false,
    error: null,
    session: null,
  },
};

const sessionsSlice = createSlice({
  initialState,
  name: 'sessions',
  reducers: {
    clearSessionsErrorAction: (state) => {
      state.error = null;
    },
    removeSession: (state, { payload }) => {
      const sessions = [...state.sessions];
      const sessionIndex = sessions.findIndex((x) => x.id === payload);
      sessions.splice(sessionIndex, 1);
      state.sessions = sessions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessionsAction.fulfilled as any, (state, { payload: { data } }) => {
        state.sessions = data;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSessionsAction.pending as any, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSessionsAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })
      .addCase(deleteSessionsAction.fulfilled as any, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteSessionsAction.pending as any, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSessionsAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })
      .addCase(
        createNoteAction.fulfilled as any,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            note: string;
            createdBy: string;
            appointmentId: string;
            createdAt: string;
          }>,
        ) => {
          const sessions = [...state.sessions];
          const sessionIndex = sessions.findIndex((x) => x.id === payload.appointmentId);
          const session = { ...state.sessions[sessionIndex] };
          session.appointmentNotes.data.unshift({
            createdAt: payload.createdAt,
            createdBy: payload.createdBy,
            note: payload.note,
          });
          sessions[sessionIndex] = session;
          state.sessions = sessions;
        },
      )
      .addCase(getSessionAction.fulfilled as any, (state, { payload }) => {
        state.sessionDetails.session = payload;
        state.sessionDetails.isLoading = false;
        state.sessionDetails.error = null;
      })
      .addCase(getSessionAction.pending as any, (state) => {
        state.sessionDetails.isLoading = true;
      })
      .addCase(getSessionAction.rejected as any, (state, { payload }) => {
        state.sessionDetails.error = payload;
        state.sessionDetails.isLoading = false;
      });
  },
});

export default sessionsSlice.reducer;
export const { clearSessionsErrorAction, removeSession } = sessionsSlice.actions;
export { fetchSessionsAction, deleteSessionsAction };
