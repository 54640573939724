import { gql } from 'graphql-request';

export const ContactSupportDocument = gql`
  mutation ContactSupport($data: CustomerSupportSendDto!) {
    contactSupport(input: $data)
  }
`;

export const ContactExpertDocument = gql`
  mutation ContactExpert(
    $expertId: String!
    $expertFirstName: String!
    $expertLastName: String!
    $expertEmail: String!
  ) {
    contactExpert(
      expertId: $expertId
      expertFirstName: $expertFirstName
      expertLastName: $expertLastName
      expertEmail: $expertEmail
    )
  }
`;

const pageFields = `
{
  tour {
    version
  }
}
`;

export const GetSettingsDocument = gql`
  query GetSettings {
    settings {
      id
      features {
        version
      }
      actions {
        seenIntro
      }
      surveys
      pages {
        dashboard ${pageFields}
        investments ${pageFields}
        investmentsAnalysis ${pageFields}
      }
    }
  }
`;

export const UpdateSettingsDocument = gql`
  mutation UpdateSettings($input: SettingsUpdateDto!) {
    updateSettings(input: $input) {
      id
      features {
        version
      }
      actions {
        seenIntro
      }
      pages {
        dashboard ${pageFields}
        investments ${pageFields}
        investmentsAnalysis ${pageFields}
      }
    }
  }
`;

export const SiteSettingsDocument = gql`
  query SiteSettings($url: String!) {
    siteSettings(url: $url) {
      faviconUrl
      logoUrl
      logoUrlContrast
      logoWidth
      theme
      title
      disableCoachingIntro
      naming {
        service
        persona
      }
    }
  }
`;

export const ShareFinancialInformationDocument = gql`
  mutation ShareFinancialInformation($share: Boolean!, $userId: String!) {
    shareFinancialInformation(share: $share, userId: $userId)
  }
`;
