import { IconProps } from 'common/otto-ui/icons/icon.type';

export const ForumIcon = ({ size = 24 }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M7 3C5.90694 3 5 3.90694 5 5V8L2 11H7H20C21.0931 11 22 10.0931 22 9V5C22 3.90694 21.0931 3 20 3H7ZM7 5H20V9H7V5ZM7 13C5.90694 13 5 13.9069 5 15V18L2 21H7H20C21.0931 21 22 20.0931 22 19V15C22 13.9069 21.0931 13 20 13H7ZM7 15H20V19H7V16V15Z"
    />
  </svg>
);
