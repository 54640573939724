'use client';

import { ToasterContainer } from '@otto-finance/ui';
import { useLazyFetchAccountsQuery } from 'api/accounts.api';
import { useLazyFinanceOverviewQuery } from 'api/finance.api';
import { useLazySettingsQuery } from 'api/settings.api';
import { useLazyCurrentUserQuery } from 'api/users.api';
import { UserInterface } from 'common/interfaces';
import { ConfirmAlert } from 'common/otto-ui/confirm-alert/confirm-alert';
import { useIdentifyUser, usePageChange } from 'common/roq-hooks';
import { useAppDispatch } from 'configuration/redux/store';
import { ReactNode, useEffect } from 'react';
import { updateUserCoachAction } from 'slices/user-coach';
import { useLoginHook } from 'views/login/hooks';
import { AppContext } from './app.context';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { useRouter } from 'next/router';

const AppProvider = ({ children }: { children: ReactNode }) => {
  const { userData, isLogged, isCustomer, currency } = useLoginHook();
  const [getFinanceOverview] = useLazyFinanceOverviewQuery();
  const [getAccounts] = useLazyFetchAccountsQuery();
  const [getSettings] = useLazySettingsQuery();
  const dispatch = useAppDispatch();
  const { identifyUser } = useIdentifyUser();
  const [getUser] = useLazyCurrentUserQuery();
  const router = useRouter();

  usePageChange();

  useEffect(() => {
    if (isLogged) {
      getUser({ id: userData.id });
      getSettings(undefined);
      if (isCustomer) {
        dispatch(updateUserCoachAction(userData as UserInterface));
        if (router?.route !== ROUTE_ENUM.SESSION_ROOM) {
          getFinanceOverview({ userId: userData.id, convertTo: currency });
          getAccounts({ userId: userData.id, convertTo: currency });
        }
      }
      void identifyUser();
    }
  }, [isLogged]);

  return (
    <AppContext.Provider value={{}}>
      {children}
      <ToasterContainer
        placement="topRight"
        overrides={{
          Root: {
            style: () => ({
              zIndex: 999,
            }),
          },
        }}
      />
      <ConfirmAlert />
    </AppContext.Provider>
  );
};

export { AppProvider };
