import { createAsyncThunk } from '@reduxjs/toolkit';
import store, { ThunkExtraArguments } from 'configuration/redux/store';
import { confirmAlertActions, ConfirmAlertOptiomsInterface } from '../confirm-alert.slice';

export const confirmationAlertAction = createAsyncThunk('actions/confirmAlert', async (_args, { dispatch }) => {
  return new Promise<boolean>((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.confirmAlert.isConfirmed) {
        unsubscribe();
        resolve(true);
      }
      if (state.confirmAlert.isDeclined) {
        unsubscribe();
        resolve(false);
      }
    });
  });
});

// export const confirmationAlertAction = createAsyncThunk<boolean, ConfirmAlertOptiomsInterface, { extra: ThunkExtraArguments; }>(
//     'actions/confirmAlert',
//     async (args, { dispatch }) => {
//       dispatch<boolean, ConfirmAlertOptiomsInterface>(confirmAlertActions.open(args));
//       return new Promise<boolean>(resolve => {
//         const unsubscribe = store.subscribe(() => {
//           const state = store.getState();
//           if (state.confirmAlert.isConfirmed) {
//             unsubscribe();
//             resolve(true);
//           }
//           if (state.confirmAlert.isDeclined) {
//             unsubscribe();
//             resolve(false);
//           }
//         });
//       });
//     }
//   );
