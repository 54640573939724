import { theme } from '@otto-finance/ui';
import { SecurityClassStatus } from 'common/interfaces';

type Color = 'positive' | 'negative' | 'warning';

export const outcomeStatusMap: { [status in SecurityClassStatus]: { color: string | Color; label: string } } = {
  [SecurityClassStatus.UNDERWEIGHT]: {
    color: 'rgb(237 59 125)',
    label: 'Potentially Underweight',
  },
  [SecurityClassStatus.BALANCED]: {
    color: theme.colors.positive200,
    label: 'Balanced',
  },
  [SecurityClassStatus.OVERWEIGHT]: {
    color: theme.colors.warning400,
    label: 'Potentially Overweight',
  },
};
