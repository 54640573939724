import googleAnalyticsPlugin from '@analytics/google-analytics';
import segmentPlugin from '@analytics/segment';
import { __DEV__ } from 'utils';

export const plugins = __DEV__
  ? []
  : [
      googleAnalyticsPlugin({
        measurementIds: [process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_V4_MEASUREMENT_ID],
        debug: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
      }),
      segmentPlugin(
        {
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE,
        },
        // {
        //   obfuscate: true
        // }
      ),
    ];
