import { FormControl, Textarea, TextareaProps } from '@otto-finance/ui';
import { useField } from 'formik';

export const FormTextareaField = ({
  label,
  name,
  caption,
  ...props
}: TextareaProps & { label: string; caption?: string }) => {
  const [field, meta] = useField({ name });
  return (
    <div>
      <FormControl label={`${label}${props.required ? '*' : ''}`} error={meta.error ? '' : null} caption={caption}>
        <Textarea {...props} name={name} onChange={field.onChange} value={field.value} />
      </FormControl>
    </div>
  );
};
