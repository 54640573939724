import { Appointment, AppointmentNote, AppointmentRoom, CoachCalendarInterface } from 'common/interfaces';
import { caching } from 'configuration/data';
import {
  AttendAppointmentDocument,
  CancelSessionDocument,
  CreateAppointmentDocument,
  CreateRoomRecordingDocument,
  CreateSessionNoteDocument,
  DeleteSessionNoteDocument,
  GenerateSessionSummaryDocument,
  GetCoachCalendarsDocument,
  GetSessionDocument,
  GetSessionsDocument,
  GetTotalSessionsDocument,
  ResendAppointmentGuestUrlDocument,
  SessionNotesDocument,
  SessionRoomDocument,
  UpdateSessionDocument,
  UpdateTranscriptionDocument,
} from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { AppointmentAttendeesDto, PagedAppointmentNote } from 'common/interfaces';
import { APPOINTMENT_TRANSCRIPTION_TYPE, SummaryTypeEnum, UserTypeEnum } from 'enums';
import { isSessionActive } from 'utils/is-session-active';

interface UpdateTranscriptionArgs {
  // sessionId?: string;
  // userId?: string;
  // sentence?: string;
  // timestamp?: string;
  summary?: string;
  appointmentId: string;
  reportSummary?: APPOINTMENT_TRANSCRIPTION_TYPE;
  reportComment?: string;
}

export interface AppointmentCreateDto {
  appointmentOwnerId?: string;
  appointmentStatus?: string;
  attendees: AppointmentAttendeesDto;
  calendarId?: string;
  createdById?: string;
  description?: string;
  end?: string;
  externalId?: string;
  joinUrl?: string;
  profileId?: string;
  providerDescription?: string;
  start?: string;
  title: string;
  userId?: string;
  clientId?: string;
  reportType?: string;
  duration?: number;
  instant?: boolean;
  inPerson?: boolean;
}

export interface GetSessionsArgs {
  participantUserId?: string;
  start?: string;
  end?: string;
  ids?: string[];
  limit?: number;
  offset?: number;
  participants?: string[];
  order?: {
    sort: string;
    order: string;
  };
  appointmentOwnerId?: string;
  userId?: string;
  appointmentParticipantId?: string;
  listNextAppointmentsOnly?: boolean;
  listPreviousAppointmentsOnly?: boolean;
  listPendingAppointmentsOnly?: boolean;
}

export const sessionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    attendAppointment: builder.mutation<boolean, { id: string }>({
      query: (args) => ({
        variables: args,
        document: AttendAppointmentDocument,
      }),
    }),
    cancelSession: builder.mutation<string, { id: string }>({
      query: (variables) => ({
        variables,
        document: CancelSessionDocument,
      }),
      invalidatesTags: [API_TAGS.SESSIONS],
    }),
    generateSessionSummary: builder.mutation<boolean, { id: string; type: SummaryTypeEnum }>({
      query: ({ id, type }) => ({
        variables: { appointmentId: id, type },
        document: GenerateSessionSummaryDocument,
      }),
      invalidatesTags: (_, __, arg) => [{ type: API_TAGS.SESSION, id: arg.id }],
    }),
    updateTranscription: builder.mutation<boolean, UpdateTranscriptionArgs>({
      query: (args) => ({
        variables: { input: args },
        document: UpdateTranscriptionDocument,
      }),
      async onQueryStarted({ appointmentId, summary }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            sessionsApi.util.updateQueryData('getSession', { id: appointmentId }, (draft) => {
              draft.transcription.summary.updated = summary;
              draft.transcription.dates.updatedAt = new Date().toISOString();
            }),
          );
        } catch {}
      },
    }),
    getSessions: builder.query<{ totalCount: number; data: Appointment[] }, GetSessionsArgs>({
      query: (variables) => ({
        variables,
        document: GetSessionsDocument,
      }),
      transformResponse: (response: { appointments: { totalCount: number; data: Appointment[] } }) => {
        const { totalCount, data } = response.appointments;
        return {
          totalCount,
          data:
            data && data.length
              ? data.map((d) => {
                  const customer = d.appointmentParticipants.data.find((f) => f.user?.type === UserTypeEnum.CUSTOMER);
                  return {
                    ...d,
                    // ...(d.transcription && {
                    //   transcriptionFormat: transformSessionTranscription(d.transcription),
                    // }),
                    isActiveSession: isSessionActive(d.start, d.end),
                    // duration: dateToMinutes(d.end,d.start),
                    customerUser: {
                      id: customer?.userId,
                      fullName: customer?.externalName,
                    },
                  };
                })
              : [],
        };
      },
      keepUnusedDataFor: caching.fiveMinutes,
      providesTags: [API_TAGS.SESSIONS],
    }),
    getTotalSessions: builder.query<number, GetSessionsArgs>({
      query: (variables) => ({
        variables,
        document: GetTotalSessionsDocument,
      }),
      transformResponse: (response: { appointments: { totalCount: number } }) => {
        return response.appointments?.totalCount ?? 0;
      },
      keepUnusedDataFor: caching.day,
    }),
    getSession: builder.query<Appointment, { id: string }>({
      query: (variables) => ({
        variables,
        document: GetSessionDocument,
      }),
      transformResponse: (response: { appointment: Appointment }) => {
        const { appointment } = response;
        return {
          ...appointment,
          isActiveSession: isSessionActive(appointment.start, appointment.end),
          formalTitle: `${appointment.users.map((u) => u.firstName).join(' & ')} ${appointment.title}`,
          // ...(appointment.transcription && {
          //   transcriptionFormat: transformSessionTranscription(appointment.transcription),
          // })
        };
      },
      keepUnusedDataFor: caching.oneHour,
      providesTags: (_, __, arg) => [{ type: API_TAGS.SESSION, id: arg.id }],
    }),
    createSessionNote: builder.mutation<AppointmentNote, { appointmentId: string; createdBy: string; note: string }>({
      query: (variables) => ({
        variables: { input: variables },
        document: CreateSessionNoteDocument,
      }),
      transformResponse: ({ createAppointmentNote }: { createAppointmentNote: AppointmentNote }) =>
        createAppointmentNote,
      invalidatesTags: [API_TAGS.SESSION_NOTES],
    }),
    deleteSessionNote: builder.mutation<string, { id: string }>({
      query: (variables) => ({
        variables,
        document: DeleteSessionNoteDocument,
      }),
      invalidatesTags: [API_TAGS.SESSION_NOTES],
    }),
    sessionNotes: builder.query<
      PagedAppointmentNote,
      { appointmentId: string; order?: { order: string; sort: string } }
    >({
      query: (variables) => ({
        variables,
        document: SessionNotesDocument,
      }),
      transformResponse: ({ appointmentNotes }: { appointmentNotes: PagedAppointmentNote }) => appointmentNotes,
      providesTags: [API_TAGS.SESSION_NOTES],
    }),
    updateSession: builder.mutation<Appointment, { id: string; data: Partial<AppointmentCreateDto> }>({
      query: ({ id, data }) => ({
        variables: { id, appointment: data },
        document: UpdateSessionDocument,
      }),
      transformResponse: ({ updateAppointment }: { updateAppointment: Appointment }) => updateAppointment,
    }),
    sessionRoom: builder.query<AppointmentRoom, { id: string }>({
      query: (variables) => ({
        variables,
        document: SessionRoomDocument,
      }),
      transformResponse: ({ appointmentRoom }: { appointmentRoom: AppointmentRoom }) => appointmentRoom,
    }),
    createRoomRecording: builder.mutation<boolean, { url: string; data: Blob; contentRange: string }>({
      query: (variables) => ({
        variables,
        document: CreateRoomRecordingDocument,
      }),
    }),
    createSession: builder.mutation<Partial<Appointment>, AppointmentCreateDto>({
      query: (variables) => ({
        variables: { appointment: variables },
        document: CreateAppointmentDocument,
      }),
      // invalidatesTags: [API_TAGS.SESSIONS]
      transformResponse: ({ createAppointment }: { createAppointment: Appointment }) => createAppointment,
    }),
    coachCalendars: builder.query<CoachCalendarInterface, { userId: string }>({
      query: (variables) => ({
        variables,
        document: GetCoachCalendarsDocument,
      }),
      transformResponse: ({ appointmentOwnerCalendars }: { appointmentOwnerCalendars }) => appointmentOwnerCalendars,
    }),
    resendAppointmentGuestUrl: builder.mutation<boolean, { email: string; id: string }>({
      query: (variables) => ({
        variables,
        document: ResendAppointmentGuestUrlDocument,
      }),
      transformResponse: ({ resendAppointmentGuestUrl }: { resendAppointmentGuestUrl }) => resendAppointmentGuestUrl,
    }),
  }),
});

export const {
  useAttendAppointmentMutation,
  useUpdateTranscriptionMutation,
  useGetSessionsQuery,
  useCreateSessionNoteMutation,
  useSessionNotesQuery,
  useLazySessionNotesQuery,
  useGetSessionQuery,
  useLazyGetSessionQuery,
  useUpdateSessionMutation,
  useLazyGetSessionsQuery,
  useSessionRoomQuery,
  useLazySessionRoomQuery,
  useCancelSessionMutation,
  useCreateRoomRecordingMutation,
  useDeleteSessionNoteMutation,
  useCreateSessionMutation,
  useGetTotalSessionsQuery,
  useLazyGetTotalSessionsQuery,
  useCoachCalendarsQuery,
  useLazyCoachCalendarsQuery,
  useResendAppointmentGuestUrlMutation,
  useGenerateSessionSummaryMutation,
} = sessionsApi;
