import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CheckmarkIcon: React.FC<IconProps> = ({ height = 22, width = 22, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width={width - 5} height={height - 5} stroke={color} strokeWidth="1.2" />
    <path
      d="M14.1616 6.48242L8.12939 12.5147L5.38745 9.77274"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
