export const benchmarkIndexes: { [symbol: string]: string } = {
  '^FTSE': 'FTSE 100',
  '^SP500TR': 'S&P 500 (TR)',
  '^SPGNRUP': 'S&P Global Natural Resources Index',
  '^NDX': 'NASDAQ 100',
  '^DJI': 'Dow Jones Industrial Average',
  '^FVX': 'Treasury Yield 5 Years',
  '^TNX': 'Treasury Yield 10 Years',
  '^TYX': 'Treasury Yield 30 Years',
  '^N100': 'EURONEXT 100',
  '^HSI': 'HANG SENG INDEX',
  '^N225': 'Nikkei 225',
  '^GDAXI': 'DAX PERFORMANCE-INDEX',
  '^OVX': 'CBOE Crude Oil Volatility Index',
  '^SPGSCI': 'S&P GSCI Index',
  '^VIX': 'CBOE Volatility Index',
};
