import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface FetchSessionsInterface {
  userId: string;
  configurationId?: string;
}

export interface FetchSessionsActionRequestInterface extends QueryOptions {
  variables: FetchSessionsInterface;
}

export const fetchSessionsAction = createAsyncThunk(
  'survey/sessions/fetch',
  async (request: FetchSessionsActionRequestInterface, thunkApi): Promise<any> =>
    await requestGql<any>(request, thunkApi),
);
