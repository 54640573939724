import { Draft, EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { Action, KanbanColumn, KanbanGoal, UserSimpleInterface } from 'common/interfaces';
import { StoreInterface } from 'configuration/redux/store';
import { ACTION_VIEW, TaskStatusEnum } from 'enums';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';

type Kanban = { [key: string]: KanbanColumn };
type ActionsStateStatus = 'isLoaded' | 'isLoading' | 'isError';

const tasksAdapter = createEntityAdapter<Action>();

export interface ActionsStateInterface extends EntityState<Action, string> {
  userId: string;
  tasksView: ACTION_VIEW;
  kanban: Kanban;
  users: { [userId: string]: UserSimpleInterface };
  goals: { [goalId: string]: { id: string; summary: string } };
  status: { type: ActionsStateStatus };
}

const defaultKanbanState: Kanban = {
  [TaskStatusEnum.OPEN]: {
    id: TaskStatusEnum.OPEN,
    title: 'To Do',
    list: [],
  },
  [TaskStatusEnum.INPROGRESS]: {
    id: TaskStatusEnum.INPROGRESS,
    title: 'In Progress',
    list: [],
  },
  [TaskStatusEnum.COMPLETED]: {
    id: TaskStatusEnum.COMPLETED,
    title: 'Done',
    list: [],
  },
  [TaskStatusEnum.CLOSED]: {
    id: TaskStatusEnum.CLOSED,
    title: 'Closed',
    list: [],
  },
};

const initialState: ActionsStateInterface = {
  userId: null,
  tasksView: ACTION_VIEW.KANBAN,
  kanban: { ...defaultKanbanState },
  users: {},
  goals: {},
  status: { type: 'isLoading' },
  ...tasksAdapter.getInitialState(),
};

const slice = createSlice({
  initialState,
  name: 'actions',
  reducers: {
    setActionsStatus: (state, { payload }: PayloadAction<ActionsStateStatus>) => {
      state.status = { type: payload };
    },
    setActions: (
      state,
      { payload }: PayloadAction<{ users: UserSimpleInterface[]; actions: Action[]; userId: string }>,
    ) => {
      state.userId = payload.userId;
      state.users = payload.users.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {});
      groupByStatus(payload.actions, state.kanban);
      tasksAdapter.setAll(state, payload.actions);
      state.goals = payload.actions.reduce((a, c) => {
        a[c.parent] = { id: c.parent, summary: c.parentInfo.summary };
        return a;
      }, {});

      state.status = { type: 'isLoaded' };
    },
    toggleTasksView: (state) => {
      state.tasksView = state.tasksView === ACTION_VIEW.KANBAN ? ACTION_VIEW.LIST : ACTION_VIEW.KANBAN;
    },
    addAction: (state, { payload }: PayloadAction<Action>) => {
      tasksAdapter.setOne(state, payload);
      groupByStatus(Object.values(state.entities), state.kanban);
    },
    removeAction: (state, { payload }: PayloadAction<string>) => {
      tasksAdapter.removeOne(state, payload);
      groupByStatus(Object.values(state.entities), state.kanban);
    },
    addGoal: (state, { payload }: PayloadAction<{ id: string; summary: string }>) => {
      state.goals[payload.id] = payload;
    },
    editGoal: (state, { payload: { id, summary } }: PayloadAction<{ id: string; summary: string }>) => {
      state.goals[id].summary = summary;
    },
    removeGoal: (state, { payload }: PayloadAction<string>) => {
      delete state.goals[payload];
      tasksAdapter.removeMany(
        state,
        Object.values(state.entities)
          .filter((f) => f.parent === payload)
          .map((t) => t.id),
      );
      groupByStatus(Object.values(state.entities), state.kanban);
    },
    updateTaskAction: (state, { payload: { id, data } }: { payload: { id: string; data: Partial<Action> } }) => {
      state.entities[id] = {
        ...state.entities[id],
        ...data,
        updatedAt: new Date().toISOString(),
      };

      // Update fields in kanban
      const partialActionFields = pick(data, ['summary', 'deadlineAt']);
      if (Object.keys(partialActionFields).length) {
        const status = state.entities[id].taskStatus.name;
        state.kanban[status].list.forEach((g) => {
          if (g.id === state.entities[id].parent) {
            g.list.forEach((t) => {
              if (t.id === id) {
                Object.keys(partialActionFields).forEach((k) => {
                  t[k] = partialActionFields[k];
                });
                t.updatedAt = new Date().toISOString();
              }
            });
          }
        });
      }
      // Rebuild kanban if status changes
      if (data?.taskStatus) {
        groupByStatus(Object.values(state.entities), state.kanban);
      }
    },
  },
});

const groupByStatus = (actions: Action[], kanban: Draft<Kanban>) => {
  Object.values(TaskStatusEnum).forEach((status) => {
    const _groupedTasks = groupBy(
      actions.filter((f) => f.taskStatus.name === status),
      'parent',
    );
    kanban[status].list = Object.keys(_groupedTasks).map((id) => ({
      id,
      list: _groupedTasks[id],
    })) as KanbanGoal[];
  });
};

export default slice.reducer;
export const {
  updateTaskAction,
  removeAction,
  addAction,
  toggleTasksView,
  setActions,
  editGoal,
  removeGoal,
  setActionsStatus,
  addGoal,
} = slice.actions;

const { selectById } = tasksAdapter.getSelectors<StoreInterface>((state) => state.actions);

const actionsSelector = ({ actions }: { actions: ActionsStateInterface }) => actions;

export const actionsViewSelector = createSelector(actionsSelector, (v) => v.tasksView);
export const actionsKanbanSelector = createSelector(actionsSelector, (v) => v.kanban);
export const actionsUserSelector = createSelector(actionsSelector, (v) => v.users);
export const actionsStatusSelector = createSelector(actionsSelector, (v) => v.status);
export const actionsGoalsSelector = createSelector(actionsSelector, (v) => v.goals);
export const selectActionsUser = createSelector(actionsSelector, (v) => v.userId);
export const selectAllActions = createSelector(actionsSelector, (v) => Object.values(v.entities));

export const selectTaskById = (id) => (state) => selectById(state, id);
