import { FormBuilderType } from 'components/form-builder/types';
import * as yup from 'yup';

/*
// Example

const fields = [
  {
    name: 'person.lastName', // nested in an object
    label: 'Last name',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['Required']
      }
    ]
  },
    {
    name: 'email', // non nested, non array
    label: 'Email',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['Required']
      }
    ]
  }
];

*/

type YupSchemaFields = {
  name: string;
  validationType: string;
  validationTypeError?: string;
  validations: {
    type: string;
    params: string[];
  }[];
};

export const yupTypeMap = {
  text: 'string',
};

export const getYupSchema = (fields: YupSchemaFields[]): yup.AnySchema => {
  const yupSchema = fields.reduce((schema, field) => {
    const { name, validationType, validationTypeError, validations = [] } = field;
    const isObject = name.indexOf('.') >= 0;

    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]().typeError(validationTypeError || '');
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](params);
    });

    if (!isObject) {
      return schema.concat(yup.object().shape({ [name]: validator }));
    }

    const path = name.split('.');
    const nestedName = path.pop();

    // Array vs Object
    const newFieldSchema = !isNaN(Number(path[1]))
      ? yup.array().of(yup.object({ [nestedName]: validator }))
      : yup.object().shape({ [nestedName]: validator });
    const objectSchema = yup.object().shape({
      [path[0]]: schema.fields[path[0]] ? schema.fields[path[0]].concat(newFieldSchema) : newFieldSchema,
    });
    return schema.concat(objectSchema);
  }, yup.object().shape({}));

  return yupSchema;
};
