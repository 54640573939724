import { InvestmentAccountInterface } from 'common/interfaces';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { investmentsAccountsSelector } from 'slices/investments';

export const useInvestmentAsset = (accountId: string): InvestmentAccountInterface => {
  const accounts = useSelector(investmentsAccountsSelector);
  const asset = useMemo(() => accounts.find((f) => f.id === accountId), [accounts, accountId]);
  return asset;
};
