import { currencyMap } from 'configuration/data/currencies';

export const subPercent = (n: number, p: number): number => n - n * (p / 100);
export const addPercent = (n: number, p: number): number => n + n * (p / 100);

/**
 * Converts number from `100000` > `100K`, `1500000` > `1.5M` etc
 * @param n Amount
 * @returns string
 */
export const abbreviateNumber = (n: number): string => {
  let v = n;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (v >= 1000) {
    v /= 1000;
    suffixNum++;
  }
  if (suffixNum === 0) {
    return Math.round(v).toString();
  }
  return `${v.toString().length > 2 ? v.toPrecision(3) : v.toPrecision()}${suffixes[suffixNum]}`;
};

export const renderMoney = (a: number, currency = 'GBP', showCurrency = true): string =>
  `${a < 0 ? `-` : ''}${showCurrency ? currencyMap[currency]?.symbol : ''}${abbreviateNumber(Math.abs(a))}`;

/**
 * Get percentage from 2 numbers
 * @param a
 * @param b
 */
export const getPercentage = (a: number, b: number, d = 0): number =>
  a + b === 0 ? 0 : a > 0 ? Number(((b / a) * 100).toFixed(d)) : 100;

export const randomRangeNumber = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const average = (arr: number[]): number => arr.reduce((p, c) => p + c, 0) / arr.length;

export const sigFigCount = (v: number): string | number => v?.toString().split('.')?.[1]?.length || 1;
