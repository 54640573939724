import { AccountTypeEnum, AssetCategoryEnum } from 'enums';

export enum AssetToggleEnum {
  CATEGORY = 'category',
  PROVIDER = 'provider',
}

export enum AccountPolicyEnum {
  /**
   * Account is an ISA but the user (or otto) doesn't know specifically which kind of ISA.
   */
  UNKNOWN_ISA = 'ISA',
  /**
   * A Cash ISA is a tax-free savings account that pays interest on your savings. You can open one Cash ISA per tax
   * year, and there is a maximum annual allowance for contributions, which is £20,000 for the 2022/23 tax year.
   */
  CASH_ISA = 'CASH_ISA',
  /**
   * A Stocks and Shares ISA is a tax-free investment account that allows you to invest in a range of different investments,
   * including stocks, shares, funds, and bonds. You can open one Stocks and Shares ISA per tax year, and there is a maximum
   * annual allowance for contributions, which is also £20,000 for the 2022/23 tax year.
   */
  STOCKS_SHARES_ISA = 'STOCKS_SHARES_ISA',
  /**
   * A Lifetime ISA is a tax-free savings and investment account that is designed to help people save for their first home or
   * for retirement. You can contribute up to £4,000 per tax year, and the government will add a 25% bonus to your contributions,
   * up to a maximum of £1,000 per tax year.
   */
  LIFETIME_ISA = 'LIFETIME_ISA',
  /**
   * An Innovative Finance ISA (IFISA) is a tax-free investment account that allows you to invest in peer-to-peer lending or
   * crowdfunding. The maximum annual allowance for contributions to an IFISA is also £20,000 for the 2022/23 tax year.
   */
  INNOVATIVE_FINANCE_ISA = 'INNOVATIVE_FINANCE_ISA',
  /**
   * A Junior ISA is a tax-free savings and investment account that is designed for children under the age of 18. There are two
   * types of Junior ISA: Cash Junior ISAs and Stocks and Shares Junior ISAs. The maximum annual allowance for contributions to
   * a Junior ISA is £9,000 for the 2022/23 tax year.
   */
  JUNIOR_ISA = 'JUNIOR_ISA',
}

export enum AccountSubtypeEnum {
  CURRENT = 'CURRENT',
  REPAYMENT = 'REPAYMENT',
  INTEREST_ONLY = 'INTEREST_ONLY',
  DEFINED_BENEFIT = 'DEFINED_BENEFIT',
  DEFINED_CONTRIBUTION = 'DEFINED_CONTRIBUTION',
  RESIDENTIAL = 'RESIDENTIAL',
  BUY_TO_LET = 'BUY_TO_LET',
}

export interface AccountTypeInterface {
  name: AssetCategoryEnum;
  title: string;
  types: AccountTypeEnum[];
  description: string;
  beta?: string[];
  isManual?: boolean;
}

export interface AccountSubTypeInterface {
  name: AccountSubtypeEnum;
  title: string;
}

interface ManualAccountTypeInterface {
  name: AccountTypeEnum;
  title: string;
  subtypes: AccountSubtypeEnum[];
  children: AccountSubTypeInterface[];
}

export const MANUAL_ACCOUNT_TYPES: ManualAccountTypeInterface[] = [
  {
    name: AccountTypeEnum.CASH,
    title: 'Cash',
    subtypes: [AccountSubtypeEnum.CURRENT],
    children: [
      {
        name: AccountSubtypeEnum.CURRENT,
        title: 'Current',
      },
    ],
  },
  {
    name: AccountTypeEnum.SAVINGS,
    title: 'Savings',
    subtypes: [],
    children: [],
  },
  {
    name: AccountTypeEnum.INVESTMENT,
    title: 'Investment',
    subtypes: [],
    children: [],
  },
  {
    name: AccountTypeEnum.LOAN,
    title: 'Loan',
    subtypes: [],
    children: [],
  },
  {
    name: AccountTypeEnum.MORTGAGE,
    title: 'Mortgage',
    subtypes: [AccountSubtypeEnum.REPAYMENT, AccountSubtypeEnum.INTEREST_ONLY],
    children: [
      {
        name: AccountSubtypeEnum.REPAYMENT,
        title: 'Repayment',
      },
      {
        name: AccountSubtypeEnum.INTEREST_ONLY,
        title: 'Interest Only',
      },
    ],
  },
  {
    name: AccountTypeEnum.PENSION,
    title: 'Pension',
    subtypes: [AccountSubtypeEnum.DEFINED_BENEFIT, AccountSubtypeEnum.DEFINED_CONTRIBUTION],
    children: [],
  },
  {
    name: AccountTypeEnum.PROPERTY,
    title: 'Properties',
    subtypes: [AccountSubtypeEnum.RESIDENTIAL, AccountSubtypeEnum.BUY_TO_LET],
    children: [
      {
        name: AccountSubtypeEnum.RESIDENTIAL,
        title: 'Residential',
      },
      {
        name: AccountSubtypeEnum.BUY_TO_LET,
        title: 'Buy to Let',
      },
    ],
  },
  // {
  //   name: AccountTypeEnum.ASSET,
  //   title: 'Asset',
  //   subtypes: [],
  //   children: [],
  // },
  {
    name: AccountTypeEnum.CRYPTO,
    title: 'Crypto',
    subtypes: [],
    children: [],
  },
  {
    name: AccountTypeEnum.OTHER,
    title: 'Other',
    subtypes: [],
    children: [],
  },
];

export const assetIconMap: { [key in AccountTypeEnum]: AssetCategoryEnum } = {
  CASH: AssetCategoryEnum.CASH,
  CRYPTO: AssetCategoryEnum.CRYPTO,
  PROPERTY: AssetCategoryEnum.PROPERTY,
  SAVINGS: AssetCategoryEnum.CASH,
  LOAN: AssetCategoryEnum.LOAN,
  MORTGAGE: AssetCategoryEnum.LOAN,
  PENSION: AssetCategoryEnum.PENSION,
  CARD: AssetCategoryEnum.CARD,
  INVESTMENT: AssetCategoryEnum.INVESTMENT,
  OTHER: AssetCategoryEnum.OTHER,
  ASSET: AssetCategoryEnum.OTHER,
};

export const ACCOUNT_TYPES: AccountTypeInterface[] = [
  {
    name: AssetCategoryEnum.INVESTMENT,
    title: 'Investments',
    types: [AccountTypeEnum.INVESTMENT],
    description:
      'Fancy yourself as a bit of a stock market guru? Great, Otto integrates most of the leading investment accounts, such as stocks, shares or ISAs, so you can keep up with the market goings-on on a daily basis.',
  },
  {
    name: AssetCategoryEnum.CASH,
    title: 'Current / Savings',
    types: [AccountTypeEnum.CASH, AccountTypeEnum.SAVINGS],
    description:
      'We interconnect with over 100 banks, credit unions & financial institutions so you can monitor your accounts live.',
  },
  {
    name: AssetCategoryEnum.PENSION,
    title: 'Pensions',
    types: [AccountTypeEnum.PENSION],
    description:
      'Link up your pension so we are able to track your funds that will unlock in the future, and make sure that you take full advantage of the tax benefits of a pension.',
  },
  {
    name: AssetCategoryEnum.CARD,
    title: 'Credit Cards',
    types: [AccountTypeEnum.CARD],
    description:
      'We interconnect with over 100 banks, credit unions & financial institutions so you can monitor your accounts live.',
  },
  {
    name: AssetCategoryEnum.PROPERTY,
    title: 'Real Estate',
    types: [AccountTypeEnum.PROPERTY],
    description:
      'If owning your own house is the dream - let’s get it tracked live here. We work with Zoopla to keep the estimated value of your properties updated monthly so you know you’re keeping up with the Joneses.',
  },
  {
    name: AssetCategoryEnum.LOAN,
    title: 'Loans / Mortgages',
    types: [AccountTypeEnum.LOAN, AccountTypeEnum.MORTGAGE],
    description:
      'If leverage is the name, Otto is game. Let’s see what you’ve borrowed against your property, vehicles and other assets to get a full picture of your financial health.',
  },
  {
    name: AssetCategoryEnum.OTHER,
    title: 'Other',
    types: [AccountTypeEnum.OTHER],
    description: `Can't see what you're looking for? Manually add any other type of asset here.`,
  },
  {
    name: AssetCategoryEnum.CRYPTO,
    title: 'Cryptocurrency',
    types: [AccountTypeEnum.CRYPTO],
    description:
      'Whether you store your crypto on an exchange or in an offline wallet - we can integrate to your preferred storage method of choice and keep you up to date as to your crypto portfolio happenings!',
    isManual: true,
  },
  {
    name: AssetCategoryEnum.INSURANCE,
    title: 'Insurance',
    types: [],
    description:
      'It’s nice to have money, but it’s even nicer to have peace of mind. Let us know what insurance you have here, whether it’s life insurance, wage protection or travel - we want to make sure you’re taken care of.',
    beta: [],
  },
];

export const INVESTMENT_TYPES = [AccountTypeEnum.INVESTMENT, AccountTypeEnum.PENSION];

export const LIABILITY_TYPES = [AccountTypeEnum.LOAN, AccountTypeEnum.MORTGAGE];
